import React, { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Category } from '../../models/Models';

// Komponenty
import { Backdrop, Box, Button, CircularProgress, Divider, ListItemIcon, Menu, Paper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ButtonList, { Item, ItemStyle } from '../../components/ButtonList';
import CategoryCreate, { CategoryCreateProps } from './CategoryCreate';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import MessageBox, { MessageBoxProps } from '../../components/MessageBox';
import CategorySettings, { CategorySettingsProps } from './CategorySettings';
import Search from '../../components/Search';

// Ikony
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { SearchInText } from '../../utility/Text';
import SettingsIcon from '@mui/icons-material/Settings';

interface CategoriesProps {
    categories: Category[];             // Zoznam kategórii (zdroj)
    activeCategoryId?: number;          // Id aktívnej kategórie
    hiddenCategoryId?: number;          // Skrytá vetva kategórie, napr. ak vyberám nadradenú kategóriu tak nemôžem dovoliť vybrať seba samú, inak hrozí zacyklenie a to, že sa nevykreslí.
    selectedCategories?: number[];      // Id označených kategórii
    checklist?: boolean;                // Zobraziť zaškrtávacie políčka (v režime označenia sa vypne responzívne zobrazenie otváracieho zoznamu)
    hideSearch?: boolean;               // Skryť vyhľadávanie
    hideAll?: boolean;                  // Skryť vetvu "všetko"
    hideUncategorized?: boolean;        // Skryť vetvu "nezaradené"
    onClick?: (id: number) => void;     // Udalosť po kliknutí na položku (reakciou by mala byť napr. zmena activeCategoryId)
    onSelectionChanged?: (categories: number[], confirm: boolean) => void; // Udalosť po zmene označenia (reakciou by mala byť napr. zmena selectedCategories)
    onCategoriesChanged?: () => void;   // Udalosť po zmene v zozname (CRUD)
}

const Categories = (props: CategoriesProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [categoriesSource, setCategoriesSource] = useState<Item[]>([]);
    const [categoriesSourceFiltered, setCategoriesSourceFiltered] = useState<Item[]>([]);
    // const [categoriesSourceSelect, setCategoriesSourceSelect] = useState<SelectItem[]>([]);
    const [categoryCreate, setCategoryCreate] = useState<CategoryCreateProps>({
        open: false,
        categories: [],
        keepMounted: true,
        onCategoriesChanged: () => props.onCategoriesChanged?.(),
        onSave: props.onCategoriesChanged,
        onClose: () => setCategoryCreate(prev => ({ ...prev, open: false }))
    });
    const [categorySettings, setCategorySettings] = useState<CategorySettingsProps>({
        open: false,
        keepMounted: false,
        categories: [],
        onCategoriesChanged: () => props.onCategoriesChanged?.(),
        onSelect: (id) => { },
        onClose: () => setCategorySettings(prev => ({ ...prev, open: false }))
    });
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({
        open: false, title: '', children: null, onClose: () => {
            setMessageBox(prev => ({
                ...prev, open: false
            }));
        }
    });
    const [searchCategories, setSearchCategories] = useState<string>('');

    // Kontextové menu
    const [categoriesMenuItem, setCategoriesMenuItem] = useState<Item>();
    const [categoriesMenuEl, setCategoriesMenuEl] = useState<null | HTMLElement>(null);

    // Pregenerovanie zdroja pre kategórie po zmene dát
    useEffect(() => {
        // Funkcia pre rekurzívne vygenerovanie položiek pre button list (pomocou level obmedzím rekurzívne vykreslenie kvôli zacykleniu ak bude chybný parent)
        const generate = (): Item[] | null => {
            const byParent = (id: number, level: number, parentName: string): Item[] => {
                if ((level ?? 0) > 30) {
                    return [];
                }
                return props.categories.filter(d => d.parentId === id && d.id !== props.hiddenCategoryId)?.map(d => {
                    var fullName = parentName + (parentName.length > 0 ? ' / ' : '') + (d.other?.translationName ?? '');
                    return ({
                        key: d.id ?? 0,
                        title: d.other?.translationName ?? '',
                        icon: <FolderOpenIcon />,
                        items: byParent(d.id ?? -1, level + 1, fullName),
                        style: (d.actived === false ? ItemStyle.Disabled : undefined),
                        onClickMore: handleCategoriesMenuOpen
                    })
                }) ?? null;
            };
            return byParent(0, 0, '');
        };
        const items: Item[] = [
            ...((props.hideAll ?? false) === false ? [{ key: 0, title: 'Všetko', icon: <FolderIcon />, divider: true }] : []),
            ...(generate() ?? []),
            ...((props.hideUncategorized ?? true) === false ? [{ key: -1, title: 'Nezaradené', icon: <FolderIcon /> }] : [])
        ];
        setCategoriesSource(items);

        // Prerobiť na výber cez komponent "CategorySelect" 
        // Kým nie je pripravená mobilná verzia, tak je SELECT zatiaľ vypnutý kvôli rýchlosti, tak ani neplním zdroj
        // Funkcia pre rekurzívne vygenerovanie položiek (dalo by sa to zlúčiť s tou prvou)
        // const itemsSelect: SelectItem[] = [];
        // const generateSelect = (parentId: number, parentName: string) => {
        //     props.categories.filter(d => d.parentId === parentId && d.id !== props.hiddenCategoryId)?.forEach(d => {
        //         const name = parentName + (parentName.length > 0 ? ' / ' : '') + (d.other?.translationName ?? '');
        //         itemsSelect.push({ id: d?.id ?? 0, name: name });
        //         generateSelect(d.id ?? 0, name);
        //     });
        // };
        // generateSelect(0, '');
        // setCategoriesSourceSelect(itemsSelect);

    }, [props.categories, props.checklist]);  // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre otvorenie kontexového menu kategórie
    const handleCategoriesMenuOpen = (e: HTMLElement, item: Item) => {
        setCategoriesMenuItem(item);
        setCategoriesMenuEl(e);
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number, parentId?: number) => {
        setCategoryCreate(prev => ({
            ...prev,
            id: id,
            parentId: parentId,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať túto kategóriu a jej pod-kategórie?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'category/' + id)
                    .then(response => {
                        if (response.data === true && props.onCategoriesChanged !== undefined) {
                            props.onCategoriesChanged();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Hromadné nastavenie vetvy
    const handleSettings = (id: number) => {
        setCategorySettings(prev => ({
            ...prev, 
            open: true, 
            categoryId: id, 
            onSelect: (id: number, type: number, typeArgument: number) => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'category/settings', null, {
                        params: {
                            'id': id,
                            'type': type,
                            'typeArgument': typeArgument
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            props.onCategoriesChanged?.();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => {
        if (searchCategories.length === 0) {
            setCategoriesSourceFiltered(categoriesSource);
            return;
        }

        // Pravidlá:
        // --------------
        // Zobraziť všetky pod vetvy nájdenej kategórie (ak vyhľadám obuv, tak sa mi zobrazia poltopanky, sandale atd)
        // Zobrazím celú nadradenú vetvu nájdenej kategórie (ak vyhľadám obuv, tak sa mi zobrazí nadradené oblečenie)

        // Riešenie
        // --------------
        // V jednom cykle vytvoriť dva zoznamy: 
        // - Zoznam č.1 (nájdené) - z pôvodného zdroju nájsť v všetky kategórie, ktoré obsahujú kľúčové slovo
        // - Zoznam č.2 (nadradené) - nájsť všetkých parentov pre prvý zoznam až po root
        // Rekurzívne skopírovať kategórie, ktoré:
        // - Ak figuruje v prvom zozname, tak automaticky zobraziť celú vetvu
        // - Ak figuruje v druhom zozname, tak ju pridať s tým, že jej podpoložky sa budú tiež prechádzať

        const foundItems: number[] = [-1, 0]; // Položky všetko a nezradené zobrazujem vždy
        const parentsItems: number[] = [];

        // Nájdem všetky položky a ich naradené vetvy
        props.categories.forEach(category => {
            if (SearchInText(searchCategories, (category?.other?.translationName ?? '')) === true) {
                var id = category.id ?? 0;
                if (!foundItems.includes(id)) {
                    foundItems.push(id);

                    // Nájdem celú nadradenú vetvu (9999 = ochrana pred zacyklením kvôli chybne nastaveným parentom: A > B > C > A > B > C...)
                    const addParents = (parentId: number) => {
                        if (parentId === 0 || parentsItems.length >= 9999) {
                            return;
                        }
                        var parent = props.categories.find(p => p.id === parentId);
                        if (parent !== undefined && (parent.id ?? 0) > 0 && !parentsItems.includes(parent.id ?? 0)) {
                            parentsItems.push(parent.id ?? 0);
                            addParents(parent.parentId ?? 0);
                        }
                    };
                    addParents(category.parentId ?? 0);
                }
            }
        });
       
        // Rekurzívne nájde položky
        const findItems = (items?: Item[]) : Item[] => {
            return items?.map(item => {

                // Ak položka nie je ani v prvom ani v druhom zozname, tak ju nezobrazujem
                if (!foundItems.includes(item.key as number) && !parentsItems.includes(item.key as number)) {
                    return { key: null, title: '' };
                }

                // Ak je položka v prvom zozname, tak automaticky zobrazujem celú jej vetvu
                if (foundItems.includes(item.key as number)) {
                    return { ...item };
                }

                // Nájdem podpoložky
                return { ...item, items: findItems(item.items) as Item[] }

            })?.filter(item => item.key !== null) ?? [];
        }

        // Použijem odfiltrovaný zoznam
        setCategoriesSourceFiltered(findItems(categoriesSource));
    }, [categoriesSource, searchCategories]);  // eslint-disable-line react-hooks/exhaustive-deps

    // Prerobiť zobrazenie tak, aby sa na mobile zobrazil ľavý panel ako "drawer" panel
    // Funkcia pre otvorenie kontexového menu kategórie
    // const handleCategoriesSelectMenuOpen = (e: HTMLElement, id: number) => {
    //     const item = props.categories.find(d => d.id === id);
    //     if (item) {
    //         handleCategoriesMenuOpen(e, {
    //             key: item.id ?? 0,
    //             title: item.other?.translationName ?? ''
    //         });
    //     }
    // };

    // Prerobiť na výber cez komponent "CategorySelect" 
    // Responzívnosť pre zobrazenia harmoniky alebo otváracieho zoznamu
    // Otvárací zoznam zobrazím len vtedy, ak nechcem zobraziť zaškrtávacie políčka, tie sa dajú zobraziť len v harmonike
    // const responsiveButtonList = (): SxProps => {
    //     if ((props.checklist ?? false) === false) {
    //         return { display: { xs: 'none', md: 'inherit' } } // Responzívne
    //     }
    //     else {
    //         return { display: { xs: 'inherit' } } // Zobrazím vždy
    //     }
    // };
    // const responsiveSelect = (): SxProps => {
    //     if ((props.checklist ?? false) === false) {
    //         return { display: { xs: 'inline-flex', md: 'none' } } // Responzívne
    //     }
    //     else {
    //         return { display: { xs: 'none' } } // Skryjem vždy
    //     }
    // };

    return (
        <React.Fragment>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Upozornenie */}
            <MessageBox {...messageBox} />

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <CategoryCreate {...categoryCreate} categories={props.categories} />

            {/* Formulár pre možnosti vetvy */}
            <CategorySettings {...categorySettings} categories={props.categories} />

            {/* Kontextové menu položky */}
            <Menu id="menu-categories" anchorEl={categoriesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(categoriesMenuEl)} onClose={() => setCategoriesMenuEl(null)} >
                <MenuItem dense onClick={() => { handleCreate(0, categoriesMenuItem?.key ?? 0); setCategoriesMenuEl(null); }}>
                    <ListItemIcon><AddIcon fontSize="small" /></ListItemIcon> Vložiť novú kategóriu
                </MenuItem>
                <MenuItem dense onClick={() => { handleCreate(categoriesMenuItem?.key ?? 0, 0); setCategoriesMenuEl(null); }}>
                    <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Upraviť kategóriu
                </MenuItem>
                <MenuItem dense onClick={() => { handleDelete(categoriesMenuItem?.key ?? 0, categoriesMenuItem?.title ?? ''); setCategoriesMenuEl(null); }}>
                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať kategóriu
                </MenuItem>
                <Divider />
                <MenuItem dense onClick={() => { handleSettings(categoriesMenuItem?.key ?? 0); setCategoriesMenuEl(null); }}>
                    <ListItemIcon><SettingsIcon fontSize="small" /></ListItemIcon> Možnosti
                </MenuItem>
            </Menu>

            {/* Vyhľadávanie */}
            {(props.hideSearch ?? false) === false && <Box mb={1}><Search text={searchCategories} onSearch={s => setSearchCategories(s)} onClear={() => { setSearchCategories(''); }} autoFocus={false} /></Box>}

            {/* Zoznam kategórii (harmonika) */}
            {/* <Paper sx={{ ...(responsiveButtonList()) }}> */}
            <Paper>
                <ButtonList
                    activedKey={props.activeCategoryId}
                    dense={true}
                    items={categoriesSourceFiltered ?? []}
                    checklist={props.checklist}
                    selectedKeys={props.selectedCategories}
                    onSelectionChanged={(keys) => {
                        if (props.onSelectionChanged !== undefined) {
                            props.onSelectionChanged([...keys], false);
                        }
                    }}
                    onClick={(e) => {
                        if (props.onClick !== undefined) {
                            props.onClick(e.key as number);
                        }
                    }} />
                <Box padding={1}>
                    <Button fullWidth variant="outlined" color="secondary" onClick={() => handleCreate(0, ((props.activeCategoryId ?? 0) > 0 ? props.activeCategoryId : 0))}>Nová kategória</Button>
                </Box>
            </Paper>

            {/* Na mobile bude lavý panel vykreslený ako vysúvací "drawer" */}
            {/* Dočasne vypínam kvôli rýchlosti, kým to nie je pripravené pre mobil, zbytočne sa to bude pregenerovávavať  */}
            {/* Dočasne vypínam kvôli rýchlosti, kým to nie je pripravené pre mobil, zbytočne sa to bude pregenerovávavať  */}
            {/* Zoznam kategórii (otvárací zoznam) */}
            {/* <Paper sx={{ ...(responsiveSelect()), p: '3px', alignItems: 'center', width: '100%' }}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <Select fullWidth size="small" value={props.activeCategoryId ?? 0} sx={{ flex: 1 }}
                        onChange={(e) => { if (props.onClick !== undefined) { props.onClick((parseInt(e.target.value.toString()))); } }}>
                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                        <MenuItem key={-1} value={-1}>Nezaradené</MenuItem>
                        {categoriesSourceSelect.map(category => (<MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>))}
                    </Select>
                </FormControl>
                <IconButton aria-label="Možnosti" title="Možnosti" disabled={(props.activeCategoryId ?? 0) <= 0} onClick={(e) => { handleCategoriesSelectMenuOpen(e.currentTarget, (props.activeCategoryId ?? 0)); }}>
                    <MoreVertIcon fontSize="small" />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton aria-label="Nová kategória" title="Nová kategória" onClick={() => { handleCreate(0, ((props.activeCategoryId ?? 0) > 0 ? props.activeCategoryId : 0)); }}>
                    <CreateNewFolderIcon fontSize="small" />
                </IconButton>
            </Paper> */}

        </React.Fragment>
    )
}

export default Categories;