
/** Vygeneruje unikátne 12-miestne kódy v tvare: XXXX-XXXX-XXXX (max: 10 000 v dávke).
 * Prvých 7-8 znakov tvorí aktuálny čas v milisekundách s indexom kódu, čo zabezpečí unikátnosť v čase.
 * Zvyšné znaky tvorí náhodný text, aby kódy nešli do poradia.
*/
export const UniqueCodesGenerate = (count: number): string[] => {
    let result: string[] = [];

    // Skontrolujem počet
    if(count <= 0) {
        return result;
    }

    // Získam celkový počet ms od roku 1970
    const time = new Date().getTime();

    // V cykle vygenerujem unikátne kódy (obmedzím maximálny počet)
    for(var i = 0; i < count && i < 10000; i++) {

        // K milisekundám pripočítam index a prevediem na text, ktorý bude mať cca: 7-9 znakov, ten  zabezpečí, že sa kódy nikdy nebudú opakovať
        let code = (time + i).toString(36);

        // Vygenerujem náhodný text
        let random = GetRandomInt(99999, 9999999999).toString(36);

        // Ku kódu pridám náhodný text (viac krát, aby som pokryl dĺžku 12 znakov), aby kódy sa negenerovali do poradia (xya > xyb > xyc)
        code = (code + random + random).substring(0, 12);

        // Preformátujem a vložím do zoznamu
        result.push(ReorganizeAndFormatCode(code));        
    }
    return result;
}

/** Zmení poradie znakov z AAAAAABBBBBB na ABABABABABAB a naformátuje po 4 znaky: ABAB-ABAB-ABAB */
const ReorganizeAndFormatCode = (code: string) : string => {    
    const l = Math.floor(code.length / 2);
    const a = code.substring(0, l);
    const b = code.substring(l);
    code = '';
    for(var i = 0; i < l; i++) {
        if(i > 0 && ((code.length + 1) % 5) === 0) {
            code += '-';
        }
        code += a.charAt(i);      
        code += b.charAt(i);
    }
    return code;
}

const GetRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); 
}