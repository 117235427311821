import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// Utility
import { ConvertToInt } from '../../../utility/Convert';
import addMonths from 'date-fns/addMonths'
import skLocale from 'date-fns/locale/sk';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Modely
import { Popup, Domain } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DateTimePicker } from '@mui/lab';
import InputAdornment from '@mui/material/InputAdornment';
import TinyMCEDialog, { TinyMCEDialogProps } from '../../tinymce/TinyMCEDialog';

// Ikony
import AvTimerIcon from '@mui/icons-material/AvTimer';
import EditIcon from '@mui/icons-material/Edit';
import Iframe from '../../../components/Iframe';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Popup => ({
    id: 0,
    actived: true,
    activedDateFrom: new Date(),
    activedDateTo: addMonths(new Date(), 1),
    intervalType: 0,
    interval: 1,
    name: '',
    text: '',
    domains: []
});

// Predpis pre argumenty TinyMCE
interface TinyMCEArgument {
    property: string;
}

// Vstupné parametre
export interface PopupCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const PopupCreate = (props: PopupCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Popup>(EmptySource());
    const [domains, setDomains] = useState<Domain[]>([]);
    const [tinyMCEDialog, setTinyMCEDialog] = useState<TinyMCEDialogProps>({
        open: false,
        content: '',
        contentStyle: 'body { font-family: Arial, sans-serif; }', // CSS štýl, ktorý sa pridáva do vygenerovaného HTML ako predvolený štýl
        onSave: (content: string, argument: TinyMCEArgument) => {
            handleChange(argument.property, content);
            handleTinymceClose();
        },
        onClose: () => handleTinymceClose()
    });

    // Zobrazenie HTML editoru
    const handleTinymceOpen = (argument: TinyMCEArgument, title: string, content: string) => {
        setTinyMCEDialog(prev => ({ ...prev, open: true, title: title, argument: argument, content: content, /*contentTagsIds: []*/ }));
    };
    const handleTinymceClose = () => {
        setTinyMCEDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'popup/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Popup);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam domén
    const loadDomains = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDomains(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'popup', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <TinyMCEDialog {...tinyMCEDialog} />

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Vyskakovacie okno' : 'Nové vyskakovacie okno')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívne" />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="popup-create-type">Typ intervalu</InputLabel>
                                    <Select required labelId="popup-create-type" label="Typ intervalu" value={source.intervalType ?? 0} name="intervalType" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'intervalType' && setFocus(input)}>
                                        <MenuItem value={0}>Dni</MenuItem>
                                        <MenuItem value={1}>Hodiny</MenuItem>
                                        <MenuItem value={2}>Minúty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="volumediscount-create-domain">Domény</InputLabel>
                                    <Select multiple labelId="volumediscount-create-domain" label="Domény" name="domains" value={source.domains ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'domains' && setFocus(input)}>
                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="interval" type="number" label="Interval zobrazenia" variant="outlined" autoComplete="off" value={source.interval ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'interval' && setFocus(input)}
                                    inputProps={{ maxLength: 18, min: 0, max: 99999999999999, step: 'any' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Po uplynutí nastaveného intervalu sa vyskakovacie okno zobrazí znovu.">
                                                    <AvTimerIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DateTimePicker inputFormat='dd.MM.yyyy HH:mm' ampm={false} disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth required margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Aktívne od" value={source.activedDateFrom} onChange={(d) => { handleChange('activedDateFrom', d) }}
                                        inputRef={input => (props.autoFocus === 'activedDateFrom') && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DateTimePicker inputFormat='dd.MM.yyyy HH:mm' ampm={false} disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth required margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Aktívne do" value={source.activedDateTo} onChange={(d) => { handleChange('activedDateTo', d) }}
                                        inputRef={input => (props.autoFocus === 'activedDateTo') && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Náhľad obsahu</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ height: '250px' }}>
                                <Iframe title={'Obsah vyskakovacieho okna'} content={(source.text ?? '') + '<style>' + (tinyMCEDialog.contentStyle ?? '') + '</style>'} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }}
                                    onClick={() => { handleTinymceOpen({ property: 'text' }, 'Obsah vyskakovacieho okna', source.text ?? ''); }}>Upraviť</Button>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default PopupCreate;