import React, { useEffect, useState } from 'react';

// Modely
import { Category } from '../../models/Models';

// Komponenty
import { TextField, InputAdornment } from '@mui/material';
import CategorySelectDialog, { CategorySelectDialogProps } from '../category/CategorySelectDialog';

// Ikony
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export interface CategorySelectProps {
    label?: string;
    activeCategoryId?: number;          // Aktuálne označená kategória
    hiddenCategoryId?: number;          // Skrytá vetva kategórie, napr. ak vyberám nadradenú kategóriu tak nemôžem dovoliť vybrať seba samú, inak hrozí zacyklenie a to, že sa nevykreslí.
    categories: Category[];             // Zoznam kategórii
    onCategoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
    onSelect: (id: number) => void;
}

const CategorySelect = (props: CategorySelectProps) => {

    // Stav
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>('...');

    // Zobrazenie výberu kategórie
    const [categorySelectDialog, setCategorySelectDialog] = useState<CategorySelectDialogProps>({
        open: false,
        categories: [],
        onSelect: props.onSelect,
        onClose: () => handleCategorySelectDialogClose()
    });
    const handleCategorySelectDialogOpen = () => {
        setCategorySelectDialog(prev => ({ ...prev, open: true }));
    };
    const handleCategorySelectDialogClose = () => {
        setCategorySelectDialog(prev => ({ ...prev, open: false }));
    };

    // Získam názov aktívnej kategórie s jednou nadradenou kategóriou
    useEffect(() => {
        // Ak nie možné získať názov, tak ďalej nepokračujem
        if (((props.activeCategoryId ?? 0) === 0 || props.categories.length === 0) && selectedCategoryName !== '') {
            setSelectedCategoryName('');
            return;
        }

        // Názov označenej kategórie
        let name = '';
        let active = props.categories.find(c => c.id === props.activeCategoryId);
        if (active !== undefined) {
            name = active?.other?.translationName ?? '';

            // Pridám názov nadradenej kategórie
            if ((active.parentId ?? 0) > 0) {
                let parent = props.categories.find(c => c.id === active?.parentId);
                if (parent !== undefined) {
                    name = (parent?.other?.translationName ?? '') + ' / ' + name;

                    // Ak má aj naradená kategóriu nadradenú kategóriu, tak pridám tri bodky na začiatok
                    if ((parent.parentId ?? 0) > 0) {
                        name = '... / ' + name;
                    }
                }
            }
        }

        setSelectedCategoryName(name);
    }, [props.activeCategoryId, props.categories]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <TextField fullWidth margin="none" label={props.label ?? "Kategória"} variant="outlined" autoComplete="off" onClick={handleCategorySelectDialogOpen}
                value={selectedCategoryName}
                inputProps={{ style: { cursor: 'pointer' } }}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                            <FolderOpenIcon />
                        </InputAdornment>
                    ),
                }} />
            <CategorySelectDialog
                {...categorySelectDialog}
                activeCategoryId={props.activeCategoryId}
                hiddenCategoryId={props.hiddenCategoryId}
                categories={props.categories}
                onCategoriesChanged={props.onCategoriesChanged}
            />
        </>
    )
}

export default CategorySelect;