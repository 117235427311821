import Paper from '@mui/material/Paper';

interface Props {
    title: string;
    content?: string;
    print?: boolean;
    src?: string;
}

const Iframe = (props: Props) => {
    const refFrame = (frame: HTMLIFrameElement) => {
        if (!frame) {
            return;
        }

        // Nastavím maximálnu veľkosť
        frame.style.width = '100%';
        frame.style.height = '100%';
        
        // Vytlačím stránku (try catch kvôli CORS policy)
        if (props.print === true) {
            frame.onload = () => {
                setTimeout(() => {
                    try {
                        (frame as any).contentWindow.print();
                    } catch (e) { }
                }, 250);
            };            
        }
    };
    return (
        <Paper variant='outlined' sx={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
            {props.src !== undefined && <iframe title={props.title} ref={refFrame} src={props.src} frameBorder="0" />}
            {props.src === undefined && <iframe title={props.title} ref={refFrame} src="about:blank" frameBorder="0" srcDoc={props.content} />}
        </Paper>
    );
}

export default Iframe;