import React, { useState } from 'react';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { ProductLite, ProductRelated } from '../../models/Models';

// Komponenty
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import ProductsDialog, { ProductsDialogProps } from './ProductsDialog';

// Ikony
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateRelatedProps extends ProductCreateComponentProps {

};

const ProductCreateRelated = (props: ProductCreateRelatedProps) => {

    // Súvisiace produkty (musím pracovať s predchádzajúcim stavom "prev", ktorý mi vnútorne vracia "useState" a preto je obslužný kód tu)
    const handleChangeRelated = (type: number, products: ProductLite[], add: boolean) => {
        // Súvisiace produkty
        if (type === 0) {
            props.setSource(prev => {
                if (add) {
                    const changed: ProductRelated[] = [...prev.relatedAny ?? []];
                    products.forEach(product => {
                        if (changed.find(item => item.productIdRelated === product.id) === undefined) {
                            changed.push({ productIdRelated: product.id, productCode: product.code, productName: product.name });
                        }
                    });
                    return { ...prev, relatedAny: changed };
                }
                else {
                    return { ...prev, relatedAny: prev.relatedAny?.filter(item => products.find(deleted => deleted.id === item.productIdRelated) === undefined) ?? [] }
                }
            });
        }
        // Alternatívne produkty
        if (type === 1) {
            props.setSource(prev => {
                if (add) {
                    const changed: ProductRelated[] = [...prev.relatedAlternative ?? []];
                    products.forEach(product => {
                        if (changed.find(item => item.productIdRelated === product.id) === undefined) {
                            changed.push({ productIdRelated: product.id, productCode: product.code, productName: product.name });
                        }
                    });
                    return { ...prev, relatedAlternative: changed };
                }
                else {
                    return { ...prev, relatedAlternative: prev.relatedAlternative?.filter(item => products.find(deleted => deleted.id === item.productIdRelated) === undefined) ?? [] }
                }
            });
        }
        // Príslušenstvo
        if (type === 2) {
            props.setSource(prev => {
                if (add) {
                    const changed: ProductRelated[] = [...prev.relatedAccessory ?? []];
                    products.forEach(product => {
                        if (changed.find(item => item.productIdRelated === product.id) === undefined) {
                            changed.push({ productIdRelated: product.id, productCode: product.code, productName: product.name });
                        }
                    });
                    return { ...prev, relatedAccessory: changed };
                }
                else {
                    return { ...prev, relatedAccessory: prev.relatedAccessory?.filter(item => products.find(deleted => deleted.id === item.productIdRelated) === undefined) ?? [] }
                }
            });
        }
        // Náhradné diely
        if (type === 3) {
            props.setSource(prev => {
                if (add) {
                    const changed: ProductRelated[] = [...prev.relatedSpareParts ?? []];
                    products.forEach(product => {
                        if (changed.find(item => item.productIdRelated === product.id) === undefined) {
                            changed.push({ productIdRelated: product.id, productCode: product.code, productName: product.name });
                        }
                    });
                    return { ...prev, relatedSpareParts: changed };
                }
                else {
                    return { ...prev, relatedSpareParts: prev.relatedSpareParts?.filter(item => products.find(deleted => deleted.id === item.productIdRelated) === undefined) ?? [] }
                }
            });
        }
    }

    // Stav
    const [productsDialog, setProductsDialog] = useState<ProductsDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (products: ProductLite[], type: number) => {
            if (products.length > 0) {
                handleChangeRelated(type, products, true);
            }
            handleProductsClose();
        },
        onClose: () => handleProductsClose()
    });

    // Zobrazenie produktov (pri prvom )
    const handleProductsOpen = (type: number) => {
        setProductsDialog(prev => ({ ...prev, argument: type, open: true }));
    };
    const handleProductsClose = () => {
        setProductsDialog(prev => ({ ...prev, open: false }));
    };

    return (
        <>

            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('relatedany')} onChange={() => { props.onToggleAccordion('relatedany') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Odporúčané produkty ({props.source.relatedAny?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Alert severity="info" sx={{ mb: 1 }}>
                        Odporúčané produkty pre zakúpenie spolu s týmto produktom.
                    </Alert>
                    {(props.source.relatedAny?.length ?? 0) > 0 &&
                        <Table size="small">
                            <TableBody>
                                {props.source.relatedAny?.map((product, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <strong>{product.productCode}</strong> - {product.productName}
                                        </TableCell>
                                        <TableCell style={{ padding: 0, width: '100px', textAlign: 'center' }}>
                                            <IconButton aria-label="Upraviť" size="small" href={AppRouteUrl.PRODUCTS + '?id=' + (product.productIdRelated ?? 0).toString()} target="_blank">
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton aria-label="Vymazať" size="small" onClick={() => handleChangeRelated(0, [{ id: product.productIdRelated }], false)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                    <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleProductsOpen(0)}>Pridať produkt</Button>
                </AccordionDetails>
            </Accordion>



            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('relatedalternative')} onChange={() => { props.onToggleAccordion('relatedalternative') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Alternatívne produkty ({props.source.relatedAlternative?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Alert severity="info" sx={{ mb: 1 }}>
                        Produkty zobrazené ako alternatíva k tomuto produktu.
                    </Alert>
                    {(props.source.relatedAlternative?.length ?? 0) > 0 &&
                        <Table size="small">
                            <TableBody>
                                {props.source.relatedAlternative?.map((product, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <strong>{product.productCode}</strong> - {product.productName}
                                        </TableCell>
                                        <TableCell style={{ padding: 0, width: '100px', textAlign: 'center' }}>
                                            <IconButton aria-label="Upraviť" size="small" href={AppRouteUrl.PRODUCTS + '?id=' + (product.productIdRelated ?? 0).toString()} target="_blank">
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton aria-label="Vymazať" size="small" onClick={() => handleChangeRelated(1, [{ id: product.productIdRelated }], false)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                    <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleProductsOpen(1)}>Pridať produkt</Button>
                </AccordionDetails>
            </Accordion>



            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('relatedaccessory')} onChange={() => { props.onToggleAccordion('relatedaccessory') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Príslušenstvo ({props.source.relatedAccessory?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Alert severity="info" sx={{ mb: 1 }}>
                        Odporúčané príslušenstvo, ktoré je možné dokúpiť samostatne.
                    </Alert>
                    {(props.source.relatedAccessory?.length ?? 0) > 0 &&
                        <Table size="small">
                            <TableBody>
                                {props.source.relatedAccessory?.map((product, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <strong>{product.productCode}</strong> - {product.productName}
                                        </TableCell>
                                        <TableCell style={{ padding: 0, width: '100px', textAlign: 'center' }}>
                                            <IconButton aria-label="Upraviť" size="small" href={AppRouteUrl.PRODUCTS + '?id=' + (product.productIdRelated ?? 0).toString()} target="_blank">
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton aria-label="Vymazať" size="small" onClick={() => handleChangeRelated(2, [{ id: product.productIdRelated }], false)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                    <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleProductsOpen(2)}>Pridať produkt</Button>
                </AccordionDetails>
            </Accordion>



            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('relatedspareparts')} onChange={() => { props.onToggleAccordion('relatedspareparts') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Náhradné diely ({props.source.relatedSpareParts?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Alert severity="info" sx={{ mb: 1 }}>
                        Náhradné diely určené k tomuto produktu.
                    </Alert>
                    {(props.source.relatedSpareParts?.length ?? 0) > 0 &&
                        <Table size="small">
                            <TableBody>
                                {props.source.relatedSpareParts?.map((product, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <strong>{product.productCode}</strong> - {product.productName}
                                        </TableCell>
                                        <TableCell style={{ padding: 0, width: '100px', textAlign: 'center' }}>
                                            <IconButton aria-label="Upraviť" size="small" href={AppRouteUrl.PRODUCTS + '?id=' + (product.productIdRelated ?? 0).toString()} target="_blank">
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton aria-label="Vymazať" size="small" onClick={() => handleChangeRelated(2, [{ id: product.productIdRelated }], false)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                    <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleProductsOpen(3)}>Pridať produkt</Button>
                </AccordionDetails>
            </Accordion>



            {/* Výber produktov */}
            <ProductsDialog {...productsDialog} />
        </>
    )
};

export default ProductCreateRelated;
