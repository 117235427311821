import { createStyles, makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles((theme: any) => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%',
            backgroundColor: theme.layout.body.backgroundColor
        },
        a: {
            textDecoration: 'none'
        },
        '#root': {
            height: '100%',
            width: '100%'
        },

        /* Scrollbar */
        '*::-webkit-scrollbar': {
            width: '0.3em',
            height: '0.8em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#666666'
        },

        /* Form */
        '.formIconHelp': {
            cursor: 'help'
        },

        /* Components > Navigation */
        '.navigation-collapse-child': {
            position: 'relative',
            '&:before': {
                display: 'block',
                width: '5px',
                height: '100%',
                position: 'absolute',
                zIndex: 2,
                top: '0px',
                left: '0px',
                content: '""',
                background: theme.layout.sidebar.collapse.borderColor,
            }
        },

        /* Components > DataGrid */
        '.MuiDataGrid-booleanCell[data-value="false"]': {
            opacity: .3
        },

        /* Components > ButtonList */
        '.buttonlist-level': {
            position: 'relative',
            paddingLeft: '15px',
            overflowY: 'hidden',
            /*'&:before': {
                display: 'block',
                width: '1px',
                height: '100%',
                position: 'absolute',
                zIndex: 2,
                top: '-26px',
                left: '20px',
                content: '""',
                background: theme.palette.secondary.light,
            }*/
        },
        '.buttonlist-level .MuiListItemButton-root' : {
            position: 'relative',
            '&:before': {
                display: 'block',
                width: '1px',
                height: '100%',
                position: 'absolute',
                zIndex: 2,
                top: '-18px',
                left: '4px',
                content: '""',
                background: theme.palette.secondary.light,
            },
            '&:after': {
                display: 'block',
                width: '8px',
                height: '1px',
                position: 'absolute',
                zIndex: 2,
                top: '18px',
                left: '5px',
                content: '""',
                background: theme.palette.secondary.light,
            }
        }
    },
}));

const StylesGlobal = () => {
    const theme = useTheme();
    useStyles(theme);
    return null;
};

export default StylesGlobal;