import React, { useState } from 'react';

// Modely
import { File, Gallery, GalleryItem } from '../../../models/Models';

// Utility
import { FileOpen } from '../../../utility/File';

// Komponenty
import { Button, Card, CardActionArea, CardActions, CardMedia, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';
import GalleryCreateItemsEdit, { GalleryCreateItemsEditProps } from './GalleryCreateItemsEdit';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface GalleryCreateItemsProps {
    source: Gallery;
    setSource: (action: React.SetStateAction<Gallery>) => void;
};

const GalleryCreateItems = (props: GalleryCreateItemsProps) => {

    // Stav
    const [galleryCreateItemsEdit, setGalleryCreateItemsEdit] = useState<GalleryCreateItemsEditProps>({
        open: false,
        keepMounted: true,
        file: {},
        onSave: (file: GalleryItem) => {
            props.setSource(prev => {
                const changed = [...prev?.items ?? []];
                changed?.every((item, itemIndex) => {
                    if (item.fileId === file.fileId) {
                        changed[itemIndex] = file;
                        return false;
                    }
                    return true;
                });
                return { ...prev, items: changed };
            });
        },
        onClose: () => handleFilesEditClose()
    });
    const handleFilesEditOpen = (file: GalleryItem) => {
        setGalleryCreateItemsEdit(prev => ({ ...prev, open: true, file: file }));
    };
    const handleFilesEditClose = () => {
        setGalleryCreateItemsEdit(prev => ({ ...prev, open: false }));
    };

    // Okno súborov
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        watermark: false,
        directoryType: 1, // Predvolený adresár pre produkty
        onSelect: (files: File[], argument: any) => {
            if (files.length > 0) {
                props.setSource(prev => {
                    let changed = prev.items ?? [];
                    files.forEach(file => {
                        // Súbor type=0 musí byť obrázok, všetko ostatné môže byť akýkoľvek súbor
                        if (argument !== 0 || file.fileIsImage) {
                            if (prev.items?.find(item => item.fileId === file.id) === undefined) {
                                changed.push({
                                    actived: true,
                                    fileId: file.id,
                                    ordinalNumber: ((changed?.length ?? 0) > 0 ? (Math.max(...(changed?.map(i => i.ordinalNumber ?? 0) ?? [])) ?? 0) + 1 : 0),
                                    name: file.name,
                                    other: {
                                        fileExtension: file.fileExtension,
                                        fileIsImage: file.fileIsImage,
                                        fileName: file.fileName,
                                        fileNameSmall: file.fileNameSmall,
                                        fileNameMedium: file.fileNameMedium,
                                        fileSrc: file.fileSrc,
                                        fileSrcSmall: file.fileSrcSmall,
                                        fileSrcMedium: file.fileSrcMedium
                                    }
                                } as GalleryItem);
                            }
                        }
                    });
                    return { ...prev, items: changed };
                });
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Kontextové menu
    const [filesMenuItem, setFilesMenuItem] = useState<GalleryItem>();
    const [filesMenuEl, setFilesMenuEl] = useState<null | HTMLElement>(null);

    // Funkcia pre otvorenie kontexového menu zložky
    const handleFilesMenuOpen = (e: HTMLElement, file: GalleryItem) => {
        setFilesMenuItem(file);
        setFilesMenuEl(e);
    };

    // Zobrazenie súborov
    const handleFilesOpen = (type: number) => {
        setFilesDialog(prev => ({ ...prev, open: true, argument: type }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Po dvoj-kliku otvorím súbor
    const handleFileClick = (file: GalleryItem) => {
        if (file?.other?.fileSrc !== undefined) {
            FileOpen(file?.other?.fileSrc, '_blank');
        }
    };

    // Vymazať súbor 
    const handleFileDelete = (file: GalleryItem) => {
        props.setSource(prev => {
            return { ...prev, items: (prev.items ?? [])?.filter(item => item.fileId !== file.fileId) };
        });
    }

    // Zoradenie položiek, najskôr podľa poradia potom podľa id súboru (poradie môže byť rovnaké)
    const itemsSortComparer = (a: GalleryItem, b: GalleryItem) : number => {
        if((a.ordinalNumber ?? 0) !== (b.ordinalNumber ?? 0)) {
            return (a.ordinalNumber ?? 0) > (b.ordinalNumber ?? 0) ? 1 : -1;
        }
        else {
            return (a.fileId ?? 0) > (b.fileId ?? 0) ? 1 : -1;
        }
    }

    return (
        <>
            {/* Kontextové menu položky */}
            <Menu id="menu-directories" anchorEl={filesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(filesMenuEl)} onClose={() => setFilesMenuEl(null)} >
                <MenuItem onClick={() => { if (filesMenuItem !== undefined && filesMenuItem?.other?.fileSrc !== undefined) { FileOpen(filesMenuItem?.other?.fileSrc, '_blank'); } setFilesMenuEl(null); }}>
                    <ListItemIcon><SearchIcon fontSize="small" /></ListItemIcon> Otvoriť súbor
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { handleFilesEditOpen(filesMenuItem ?? {}); setFilesMenuEl(null); }}>
                    <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Upraviť súbor
                </MenuItem>
                <MenuItem onClick={() => { handleFileDelete(filesMenuItem ?? {}); setFilesMenuEl(null); }}>
                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať súbor
                </MenuItem>
            </Menu>

            <Button color="secondary" size="small" variant="contained" sx={{ mb: 1 }} onClick={() => handleFilesOpen(0)}>Vložiť obrázok</Button>

            <Grid container columnSpacing={2} rowSpacing={2} {...((props.source?.items ?? [])?.length === 0 && { display: "none" })}>
                {props.source?.items?.sort((a, b) => itemsSortComparer(a, b))?.map((file) => (
                    <Grid key={file.fileId} item xs={6} sm={3} md={4} lg={3}>
                        <Card>
                            <CardActionArea onClick={() => handleFileClick(file)}>
                                <CardMedia component="img" image={(file?.other?.fileSrcMedium ?? '')} alt={file.name ?? ''} sx={{ height: { xs: '100px', md: '150px' } }} />
                            </CardActionArea>
                            <CardActions>
                                <Typography noWrap fontSize="small" pl={1}>{'#' + (file.ordinalNumber ?? 0) + ' - ' + ((file.name?.length ?? 0) > 0 ? file.name : 'Bez názvu')}</Typography>
                                <IconButton sx={{ marginLeft: 'auto' }} onClick={(e) => handleFilesMenuOpen(e.currentTarget, file)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <FilesDialog {...filesDialog} />
            <GalleryCreateItemsEdit {...galleryCreateItemsEdit} />
        </>
    )
};

export default GalleryCreateItems;