// Modely
import { Form, FormSettingsItem, FormSettingsItemInputType } from '../../../models/Models';

// Komponenty
import { Button, TextField, FormControlLabel, Switch, FormControl, InputLabel, MenuItem, Select, Chip, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface FormCreateItemProps {
    item: FormSettingsItem;
    setSource: (action: React.SetStateAction<Form>) => void;
}

const FormCreateItem = (props: FormCreateItemProps) => {

    // Upraviť položku
    const handleChange = (property: string, value: any) => {
        props.setSource(prev => {
            const items: FormSettingsItem[] = prev.settings?.items ?? [];
            for (var index = 0; index < items.length; index++) {
                if (items[index] === props.item) {
                    items[index] = { ...items[index], [property]: value };
                }
            }
            return ({ ...prev, settings: { ...prev.settings, items: items } });
        });
    }

    // Upraviť poradie položky
    const handleChangeOrdinalNumber = (ordinalNumber: number) => {
        props.setSource(prev => {
            const items: FormSettingsItem[] = prev.settings?.items ?? [];

            // Skontrolujem nové poradie
            if (ordinalNumber < 0 || ordinalNumber >= items.length) {
                return prev;
            }

            // Funkcia pre nastavenie poradia konkrétnej položke
            const setItemOrdinalNumber = (item: FormSettingsItem, itemOrdinalNumber: number) => {
                for (var index = 0; index < items.length; index++) {
                    if (items[index] === item) {
                        items[index] = { ...items[index], ordinalNumber: itemOrdinalNumber };
                    }
                }
            }

            // Nastavím poradie položke, ktorá bola pôvodne na danej pozícii
            setItemOrdinalNumber(items.find(item => item.ordinalNumber === ordinalNumber) ?? {}, props.item.ordinalNumber ?? 0);

            // Nastavím poradie aktuálnej položke
            setItemOrdinalNumber(props.item, ordinalNumber);

            return ({ ...prev, settings: { ...prev.settings, items: items } });
        });
    }

    // Vymazať položku
    const handleDelete = () => {
        props.setSource(prev => {
            // Vymažem položku
            const items = (prev.settings?.items ?? []).filter(item => item !== props.item);
            // Všetky položky, ktoré mali poradie väčšie ako vymazaná položka posuniem o vyššie
            for (var index = 0; index < items.length; index++) {
                if ((items[index].ordinalNumber ?? 0) > (props.item.ordinalNumber ?? 0)) {
                    items[index] = { ...items[index], ordinalNumber: (items[index].ordinalNumber ?? 0) - 1 };
                }
            }
            return ({ ...prev, settings: { ...prev.settings, items: items } });
        });
    }

    return (
        <Grid container columnSpacing={1}>

            <Grid item xs={12} sm={6}>
                <TextField required margin="dense" name="label" label="Popisok" value={props.item.label ?? ''} fullWidth variant="outlined" size="small" autoComplete="off" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EditIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={8} sm={4}>
                <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                    <InputLabel id="payment-create-type">Typ *</InputLabel>
                    <Select required labelId="payment-create-type" label="Typ *" value={props.item.input ?? FormSettingsItemInputType.Textbox} name="input" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        <MenuItem value={FormSettingsItemInputType.Textbox}>Text (jeden riadok)</MenuItem>
                        <MenuItem value={FormSettingsItemInputType.Textarea}>Text (viac riadkov)</MenuItem>
                        <MenuItem value={FormSettingsItemInputType.Radio}>Prepínače</MenuItem>
                        <MenuItem value={FormSettingsItemInputType.Checkbox}>Zaškrtávače</MenuItem>
                        <MenuItem value={FormSettingsItemInputType.Select}>Rozbaľovací zoznam</MenuItem>
                        <MenuItem value={FormSettingsItemInputType.Captcha}>Captcha (nie som robot)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4} sm={2}>
                <Button fullWidth variant="contained" color="secondary" onClick={handleDelete} sx={{ mt: 1.2 }}>Vymazať</Button>
            </Grid>

            {props.item.input !== FormSettingsItemInputType.Captcha && (
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField margin="dense" name="values" value={props.item.values ?? ''} label="Hodnoty, oddelené bodkočiarkou" fullWidth variant="outlined" size="small" autoComplete="off" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                            inputProps={{ maxLength: 255 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EditIcon />
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField margin="dense" name="defaultValue" value={props.item.defaultValue ?? ''} label="Predvolená hodnota" fullWidth variant="outlined" size="small" autoComplete="off" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                            inputProps={{ maxLength: 255 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EditIcon />
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>
                </>
            )}

            {props.item.input === FormSettingsItemInputType.Captcha && (
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField margin="dense" name="argument1" value={props.item.argument1 ?? ''} label="Site Key (identifikátor)" fullWidth variant="outlined" size="small" autoComplete="off" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                            inputProps={{ maxLength: 255 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EditIcon />
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField margin="dense" name="argument2" value={props.item.argument2 ?? ''} label="Secret Key (tajný kľúč)" fullWidth variant="outlined" size="small" autoComplete="off" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                            inputProps={{ maxLength: 255 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EditIcon />
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>
                </>
            )}

            <Grid item xs={12} mt={1}>
                <FormControlLabel control={<Switch checked={props.item.required ?? false} name="required" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Povinné" />
                <Chip label={'#' + (props.item.ordinalNumber ?? 0)} sx={{ mr: 1 }}></Chip>
                <IconButton daria-label="Nahor" title="Nahor" size="small" onClick={(e) => { handleChangeOrdinalNumber((props.item.ordinalNumber ?? 0) - 1); }}>
                    <KeyboardArrowUpIcon fontSize="small" />
                </IconButton>
                <IconButton daria-label="Nadol" title="Nadol" size="small" onClick={(e) => { handleChangeOrdinalNumber((props.item.ordinalNumber ?? 0) + 1); }}>
                    <KeyboardArrowDownIcon fontSize="small" />
                </IconButton>
            </Grid>

        </Grid>
    )
}

export default FormCreateItem;