import React, { useRef, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { FileResizeImage } from '../../utility/File';

// Komponenty
import { Backdrop, Button, CircularProgress, Typography } from '@mui/material';
import MessageBox, { MessageBoxProps } from '../../components/MessageBox';

// Ikony
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

// Pomôcky: https://github.com/mui-org/material-ui/issues/9716, https://www.youtube.com/watch?v=Ti8QNiRRzOA&ab_channel=ProgrammingWithPrem

interface FileUploadState {
    uploading: boolean;
    progress: number;
}

interface FileUploadProps {
    label?: string;             // Text tlačidla
    labelIcon?: JSX.Element;    // Ikona tlačidla
    directoryId?: number;       // Adresár do ktorého sa má nahrať súbor
    maxFileSize?: number;       // Maximálna veľkosť súboru v MB
    watermark?: boolean;        // Zapne alebo vypne (predvolené) automatickú aktiváciu vodotlače na nahraných fotkách
    resize?: boolean;           // Zapne (predvolené) alebo vypne automatické zmenšovanie fotiek
    resizeMaxSize?: number;     // Maximálna veľkosť pre automatické zmenšenie súboru (predvolené podľa nastavenia: AppConfig.FileUpload.ResizeMaxSize)
    onUploaded?: (ids: number[]) => void;    // Udalosť, ktorá bude vyvolaná po nahradní súborov
}

const FileUpload = (props: FileUploadProps) => {

    // Lokálny stav
    const [state, setState] = useState<FileUploadState>({
        uploading: false,
        progress: 0
    });
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({ open: false, title: '', children: null, onClose: () => {
        setMessageBox(prev => ({ 
            ...prev, open: false
        }));
    } });

    // Referencie
    const inputEl = useRef<HTMLInputElement>(null);

    // Automatické nahrávanie súboru po výbere
    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Súbory na odoslanie
        const files = e.target.files;
        const filesLength = files?.length ?? 0;

        // Pokračujem len ak som vybral súbory
        if(files == null || filesLength === 0) {
            return;
        }

        // Zobrazím preloader a odošlem formulár
        setState(prev => ({ ...prev, uploading: true, progress: 0 }));

        // Maximálna veľkosť súboru
        const maxFileSize = props.maxFileSize ?? AppConfig.FileUpload.MaxFileSize;

        // Vložím súbory (fotky automaticky zmenším)
        const data = new FormData();
        for(var i = 0; i < filesLength; i++) {
            var file = files[i];
            let fileName = files[i].name;
            let fileSize = 0;

            // Zmenším fotku
            if((props.resize ?? true) && file.type.match(/image.*/)) {
                try {
                    const resizedImage = await FileResizeImage({
                        file: file,
                        maxSize: props.resizeMaxSize ?? AppConfig.FileUpload.ResizeMaxSize
                    }) as Blob;
                    file = new File([resizedImage], file.name);
                } catch (exp) { }
            }

            // Skontrolujem veľkosť súboru
            fileSize = (Math.round(file.size * 100 / (1024 * 1024)) / 100);
            if(fileSize > maxFileSize) {
                setMessageBox(prev => ({
                    ...prev, 
                    open: true,
                    title: 'Súbor nebolo možné nahrať',
                    children: 'Maximálna veľkosť súboru je: ' + maxFileSize.toFixed(2) + ' MB. Súbor ' + (fileName ?? '') + ' má veľkosť ' + (fileSize ?? 0).toFixed(2) + ' MB.'
                }));
                setState(prev => ({ ...prev, uploading: false }));
                return;
            }

            data.append(`images[${i}]`, file);
        }

        // Vložím ostatné parametre
        data.append('watermark', (props.watermark ?? false).toString());
        data.append('directoryId', (props.directoryId ?? 0).toString());

        axios
            .post(AppConfig.ApiUri + 'file/upload', data, {
                onUploadProgress: (progress) => {
                    const { loaded, total } = progress;
                    let percent = Math.floor((loaded * 100) / total);
                    setState(prev => ({ ...prev, progress: percent }));
                }
            })
            .then(response => {
                if(response.data !== undefined) {
                    if(props.onUploaded) {
                        props.onUploaded(response.data as number[]);
                    }
                }
            })
            .catch(() => {
                setMessageBox(prev => ({ ...prev, open: true, title: 'Chyba nahrávania', children: 'Súbor sa nepodarilo nahrať na server!' }));
            })
            .finally(() => {
                setState(prev => ({ ...prev, uploading: false }));
                if(inputEl && inputEl.current) {
                    inputEl.current.value = '';
                }
            }); 
    };

    return (
        <>
            {/* Upozornenie */}
            <MessageBox {...messageBox} />

            {/* Preloader */}
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={state.uploading}>
                <CircularProgress color="inherit" />
                <Typography ml={2}>{state.progress > 0 ? 'Nahrávam: ' + state.progress.toFixed(0) + '%' : 'Pripravujem súbory'}</Typography>
            </Backdrop>

            {/* FileInput */}
            <input type="file" id="fileupload" onChange={onChange} ref={inputEl} hidden multiple />
            
            {/* Tlačidlo */}
            <label htmlFor="fileupload">
                <Button component="span" variant="contained" sx={{ mr: 1 }} size="large" startIcon={ props.labelIcon !== undefined ? props.labelIcon : <DriveFolderUploadIcon /> }>
                    { props.label !== undefined ? props.label : "Nahrať" }
                </Button>
            </label>
        </>
    )
}

export default FileUpload;