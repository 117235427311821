import { useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Komponenty
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ParameterConfigurator from './ParameterConfigurator';

// Modely
import { Parameter, ParameterItem } from '../../../models/Models';

// Vstupné parametre
export interface ParameterConfiguratorDialogProps {
    open: boolean;
    argument?: any; // môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    keepMounted?: boolean;
    parameters?: Parameter[];
    parametersItems?: number[]; // povolené položky (priradené k produktu)
    onSelect?: (items: ParameterItem[], argument?: any) => void;
    onClose?: () => void;
}

const ParameterConfiguratorDialog = (props: ParameterConfiguratorDialogProps) => {

    // Stav 
    const [parameters, setParameters] = useState<Parameter[]>([]);

    // Označené záznamy
    const [selected, setSelected] = useState<ParameterItem[]>([]);

    // Načítam zoznam parametrov
    const loadParameters = () => {
        axios
            .get(AppConfig.ApiUri + 'parameter', {
                params: {
                    withItems: true
                }
            })
            .then(response => {
                setParametersByItems(response.data);
            });
    };

    // Načítam zoznam parametrov, ak nefigurujú na vstupe
    useEffect(() => {
        if (props.parameters !== undefined) {
            setParametersByItems(props.parameters);
        } else {
            loadParameters();
        }
    }, [props.parameters, props.parametersItems]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ponechám iba dostupné parametre podľa výberu "props.parametersItems"
    const setParametersByItems = (parametersAll: Parameter[]) => {
        // Ak je výber prázdny, tak zobrazím všetko
        if(props.parametersItems === undefined) {
            setParameters(parametersAll);
            return;
        }

        // Ofiltrujem parametre a ich položky
        const parametersByItems: Parameter[] = [];
        parametersAll.filter(parameter => parameter.type === 2 || parameter.type === 3)?.forEach(parameter => {
            if(parameter?.items?.find(item => props.parametersItems?.includes(item.id ?? 0)) !== undefined) {
                parametersByItems.push({ ...parameter, items: parameter?.items?.filter(item => props.parametersItems?.includes(item.id ?? 0)) ?? [] });
            }
        });
        setParameters(parametersByItems);
    };

    // Zmena výberu s možnosťou potvrdenia výberu
    const handleSelectionChanged = (items: ParameterItem[], confirm: boolean) => { 
        if(confirm) {
            handleConfirm(items); // Pri potvrdení rovno odovzdám aktuálny zoznam, nemôžem čakať na zmenu stavu
        } else {
            setSelected(items); // Zmením aktuálny výber
        }
    };

    // Potvrdenie výberu (nepoužívam zoznam zo stavu keďže zmnena stavu sa môže dokončiť neskôr ako príde potvrdenie)
    const handleConfirm = (items: ParameterItem[]) => {
        if(props?.onSelect) {
            props?.onSelect(items, props.argument);
        }
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick' && props.onClose) { props.onClose(); }}}>
                <DialogTitle>
                    Konfigurátor
                </DialogTitle>
                <DialogContent>
                    <ParameterConfigurator onSelectionChanged={handleSelectionChanged} parameters={parameters} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Späť</Button>
                    <Button onClick={() => handleConfirm(selected)} type="submit" variant="contained">Potvrdiť výber</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ParameterConfiguratorDialog;