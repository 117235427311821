import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { DocumentsFilter, DocumentLite, DocumentType, DocumentState } from '../../models/Models';

// Utility
import Debounce from '../../utility/Debounce';
import { ResponsivePoint as HidePoint } from '../../utility/ResponsivePoint';
import { FormatDecimal } from '../../utility/Format';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../utility/DataGrid';
import format from 'date-fns/format';
import { useQuery } from '../../utility/URL';

// Komponenty
import { Backdrop, Button, Chip, CircularProgress, Divider, Grid, IconButton, ListItemIcon, Menu } from '@mui/material';
import { DataGrid, GridColDef, GridDensity, GridFilterModel, GridRenderCellParams, GridRowId, GridSortItem, GridSortModel, GridValueFormatterParams, GridValueGetterParams, skSK } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import DocumentCreate, { DocumentCreateProps, DocumentCreateSaveAction } from './DocumentCreate';
import DocumentsFiltering, { DocumentsFilteringProps } from './DocumentsFiltering';
import DocumentsState, { DocumentsStateProps } from './DocumentsState';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import ReportDocument, { ReportDocumentProps } from '../report/ReportDocument';
import DocumentsGenerate, { DocumentsGenerateProps } from './DocumentsGenerate';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import FlagIcon from '@mui/icons-material/Flag';
import EuroIcon from '@mui/icons-material/Euro';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DescriptionIcon from '@mui/icons-material/Description';

// Rozšírenie pre vlastný index zoradenia
interface GridSortModelIndex extends GridSortItem {
    index: number;
}

// Vstupné parametre
export interface DocumentsProps {
    types?: DocumentType[]; // Druhy dokladu - číselník
    type?: number; // Druh dokladu - id
    onSelectionChanged?: (rows: DocumentLite[], confirm: boolean) => void;
}

// Komponent pre zoznam
const Documents = (props?: DocumentsProps) => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [documentType, setDocumentType] = useState<DocumentType>({ id: props?.type ?? 0 });
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>(props?.types ?? []);
    const [documentStates, setDocumentStates] = useState<DocumentState[]>([]);
    const [documentCreate, setDocumentCreate] = useState<DocumentCreateProps>({
        documentType: {},
        documentTypes: [],
        open: false,
        keepMounted: true,
        onSave: (id?: number, action?: DocumentCreateSaveAction) => {
            // Obnovím zoznam
            setRowsFilter(prev => ({ ...prev }));

            // Rozšírené funkcie po uložení
            if (id !== undefined && action !== undefined) {
                if (action === DocumentCreateSaveAction.SaveAndNew) {
                    handleCreate(0);
                    return;
                }
                if (action === DocumentCreateSaveAction.SaveAndContinue) {
                    handleCreate(id ?? 0);
                    return;
                }
                if (action === DocumentCreateSaveAction.SaveAndNewFromCopy) {
                    handleCreate(id ?? 0, true);
                    return;
                }
                if (action === DocumentCreateSaveAction.SaveAndPrint) {
                    handlePrint(id ?? 0);
                    return;
                }
            }
        },
        onClose: () => setDocumentCreate(prev => ({ ...prev, open: false }))
    });
    const [documentsFiltering, setRowsFiltering] = useState<DocumentsFilteringProps>({
        open: false,
        keepMounted: true,
        onSave: (filter) => setRowsFilter({ ...filter, page: 0 }),
        onClose: () => setRowsFiltering(prev => ({ ...prev, open: false }))
    });
    const [documentsState, setDocumentsState] = useState<DocumentsStateProps>({
        open: false,
        keepMounted: true,
        onSelect: (id) => { },
        onClose: () => setDocumentsState(prev => ({ ...prev, open: false }))
    });

    // Generovanie dokladu
    const [documentsGenerate, setDocumentsGenerate] = useState<DocumentsGenerateProps>({
        documentIds: [],
        documentTypes: [],
        open: false,
        onClose: () => setDocumentsGenerate(prev => ({ ...prev, open: false }))
    });

    // Tlač dokladu
    const [documentPrint, setReportDocument] = useState<ReportDocumentProps>({
        documentId: 0,
        documentTypeId: props?.type ?? 0,
        open: false,
        preview: true,
        disableHtml: true,
        keepMounted: true,
        onClose: () => setReportDocument(prev => ({ ...prev, open: false }))
    });

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Automaticky upravím text "odberateľ / dodávateľ" v stĺpci partnera (po načítaní typu dokladu)
    useEffect(() => {
        if (documentType.sale === undefined) {
            return;
        }
        // Na predajnom doklade figuruje odberateľ
        const headerName = ((documentType.sale ?? false) === true ? 'Odberateľ' : 'Dodávateľ');
        // Zmenu stavu vyvolám iba ak sa text nezhoduje (zbytočné prekreslenie)
        if (columns.find(column => column.field === 'name')?.headerName !== headerName) {
            setColumns(prev => {
                const column = prev.find(column => column.field === 'name');
                if (column !== undefined) {
                    column.headerName = headerName;
                }
                return [...prev];
            });
        }
    }, [documentType.sale]); // eslint-disable-line react-hooks/exhaustive-deps

    // Nastavenia stĺpcov
    const gridSettings = new DataGridColumnSettings({ uid: 'documents-' + props?.type });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'done', headerName: 'Vybavené', hide: HidePoint().sm, width: 70, type: 'boolean', sortable: false },
        { field: 'paid', headerName: 'Uhradené', hide: HidePoint().sm, width: 70, type: 'boolean', sortable: false },
        {
            field: 'createdDate', headerName: 'Vytvorené', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'updatedDate', headerName: 'Upravené', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'dateOfIssue', headerName: 'Dátum vystavenia', hide: false, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfIssue']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfTaxLiability', headerName: 'Dátum zdaniteľného plnenia', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfTaxLiability']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfDue', headerName: 'Dátum splatnosti', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfDue']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfDelivery', headerName: 'Dátum dodania', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfDelivery']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfExpiration', headerName: 'Dátum platnosti', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfExpiration']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        { field: 'number', headerName: 'Číslo', hide: HidePoint().md, minWidth: 60, flex: 0.3 },
        {
            field: 'name', headerName: 'Odberateľ', hide: false, minWidth: 60, flex: 0.8,
            valueGetter: (params: GridValueGetterParams) => (params.row.person ?? false) === true ? (params.row.firstname ?? '') + ' ' + (params.row.lastname ?? '') : (params.row.company ?? '')
        },
        { field: 'trackingNumber', headerName: 'Sledovacie číslo', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'customerGroupName', headerName: 'Zákaznícka skupina', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        {
            field: 'documentStateName', headerName: 'Stav', hide: HidePoint().md, minWidth: 60, flex: 0.4, sortable: false,
            renderCell: (params: GridRenderCellParams<number>) => {
                let label = (params.row.documentStateName ?? '');
                let color = (params.row.documentStateColor ?? '');
                return <Chip label={label.length > 0 ? label : '...'} size="small" variant="filled" sx={{ bgcolor: '#' + (color.length > 0 ? params.row.documentStateColor : 'f0f0f0'), color: (color.length > 0 ? '#ffffff' : '#222222') }} component="a" onClick={() => { handleStateList([params.row.id]); }} />
            }
        },
        { field: 'symbolVariable', headerName: 'VS', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'symbolSpecific', headerName: 'ŠS', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'symbolConstant', headerName: 'KS', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'paymentName', headerName: 'Platba', hide: HidePoint().md, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'shipmentName', headerName: 'Doprava', hide: HidePoint().md, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'domainName', headerName: 'Doména', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'phone', headerName: 'Telefón', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'email', headerName: 'Email', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'firstname', headerName: 'Meno', hide: true, minWidth: 60, flex: 0.4 },
        { field: 'lastname', headerName: 'Priezvisko', hide: true, minWidth: 60, flex: 0.4 },
        { field: 'company', headerName: 'Firma', hide: true, minWidth: 60, flex: 0.4 },
        { field: 'regId', headerName: 'IČO', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'taxId', headerName: 'DIČ', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'vatId', headerName: 'IČDPH', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'city', headerName: 'Mesto', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        {
            field: 'street', headerName: 'Ulica', hide: true, minWidth: 60, flex: 0.4, sortable: false,
            valueGetter: (params: GridValueGetterParams) => (params.row.street ?? '') + ' ' + (params.row.houseNumber ?? '')
        },
        { field: 'countryName', headerName: 'Krajina', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'currencyCode', headerName: 'Kód meny', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'currencyExchange', headerName: 'Kurz meny', hide: true, minWidth: 60, flex: 0.2, sortable: false, align: 'center', headerAlign: 'center', type: 'number' },
        {
            field: 'priceTotalNoVat', headerName: 'Celkom bez DPH', hide: true, minWidth: 80, flex: 0.3, align: 'right', headerAlign: 'right', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'priceTotalWithVat', headerName: 'Celkom', hide: HidePoint().lg, minWidth: 80, flex: 0.3, align: 'right', headerAlign: 'right', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 160, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Vytlačiť" title="Vytlačiť" size="small" onClick={() => handlePrint(params.value ?? 0)}>
                        <PrintIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Kopírovať" title="Kopírovať (ctrl + enter)" size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => handleDelete(params.value ?? 0, 'Objednávka č.: ' + (params.row.number ?? ''))}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Možnosti zoradenia (index podľa DB, názov stĺpca v gride a jeho smer)
    const columnsSortDefault: number = 1;
    const columnsSort: GridSortModelIndex[] = [
        { index: 0, field: 'id', sort: 'asc' },
        { index: 1, field: 'id', sort: 'desc' },
        { index: 0, field: 'createdDate', sort: 'asc' },
        { index: 1, field: 'createdDate', sort: 'desc' },
        { index: 2, field: 'number', sort: 'asc' },
        { index: 3, field: 'number', sort: 'desc' },
        { index: 4, field: 'firstname', sort: 'asc' },
        { index: 5, field: 'firstname', sort: 'desc' },
        { index: 6, field: 'lastname', sort: 'asc' },
        { index: 7, field: 'lastname', sort: 'desc' },
        { index: 8, field: 'company', sort: 'asc' },
        { index: 9, field: 'company', sort: 'desc' },
        { index: 10, field: 'city', sort: 'asc' },
        { index: 11, field: 'city', sort: 'desc' },
        { index: 12, field: 'updatedDate', sort: 'asc' },
        { index: 13, field: 'updatedDate', sort: 'desc' },
        { index: 14, field: 'street', sort: 'asc' },
        { index: 15, field: 'street', sort: 'desc' },
        { index: 16, field: 'name', sort: 'asc' },
        { index: 17, field: 'name', sort: 'desc' },
        { index: 18, field: 'priceTotalNoVat', sort: 'asc' },
        { index: 19, field: 'priceTotalNoVat', sort: 'desc' },
        { index: 20, field: 'priceTotalWithVat', sort: 'asc' },
        { index: 21, field: 'priceTotalWithVat', sort: 'desc' },
        { index: 22, field: 'dateOfIssue', sort: 'asc' },
        { index: 23, field: 'dateOfIssue', sort: 'desc' },
        { index: 24, field: 'dateOfTaxLiability', sort: 'asc' },
        { index: 25, field: 'dateOfTaxLiability', sort: 'desc' },
        { index: 26, field: 'dateOfDue', sort: 'asc' },
        { index: 27, field: 'dateOfDue', sort: 'desc' },
        { index: 28, field: 'dateOfDelivery', sort: 'asc' },
        { index: 29, field: 'dateOfDelivery', sort: 'desc' },
        { index: 30, field: 'dateOfExpiration', sort: 'asc' },
        { index: 31, field: 'dateOfExpiration', sort: 'desc' },
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<DocumentLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<DocumentsFilter>({ page: 0, pageSize: gridSettings.pageSizeApply(25), sort: columnsSortDefault, documentTypeId: props?.type });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if ((rowsFilter.domainId ?? 0) !== 0) { count++; }
        if ((rowsFilter.customerGroupId ?? 0) !== 0) { count++; }
        if (rowsFilter.dateOfIssueFrom !== undefined) { count++; }
        if (rowsFilter.dateOfIssueTo !== undefined) { count++; }
        if (rowsFilter.createdDateFrom !== undefined) { count++; }
        if (rowsFilter.createdDateTo !== undefined) { count++; }
        if ((rowsFilter.done ?? 0) !== 0) { count++; }
        if ((rowsFilter.paid ?? 0) !== 0) { count++; }
        if ((rowsFilter.number ?? '') !== '') { count++; }
        if ((rowsFilter.documentStateId ?? 0) !== 0) { count++; }
        if ((rowsFilter.paymentId ?? 0) !== 0) { count++; }
        if ((rowsFilter.shipmentId ?? 0) !== 0) { count++; }
        if ((rowsFilter.trackingNumber ?? '') !== '') { count++; }
        if ((rowsFilter.priceTotalWithVatFrom ?? 0) !== 0) { count++; }
        if ((rowsFilter.priceTotalWithVatTo ?? 0) !== 0) { count++; }
        if ((rowsFilter.phone ?? '') !== '') { count++; }
        if ((rowsFilter.email ?? '') !== '') { count++; }
        if ((rowsFilter.firstname ?? '') !== '') { count++; }
        if ((rowsFilter.lastname ?? '') !== '') { count++; }
        if ((rowsFilter.company ?? '') !== '') { count++; }
        if ((rowsFilter.regId ?? '') !== '') { count++; }
        if ((rowsFilter.taxId ?? '') !== '') { count++; }
        if ((rowsFilter.vatId ?? '') !== '') { count++; }
        if ((rowsFilter.city ?? '') !== '') { count++; }
        if ((rowsFilter.countryId ?? 0) !== 0) { count++; }
        return count;
    };

    // Automatické odovzdanie označených záznamov, ak je nastavená udalosť
    useEffect(() => {
        if (props?.onSelectionChanged) {
            const ids = rowsSelected.map(r => r as number);
            props.onSelectionChanged(rows.filter(item => ids.includes((item.id ?? 0))) ?? [], false);
        }
    }, [rowsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        let sortIndex: number = columnsSortDefault;
        if (sortModel !== undefined && sortModel.length > 0) {
            sortIndex = columnsSort.find(s => s.field === sortModel[0].field && s.sort === sortModel[0].sort)?.index ?? 0;
        }
        if (rowsFilter.sort !== sortIndex) {
            setRowsFilter(prev => ({ ...prev, sort: sortIndex }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Dvoj-klik v zozname
    const handleDoubleClick = (id: number, field: string) => {
        // Ak požadujem výber záznamu, tak po dvoj-kliku prenesiem konkrétny záznam
        if (props?.onSelectionChanged) {
            const item = rows.find(item => item.id === id);
            if (item !== undefined) {
                props.onSelectionChanged([item], true);
                return;
            }
        }
        // Predvolená akcia pre úpravu záznamu
        handleCreate(id, false, field);
    };

    // Vygenerovať nový alebo stiahnuť nahraný doklad
    const handlePrint = (id: number) => {
        // Vygenerovať nový report
        if (id > 0) {
            setReportDocument(prev => ({ ...prev, documentId: id, open: true }));
            return;
        }
    };

    // Funkcia pre generovanie dokladu
    const handleGenerate = (ids: number[], parent: boolean) => {
        if (ids.length === 0) {
            return;
        }
        setDocumentsGenerate(prev => ({ ...prev, documentIds: ids, generateParent: parent, open: true }));
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setDocumentCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.DOCUMENTS + (props?.type ?? 0).toString() + '/');
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatický filter podľa url "filter"
    const requestFilter: string = useQuery().get('filter') ?? '';
    useEffect(() => {
        if (requestFilter.length > 0) {
            history.push(AppRouteUrl.DOCUMENTS + (props?.type ?? 0).toString() + '/');
            if (requestFilter === 'notdone') {
                setRowsFilter(prev => ({ ...prev, page: 0, done: 2 }));
            }
        }
    }, [requestFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'document/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'document/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: gridSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number),
            specific: [{
                field: 'name',
                getValue: (row: any) => {
                    return (row?.person ?? false) === true ? (row?.firstname ?? '') + ' ' + (row?.lastname ?? '') : (row?.company ?? '');
                }
            }, {
                field: 'street',
                getValue: (row: any) => {
                    return (row?.street ?? '') + ' ' + (row?.houseNumber ?? '');
                }
            }]
        });
    };

    // Funkcie pre označenie vybavené / uhradené
    const handleDoneList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vybavené záznamy: ' + ids.length, children: 'Skutočne chcete označiť vybrané záznamy ako vybavené?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'document/done', null, {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handlePaidList = (ids: number[], payment: boolean) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Uhradené záznamy: ' + ids.length, children: 'Skutočne chcete označiť vybrané záznamy ako uhradené?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'document/paid', null, {
                        params: {
                            'ids': ids,
                            'payment': payment
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadDataSafe();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleStateList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setDocumentsState(prev => ({
            ...prev, open: true, onSelect: (id: number) => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'document/state', null, {
                        params: {
                            'ids': ids,
                            'documentStateId': id
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadDataSafe();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Načítam zoznam stavov 
    const loadDocumentStates = () => {
        if (props?.type === 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'documentstate', {
                params: {
                    'documentTypeId': props?.type ?? 0
                }
            })
            .then(response => {
                setDocumentStates(response.data.filter((documentstate: DocumentState) => documentstate.actived));
            });
    };
    useEffect(() => loadDocumentStates(), [props?.type]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam zoznam 
    const loadDocumentTypes = () => {
        if (documentTypes.length > 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'documenttype')
            .then(response => {
                setDocumentTypes(response.data as DocumentType[]);
            });
    };
    useEffect(() => loadDocumentTypes(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Automaticky nájdem aktuálny typ dokladu po zmene id druhu dokladu, alebo načítania číselníku
    useEffect(() => {
        if ((props?.type ?? 0) === 0 || documentTypes.length === 0) {
            return;
        }
        setDocumentType(documentTypes.find(item => item.id === props?.type) ?? {});
    }, [props?.type, documentTypes]);

    // Funkcia pre načítanie záznamov
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'document', {
                params: rowsFilter
            })
            .then(response => {
                setRows(response.data?.list ?? []);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => {
        setRowsFilter(prev => ({ ...prev }));
    }

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* Preloader */}
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Obsah */}
            <Content>
                <ContentTop>
                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nový doklad</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleStateList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><FlagIcon fontSize="small" /></ListItemIcon> Zmeniť stav
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDoneList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><CheckBoxIcon fontSize="small" /></ListItemIcon> Označiť ako vybavené
                                </MenuItem>
                                <MenuItem onClick={() => { handlePaidList(rowsSelected.map(r => r as number), false); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><EuroIcon fontSize="small" /></ListItemIcon> Označiť ako uhradené (bez úhrady)
                                </MenuItem>
                                <MenuItem onClick={() => { handlePaidList(rowsSelected.map(r => r as number), true); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><EuroIcon fontSize="small" /></ListItemIcon> Označiť ako uhradené (s úhradou)
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleGenerate(rowsSelected.map(r => r as number), true); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DescriptionIcon fontSize="small" /></ListItemIcon> Generovať predchádzajúci doklad
                                </MenuItem>
                                <MenuItem onClick={() => { handleGenerate(rowsSelected.map(r => r as number), false); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DescriptionIcon fontSize="small" /></ListItemIcon> Generovať nasledujúci doklad
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        documentTypeId: prev.documentTypeId
                                    }));
                                }}
                                autoFocus={true}
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setRowsFiltering(prev => ({ ...prev, filter: rowsFilter, open: true }))} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rows}
                            rowCount={rowsCount}

                            pagination
                            paginationMode="server"
                            page={rowsFilter.page}
                            pageSize={rowsFilter.pageSize}

                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                            onPageSizeChange={(pageSize) => {
                                 setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                 gridSettings.pageSizeChanged(pageSize);
                            }}

                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}

                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                handleDoubleClick(e.row.id, e.field);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, true, e.field);
                                    return;
                                }
                                if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, false, e.field);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.id, 'Objednávka č.: ' + (e.row.number ?? ''));
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </ContentBottom>
            </Content>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <DocumentCreate {...documentCreate} documentType={documentType} documentTypes={documentTypes} />

            {/* Formulár pre filtráciu */}
            <DocumentsFiltering {...documentsFiltering} documentStates={documentStates} />

            {/* Zmena stavu */}
            <DocumentsState {...documentsState} documentStates={documentStates} />

            {/* Generovanie dokladu */}
            <DocumentsGenerate {...documentsGenerate} documentTypes={documentTypes} />

            {/* Tlač dokladu */}
            <ReportDocument {...documentPrint} />
        </>
    )
}

export default Documents;