import { createTheme } from '@mui/material';
import type { } from '@mui/x-data-grid/themeAugmentation';
import { skSK } from '@mui/material/locale';
import { Theme } from '@mui/material/styles/createTheme';

/*  Ako to funguje 
    -------------------------------
    Hlavný App element vygeneruje a aplikuje tému "Theme", ktorá má na vstupe prepínač pre tmavú tému.
    Niektoré farby sú nastavené len pre tmavú tému (svetlá sa zachová v originále) a niektoré aj pre tmavú aj svetlú.
    Súbor "StylesGlobal" obsahuje len CSS, ktoré nie je cielené na farebnú schému.
    Hlavný App vloží do obsahu "StylesGlobal" a popri tom strieda farebné štýly podľa témy.
    Komponent použije háčik: const theme = useTheme(); získanie témy.
    
    POZOR!
    -------------------------------
    Pri zmene témy sa GlobalStyle obnovia iba 1x kvôli <StrictMode> v roote aplikácie: https://github.com/mui-org/material-ui/issues/23334
    Strict mód je aktívny iba pri debug v produkcii to problém robiť nebude.
*/

// Predvolené nastavenie témy
const DefaultTheme = (dark: boolean): Theme => {
    return createTheme({
        typography: {
            fontFamily: 'Roboto Condensed, Helvetica, Arial, sans-serif'
        },
        palette: {
            mode: (dark ? 'dark' : 'light'),
            secondary: {
                light: (dark ? '#8e98a0' : '#8e98a0'),
                main: (dark ? '#6c757d' : '#6c757d'),
                dark: (dark ? '#525a62' : '#525a62'),
            }
        },
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        backgroundColor: (dark ? '#181818' : '#ffffff')
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        ...(dark && { backgroundColor: 'rgba(0, 0, 0, 0.8)' })
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        ...(dark && { backgroundColor: '#181818' }),
                        fontSize: 30,
                        textAlign: 'center',
                        padding: '40px 0 10px 0'
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        ...(dark && { backgroundColor: '#181818' }),
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        ...(dark && { backgroundColor: '#181818' }),
                        padding: 20
                    }
                }
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        /*backdropFilter: 'blur(3px)',*/
                        background: 'rgba(0, 0, 0, .1)'
                    }
                }
            }
        },
        layout: {
            body: {
                backgroundColor: (dark ? '#121212' : '#f4f3ef')
            },
            sidebar: {
                backgroundColor: (dark ? '#222222' : '#ffffff'),
                borderColor: (dark ? '#333333' : '#e8e7e3'),
                collapse: {
                    backgroundColor: (dark ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)'),
                    borderColor: (dark ? '#ddc04a' : '#ddc04a')
                }
            },
            content: {
                backgroundColor: (dark ? '#222222' : '#ffffff')
            }
        }
    }, skSK);
}

export default DefaultTheme;

// Rozšírenia témy pre typescript
declare module '@mui/material/styles' {
    interface Theme {
        layout: {
            body: {
                /** Farba pozadia stránky */
                backgroundColor: string;
            },
            sidebar: {
                backgroundColor: string;
                borderColor: string;
                /** Podradený element v sidebare */
                collapse: {
                    backgroundColor: string;
                    borderColor: string;
                }
            },
            content: {
                backgroundColor: string;
            }
        };
    }
    interface ThemeOptions {
        layout?: {
            body?: {
                backgroundColor?: string;
            },
            sidebar?: {
                backgroundColor?: string;
                borderColor?: string;
                collapse?: {
                    backgroundColor?: string;
                    borderColor?: string;
                }
            },
            content?: {
                backgroundColor?: string;
            }
        };
    }
}