import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface MessageBoxProps {
    children: any;
    open: boolean;
    title: string;
    onClose?: () => void;
};

const MessageBox = (props: MessageBoxProps) => {

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                onKeyDown={e => {
                    if (e.code === 'Enter' && props.onClose !== undefined) {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onClose();
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
                        {props.children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MessageBox;