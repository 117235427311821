import { useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../../AppRoutes';

// Modely
import { ExportQueue, ImportExportQueueStates } from '../../../models/Models';

// Utility
import { FindText } from '../../../utility/Search';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../../utility/DataGrid';
import { useQuery } from '../../../utility/URL';
import format from 'date-fns/format';

// Komponenty
import { DataGrid, GridColDef, skSK, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Button, Chip, Divider, Grid, IconButton, Link, ListItemIcon, Menu, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import Search from '../../../components/Search';
import ExportQueueInfo, { ExportQueueInfoProps } from './ExportQueueInfo';
import { Content, ContentTop, ContentBottom } from '../../../layout/Content';
import DataGridDensity from '../../../components/DataGridDensity';
import MessageBox, { MessageBoxProps } from '../../../components/MessageBox';
import ExportCreate, { ExportCreateProps } from '../export/ExportCreate';

// Ikony
import ReplayIcon from '@mui/icons-material/Replay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import BlockIcon from '@mui/icons-material/Block';
import DescriptionIcon from '@mui/icons-material/Description';
import UploadFileIcon from '@mui/icons-material/UploadFile';

// Komponent pre zoznam 
const Countries = () => {

    // Počet sekúnd pre automatickú obnovu
    const timerTime = 30;

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [timer, setTimer] = useState<number>(timerTime);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [exportQueueInfo, setExportQueueInfo] = useState<ExportQueueInfoProps>({
        open: false,
        keepMounted: true,
        onClose: () => setExportQueueInfo(prev => ({ ...prev, open: false }))
    });
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({
        open: false, title: '', children: null, onClose: () => {
            setMessageBox(prev => ({
                ...prev, open: false
            }));
        }
    });
    const [exportCreate, setExportCreate] = useState<ExportCreateProps>({
        open: false,
        keepMounted: true,
        queue: true,
        onSave: () => loadData(),
        onClose: () => setExportCreate(prev => ({ ...prev, open: false }))
    });

    // Url pre export (pri DEBUG potrebujem URL podľa nastavenia)
    const exportFileUri = (AppConfig.ApiUri !== '/api/' ? AppConfig.ApiUri : window.location.protocol + '//' + window.location.host + '/api/') + 'export/file/?token=';

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'exportqueues' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'url', headerName: 'Url', hide: false, minWidth: 50, flex: 0.9,
            valueGetter: (params: GridValueGetterParams) => exportFileUri + params.row.token,
            renderCell: (params: GridRenderCellParams<string>) => {
                let url = (params?.value ?? '');
                return <Link href={url} target='_blank' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{url}</Link>
            }
        },
        { field: 'note', headerName: 'Poznámka', hide: false, minWidth: 50, flex: 0.6 },
        { field: 'client', headerName: 'Klient', hide: false, minWidth: 50, flex: 0.4 },
        {
            field: 'state', headerName: 'Stav', hide: false, minWidth: 60, flex: 0.4,
            valueGetter: (params: GridValueGetterParams) => (ImportExportQueueStates.find(item => item.id === (params?.value ?? 0))?.name ?? ''),
            renderCell: (params: GridRenderCellParams<string>) => {
                let color = (ImportExportQueueStates.find(item => item.id === (params.row?.state ?? 0))?.color ?? '');
                let label = (params?.value ?? '');
                return <Chip label={label} size="small" variant="filled" sx={{ bgcolor: '#' + (color.length > 0 ? color : 'f0f0f0'), color: (color.length > 0 ? '#ffffff' : '#222222') }} />
            }
        },
        { field: 'priority', headerName: 'Priorita', hide: false, minWidth: 50, flex: 0.2, align: 'center', headerAlign: 'center' },
        {
            field: 'createdDate', headerName: 'Vytvorené', hide: false, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => format((params?.value as Date), 'dd.MM.yyyy HH:mm') ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravené', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 80, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton disabled={(params.row.state ?? 0) === 0} aria-label="Informácie" title="Informácie (enter)" size="small" onClick={() => handleInfo(params.value ?? 0)}>
                        <DescriptionIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={(params.row.state ?? 0) >= 2} aria-label="Zrušiť" title="Zrušiť (delete)" size="small" onClick={() => handleCancel(params.value ?? 0, params.row.note)}>
                        <BlockIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<ExportQueue[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<ExportQueue[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pridať upraviť záznam
    const handleInfo = (id: number) => {
        setExportQueueInfo(prev => ({
            ...prev,
            id: id,
            open: true
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.IMPORT_QUEUE);
            handleInfo(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleCancel = (id: number, name: string) => {
        if (name.length > 25) {
            name = name.substring(0, 25) + '...';
        }
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete zrušiť tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'exportqueue/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleCancelList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Zrušiť záznamy: ' + ids.length, children: 'Skutočne chcete zrušiť vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'exportqueue/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: columnsSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number),
            specific: [
                {
                    field: 'priority',
                    getValue: (row: any) => (row?.priority ?? 1) === 1 ? 'Nízka' : 'Vysoká'
                },
                {
                    field: 'url',
                    getValue: (row: any) => exportFileUri + row.token
                }
            ]
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        setTimer(timerTime);
        axios
            .get(AppConfig.ApiUri + 'exportqueue')
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    // Časovač pre automatickú obnovu
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prev => (prev - 1));
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        if (timer < 0) {
            loadData();
        }
    }, [timer]);

    return (
        <>
            {/* Formulár pre nový záznam */}
            <ExportCreate {...exportCreate} />

            {/* Upozornenie */}
            <MessageBox {...messageBox} />

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <ExportQueueInfo {...exportQueueInfo} />

            {/* Obsah */}
            <Content>
                <ContentTop>

                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={12} md lg>

                            {/* Obnoviť zoznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large"
                                startIcon={
                                    loading === true ?
                                        <ReplayIcon sx={{ animation: "spin 2s linear infinite", "@keyframes spin": { "0%": { transform: "rotate(360deg)", }, "100%": { transform: "rotate(0deg)", }, }, }} /> :
                                        <ReplayIcon />
                                }
                                onClick={() => loadData()}>Obnoviť <Typography component="div" sx={{ width: '35px', textAlign: 'right', opacity: 0.4 }}>({timer})</Typography></Button>

                            {/* Vybrať súbor pre export */}
                            <Button color="primary" variant="outlined" sx={{ mr: 1 }} size="large" startIcon={<UploadFileIcon />} onClick={() => { setExportCreate(prev => ({ ...prev, open: true })) }}>Exportovať</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleCancelList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><BlockIcon fontSize="small" /></ListItemIcon> Zrušiť záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={6} lg={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setSearch(s)}
                                onClear={() => {
                                    setSearch('');
                                    setFilterModel({ items: [], linkOperator: undefined });
                                }}
                                autoFocus={true} />
                        </Grid>
                    </Grid>

                </ContentTop>
                <ContentBottom>

                    {/* Zoznam */}
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rowsFiltered}
                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                if ((e.row.state ?? 0) === 0) {
                                    return;
                                }
                                handleInfo(e.row.id);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    if ((e.row.state ?? 0) === 0) {
                                        return;
                                    }
                                    handleInfo(e.row.id);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    if ((e.row.state ?? 0) >= 2) {
                                        return;
                                    }
                                    handleCancel(e.row.id, e.row.note);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>

                </ContentBottom>
            </Content>
        </>
    )
}

export default Countries;