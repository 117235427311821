import React, { useEffect, useState } from 'react';

// Modely
import { Parameter, ParameterItem } from '../../../models/Models';

// Komponenty
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';

export interface ParameterConfiguratorProps {
    parameters: Parameter[];
    onSelectionChanged?: (items: ParameterItem[], confirm: boolean) => void;
}

const ParameterConfigurator = (props: ParameterConfiguratorProps) => {

    // Stav
    const [itemsSelected, setItemsSelected] = useState<number[]>([]);

    // Automatické odovzdanie označených záznamov, ak je nastavená udalosť
    useEffect(() => {
        if (props?.onSelectionChanged) {
            // Prejdem všetky parametere typu "konfigurátor" (type == 2 a type == 3) a získam všetky položky, ktoré boli označené
            const items: ParameterItem[] = [];
            props?.parameters?.filter(parameter => parameter.type === 2 || parameter.type === 3)?.forEach(parameter => {
                parameter?.items?.filter(item => itemsSelected?.includes(item.id ?? 0))?.forEach(item => items.push(item));
            });
            props.onSelectionChanged(items, false);
        }
    }, [itemsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Zlúči id položiek parametra s id položiek ostatných parametrov tak, že najskôr premaže všetky id položiek parametra a potom pridá nové
    const handleParameterValues = (parameterId: number, values: number[]) => {
        const items = props.parameters.find(item => item.id === parameterId)?.items?.map(item => item.id ?? 0) ?? [];
        const parameters = [...(itemsSelected?.filter(item => !items.includes(item)) ?? []), ...values?.filter(item => item > 0) ?? []];
        setItemsSelected(parameters);
    };

    // Vráti vybrané id položiek, ktoré patria pod daný parameter
    const getParameterValues = (parameterId: number, multiple: boolean): number[] | number => {        
        const result = props.parameters.find(item => item.id === parameterId)?.items?.filter(item => itemsSelected?.includes(item.id ?? 0))?.map(item => item.id ?? 0) ?? [];
        return (multiple ? result : result[0] ?? 0);
    };

    return (
        <>
            <Grid container columnSpacing={1}>
                {props.parameters?.filter(item => item.actived && (item.type === 2 || item.type === 3)).map((parameter, index) => (
                    <Grid item xs={12} key={index}>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <InputLabel id={'parameter-configurator-' + parameter.id}>{parameter.other?.translationName + (parameter.required === true ? ' *' : '')}</InputLabel>
                            <Select multiple={parameter.type === 3} labelId={'parameter-configurator-' + parameter.id} label={parameter.other?.translationName + (parameter.required === true ? ' *' : '')} name="parameters"
                                value={getParameterValues((parameter.id ?? 0), (parameter.type === 3))}
                                onChange={(e) => handleParameterValues(parameter.id ?? 0, (parameter.type === 3 ? e.target.value as number[] : [e.target.value] as number[]))}>
                                {parameter.type === 2 && <MenuItem value={0}>-</MenuItem>}
                                {parameter.items?.filter(item => item.actived).map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default ParameterConfigurator;