import axios from 'axios';
import React, { useState } from 'react'
import { AppConfig } from '../../AppConfig';
import { Alert, AlertColor, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment } from '@mui/material';
import { PasswordStrength, PasswordStrengthType } from '../../utility/PasswordStrength';

// Ikony
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface Props {
    onClose: () => void;
}

const PasswordChange = (props: Props) => {

    const [loading, setLoading] = React.useState(false);
    const [warning, setWarning] = useState('');
    const [warningSeverity, setWarningSeverity] = useState('warning');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const password = data.get('password')?.toString() ?? '';
        const passwordVerify = data.get('passwordverify')?.toString() ?? '';

        if(password.length === 0) {
            setWarningSeverity('warning');
            setWarning('Zadajte nové heslo!');
            return;
        }
        if(password !== passwordVerify) {
            setWarningSeverity('warning');
            setWarning('Heslá sa nezhodujú!');
            return;
        }
        if( PasswordStrength(password) !== PasswordStrengthType.Strong &&
            PasswordStrength(password) !== PasswordStrengthType.Medium) {
                setWarningSeverity('warning');
                setWarning('Heslo musí mať minimálne 8 znakov a obsahovať veľké písmeno, malé písmeno a číslicu.');
            return;
        }

        setWarning('');
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'user/passwordchange', null, { params: {
                'password': password
            }})
            .then(response => {
                if(response.data === 'success') { 
                    setWarningSeverity('success');
                    setWarning('Heslo bolo úspešne zmenené!');
                    (event.target as HTMLFormElement).reset();
                } else {
                    setWarningSeverity('error');
                    setWarning('Heslo sa nepodarilo zmeniť!');
                }
            })
            .finally(() => {
                setLoading(false);
            }); 
    };

    return (
        <>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog maxWidth="xs" fullWidth open={true} onClose={props.onClose} scroll="body">
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <DialogTitle>Zmena hesla</DialogTitle>
                    <DialogContent>
                        <TextField margin="dense" autoFocus name="password" type="password" required label="Nové heslo" fullWidth variant="outlined" autoComplete="new-password"
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <LockOutlinedIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                        <TextField margin="dense" name="passwordverify" type="password" required label="Nové heslo znovu" fullWidth variant="outlined" autoComplete="new-password" 
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <LockOutlinedIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                        {(warning.length > 0 && <Alert sx={{ mt: 1 }} severity={ warningSeverity as AlertColor }>{warning}</Alert>)}  
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Potvrdiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default PasswordChange;
