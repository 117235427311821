// Integrácia: https://www.tiny.cloud/docs/integrations/react/, https://pretagteam.com/question/loading-tinymce-without-cloud-in-react 
/*  
    Základný TinyMCE Core je navždy zdarma aj na komerčné účely.
    Ak sa editor alebo niektorá časť naťahuje z cloudu, tak musí byť doména registrovaná v cloude, inak sa zobrazí upozornenie.
    Predvolene sa všetky súčasti naťahujú automaticky z cloudu, takže pre vyhnutie sa hlášky (nepoužívania clodu) je potrebné načítať súčasti z projektu (import xy...).
    Je to tak preto, že ak by sa niekto rozhodol používať platené súčasti, stačí zapnúť súčasť a doplniť api key konfiguráciu editora a je to funkčné bez inštalácie súčastí.
*/

// Poznámky
/*
    MUI predvolene blokuje opustenie modálneho dialógového okna (Modal, Dialog, ...) to bráni v interakcii z akýmkoľvek kontextovými oknami TinyMCE. 
    Riešením je použitie atribútu "disableEnforceFocus" v nadradenom okne.
*/

// TinyMCE
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/visualblocks';

import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
//import 'tinymce/skins/content/default/content.min.css'; // poškodí layout admina

// Modely
import { File } from '../../models/Models';

// Komponenty
import { Editor } from '@tinymce/tinymce-react';
import FilesDialog, { FilesDialogProps } from '../file/FilesDialog';
import { useState } from 'react';

// Vstupné parametre
export interface TinyMCEProps {
    content: string;
    contentStyle?: string;
    onChange?: (content: string) => void;
    onCancel?: () => void;
}

// Predpis pre ono výber súboru
interface FilesDialogArgument {
    image: boolean;
    callback: (src: string, alt: string) => void;
}

// Komponent pre zoznam užívateľov
const TinyMCE = (props: TinyMCEProps) => {

    // Stav
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        watermark: false,
        onSelect: (files: File[], argument: FilesDialogArgument) => {
            if (files.length > 0) {
                // Ak požadujem obrázok, ale vybral som iný súbor, tak ďalej nepokračujem
                if (argument.image && !files[0].fileIsImage) {
                    return;
                }
                // Odovzdám súbor
                argument.callback(files[0].fileSrc ?? '', files[0].name ?? '');
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = (argument: FilesDialogArgument) => {
        setFilesDialog(prev => ({ ...prev, open: true, argument: argument }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Udalosti
    const handleEditorChange = (content: any, editor: any) => {
        if (props.onChange) {
            props.onChange(content as string);
        }
    }

    return (
        <>
            <FilesDialog {...filesDialog} />
            <Editor
                //initialValue={props.content}
                init={{
                    skin: false,
                    height: 700,
                    menubar: true,
                    content_css: true,
                    automatic_uploads: false,
                    paste_as_text: true,
                    paste_block_drop: false,
                    language: 'sk',
                    language_url: '/assets/tinymce-sk.js',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | fontsizeselect | ' +
                        'bold italic | forecolor backcolor | image link | ' +
                        'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    visual: true,
                    visual_table_class: 'table-visual-border',
                    image_advtab: true,
                    relative_urls : false,
                    remove_script_host : false,
                    convert_urls: false,
                    custom_elements: 'style',
                    content_style: (props.contentStyle ?? '') +
                    `                        
                        .table-visual-border {      border: 1px dashed rgba(136,136,136,0.3); } 
                        .table-visual-border td {   border: 1px dashed rgba(136,136,136,0.3); }
                    `,
                    file_picker_callback: function (callback, value, meta) {
                        var argImage = (meta.filetype === 'image');
                        var argCallback = (src: string, alt: string) => {
                            callback(src, {
                                alt: alt
                            });
                        };
                        handleFilesOpen({ image: argImage, callback: argCallback });
                    }
                }}
                value={props.content}
                onEditorChange={handleEditorChange}
            />
        </>
    )
}

export default TinyMCE;