import React, { FormEvent, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { CustomerBase, Country, Customer, CustomerLite, CustomerBaseFromCustomer } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select, FormControlLabel, Radio, RadioGroup, Typography, Switch, Menu, Backdrop, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CustomersDialog, { CustomersDialogProps } from '../customer/CustomersDialog';

// Ikony
import ContactsIcon from '@mui/icons-material/Contacts';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import NumbersIcon from '@mui/icons-material/Numbers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SchoolIcon from '@mui/icons-material/School';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SearchIcon from '@mui/icons-material/Search';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface CustomerBaseEditProps {
    customer: CustomerBase;
    countries?: Country[];
    open: boolean;
    openCustomers?: boolean; // automaticky zobrazí adresár pre výber
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (customer: CustomerBase) => void;
    onClose: () => void;
}

const CustomerBaseEdit = (props: CustomerBaseEditProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<CustomerBase>({});
    const [customer, setCustomer] = useState<Customer>({});
    const [customerRegister, setCustomerRegister] = useState<CustomerBase>({});
    const [addressesMenuEl, setAddressesMenuEl] = useState<null | HTMLElement>(null);
    const [banksMenuEl, setBanksMenuEl] = useState<null | HTMLElement>(null);
    const [countries, setCountries] = useState<Country[]>(props.countries ?? []);
    const [closeAfterSelect, setCloseAfterSelect] = useState<boolean>(false);

    // Načítam zoznam krajín
    const loadCountries = () => {
        axios
            .get(AppConfig.ApiUri + 'country')
            .then(response => {
                setCountries(response.data.filter((country: Country) => country.actived));
            });
    };

    // Načítam krajiny, ak nefigurujú na vstupe
    useEffect(() => {
        if (props.countries !== undefined) {
            setCountries(props.countries);
        } else {
            loadCountries();
        }
    }, [props.countries]);

    // Automaticky naplním zdroj
    useEffect(() => {
        if (!props.open) {
            if (closeAfterSelect === true) {
                setCloseAfterSelect(false);
            }
            setCustomerRegister({});
            return;
        }
        setSource(props.customer ?? {});
        if ((props.customer.customerId ?? 0) > 0) {
            axios
                .get(AppConfig.ApiUri + 'customer/' + props.customer.customerId)
                .then(response => {
                    if (response.data !== null) {
                        setCustomer(response.data as Customer);
                    }
                });
        } else {
            setCustomer({});
        }

        // Automaticky zobrazím okno pre výber zo zoznamu, po výbere okno pre úpravu automaticky sa zavriem (v nadradenom formulári som neklikol som na upraviť ale rovno vybrať)
        if ((props.openCustomers ?? false) === true) {
            handleCustomersOpen();
            setTimeout(() => setCloseAfterSelect(true), 500); // Automatické zavrenie aktivujem oneskorene, keďže dokončenie setSource pri otvorení by vyvolalo ihneď zavretie okna (ak už source má vybraného partnera)
        }
    }, [props.customer, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak som automaticky zobrazoval okno pre výber zo zoznamu, tak po výbere okno pre úpravu automaticky zavriem (v nadradenom formulári som neklikol som na upraviť ale rovno vybrať)
    useEffect(() => {
        if (closeAfterSelect === true && (source.customerId ?? 0) > 0) {
            handleSave();
        }
    }, [source.customerId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setCustomer({});
        setCustomerRegister({});
        setSource(prev => ({
            ...prev,
            customerId: 0,
            customerGroupId: 0,
            domainId: 0,
            person: false,
            firstname: '',
            lastname: '',
            company: '',
            regId: '',
            taxId: '',
            vatId: '',
            vatType: 0,
            city: '',
            street: '',
            houseNumber: '',
            postcode: '',
            countryId: 0,
            phone: '',
            email: '',
            deliveryAddressCustom: false,
            deliveryAddressName: '',
            deliveryAddressCity: '',
            deliveryAddressStreet: '',
            deliveryAddressHouseNumber: '',
            deliveryAddressPostcode: '',
            deliveryAddressCountryId: 0,
            bankName: '',
            bankIban: '',
            bankNumber: '',
            bankCode: '',
            bankSwift: ''
        }))
    };

    // Otvorenie URL adresy
    const openUrl = (url: string, target?: string) => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        if (target !== undefined) {
            link.setAttribute("target", target);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Vyhľadanie adresy, nový email, volať..
    const handleOpenMap = (address: string) => {
        openUrl('http://maps.google.com/?q=' + address, '_blank');
    }
    const handleOpenCall = (phone: string) => {
        openUrl('tel:' + phone);
    }
    const handleOpenemail = (email: string) => {
        openUrl('mailto:' + email);
    }
    const handleOpenGoogle = (search: string) => {
        openUrl('https://www.google.com/search?q=' + search, '_blank');
    }
    const handleOpenORSR = (regId: string) => {
        openUrl('https://orsr.sk/hladaj_ico.asp?ICO=' + regId, '_blank');
    }
    const handleOpenFinstat = (regId: string) => {
        openUrl('https://finstat.sk/' + regId, '_blank');
    }
    const handleOpenFacebook = (name: string) => {
        openUrl('https://www.facebook.com/search/top/?q=' + name, '_blank');
    }
    const handleOpenLinkedIn = (name: string) => {
        openUrl('https://www.linkedin.com/search/results/all/?keywords=' + name, '_blank');
    }
    const handleRegisterFind = () => {
        if ((source.regId ?? '').length < 5) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customer/register/' + source.regId)
            .then(response => {
                if (response.data !== null) {
                    setCustomerRegister(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // Uloženie formuláru
    const handleSave = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        event?.stopPropagation();
        props.onSave(source);
        props.onClose();
    };

    // Zrušenie relácie so zákazníkom
    const handleCustomerRelationCancel = () => {
        setCustomer({});
        setSource(prev => ({
            ...prev,
            domainId: 0,
            customerId: 0,
            id: 0
        }))
    };

    // Výber existujúceho zákazníka
    const [customersDialog, setCustomersDialog] = useState<CustomersDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (customers: CustomerLite[]) => {
            if (customers.length > 0) {
                const customerId = customers[0].id ?? 0;
                if (customerId > 0) {
                    customerLoad(customerId);
                }
            }
            handleCustomersClose();
        },
        onClose: () => handleCustomersClose()
    });

    // Načítanie údajov zákazníka
    const customerLoad = (customerId: number) => {
        if (customerId > 0) {
            setLoading(true);
            axios
                .get(AppConfig.ApiUri + 'customer/' + customerId)
                .then(response => {
                    if (response.data !== null) {
                        const customerSelected = response.data as Customer;
                        setSource(prev => ({
                            ...prev,
                            ...CustomerBaseFromCustomer(customerSelected),
                        }));
                        setCustomer(customerSelected);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    // Zobrazenie zákazníkov
    const handleCustomersOpen = () => {
        setCustomersDialog(prev => ({ ...prev, open: true, keepMounted: true }));
    };
    const handleCustomersClose = () => {
        setCustomersDialog(prev => ({ ...prev, open: false }));
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Informácie o vybranom zákazníkovi
    const customerInfo = (customer.person ?? false) === true ? (customer.firstname ?? '') + ' ' + (customer.lastname ?? '') : (customer.company ?? '');

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Partner
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <TextField margin="dense" label="Existujúci partner" fullWidth variant="outlined" autoComplete="off" disabled onClick={handleCustomersOpen}
                                    value={((customer.id ?? 0) > 0 ? customerInfo : '...')}
                                    inputProps={{ style: { cursor: 'pointer' } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(source.customerId ?? 0) > 0 && (
                                <Grid item xs={12}>
                                    <Alert severity='info'>Zmena údajov v tomto formulári nemá vplyv na údaje existujúceho partnera.</Alert>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Button variant="contained" size="small" startIcon={<ContactsIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} onClick={handleCustomersOpen}>Vybrať</Button>
                                {(source.customerId ?? 0) > 0 && <>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => customerLoad((source.customerId ?? 0))}>Obnoviť</Button>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} href={AppRouteUrl.CUSTOMERS + '?id=' + (source.customerId ?? 0).toString()} target="_blank">Detail</Button>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={handleCustomerRelationCancel}>Zrušiť prepojenie</Button>
                                </>}
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>
                                <RadioGroup row name="person" value={source.person ?? false} onChange={(e) => { handleChange(e.target.name, (e.target.value === 'true')); }}>
                                    <FormControlLabel value="true" control={<Radio />} label="Osoba" />
                                    <FormControlLabel value="false" control={<Radio />} label="Firma" />
                                </RadioGroup>
                            </Grid>

                            {(source.person ?? false) === false && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography textAlign="center" variant="h6" gutterBottom>Firemné údaje</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField margin="dense" name="company" label="Názov firmy" fullWidth variant="outlined" autoComplete="off" value={source.company ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => ((source.person ?? false) === false && (props.autoFocus === 'name' || props.autoFocus === 'company')) && setFocus(input)}
                                            inputProps={{ maxLength: 50 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CorporateFareIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <TextField margin="dense" name="regId" label="IČO" fullWidth variant="outlined" autoComplete="off" value={source.regId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'regId') && setFocus(input)}
                                            inputProps={{ maxLength: 15 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <NumbersIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <TextField margin="dense" name="taxId" label="DIČ" fullWidth variant="outlined" autoComplete="off" value={source.taxId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'taxId') && setFocus(input)}
                                            inputProps={{ maxLength: 18 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <NumbersIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <TextField margin="dense" name="vatId" label="IČDPH" fullWidth variant="outlined" autoComplete="off" value={source.vatId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'vatId') && setFocus(input)}
                                            inputProps={{ maxLength: 20 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <NumbersIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <FormControl margin="dense" variant="outlined" fullWidth>
                                            <InputLabel id="filter-vattype">Nastavenie DPH</InputLabel>
                                            <Select labelId="filter-vattype" label="Nastavenie DPH" value={source.vatType ?? 0} name="vatType" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                inputRef={input => props.autoFocus === 'vatType' && setFocus(input)}>
                                                <MenuItem key={0} value={0}>-</MenuItem>
                                                <MenuItem key={1} value={1}>Neplatca DPH</MenuItem>
                                                <MenuItem key={2} value={2}>Platca DPH</MenuItem>
                                                <MenuItem key={3} value={3}>Osoba registrovaná pre DPH</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {((source.regId ?? '').length > 5 || (source.company ?? '').length > 2) && (
                                        <Grid item xs={12}>
                                            {(source.regId ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleRegisterFind()} startIcon={<SearchIcon />}>Vyhľadať</Button>}
                                            {(source.company ?? '').length > 2 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenGoogle(source.company ?? '')} startIcon={<GoogleIcon />}>Google</Button>}
                                            {(source.regId ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenORSR(source.regId ?? '')} startIcon={<BadgeIcon />}>ORSR</Button>}
                                            {(source.regId ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenFinstat(source.regId ?? '')} startIcon={<BadgeIcon />}>Finstat</Button>}
                                        </Grid>
                                    )}

                                    {(customerRegister.regId ?? '').length > 5 && (
                                        <Grid item xs={12} mt={1}>
                                            <Alert severity="success" onClose={() => setCustomerRegister({})}>
                                                <strong>{customerRegister.company}</strong><br />
                                                {(customerRegister.street ?? '').length > 0 ? <>{(customerRegister.street ?? '') + ' ' + (customerRegister.houseNumber ?? '') + ', '}</> : null}
                                                {(customerRegister.city ?? '').length > 2 ? <>{customerRegister.city}</> : null}
                                                {(customerRegister.postcode ?? '').length > 0 ? <>{' ' + customerRegister.postcode}</> : null}
                                                <br />
                                                {(customerRegister.regId ?? '').length > 0 ? <>{'IČO: ' + customerRegister.regId}</> : null}
                                                {(customerRegister.taxId ?? '').length > 0 ? <>{', DIČ: ' + customerRegister.taxId}</> : null}
                                                {(customerRegister.vatId ?? '').length > 0 ? <>{', IČDPH: ' + customerRegister.vatId}</> : null}
                                                <br />
                                                <Button size="small" color="success" variant="contained" sx={{ mt: 1 }} onClick={() => {
                                                    setSource(prev => ({
                                                        ...prev,
                                                        company: customerRegister.company,
                                                        city: customerRegister.city,
                                                        street: customerRegister.street,
                                                        houseNumber: customerRegister.houseNumber,
                                                        postcode: customerRegister.postcode,
                                                        regId: customerRegister.regId,
                                                        taxId: customerRegister.taxId,
                                                        vatId: customerRegister.vatId,
                                                        countryId: customerRegister.countryId
                                                    }));
                                                    setCustomerRegister({});
                                                }}>Použiť</Button>
                                            </Alert>
                                        </Grid>
                                    )}
                                </>
                            )}

                            <Grid item xs={12} mt={(source.person ?? false) === true ? 0 : 3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>{(source.person ?? false) === true ? 'Osobné údaje' : 'Kontaktná osoba'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextField margin="dense" name="title" label="Titul" fullWidth variant="outlined" autoComplete="off" value={source.title ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'title') && setFocus(input)}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SchoolIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <TextField margin="dense" name="firstname" label="Meno" fullWidth variant="outlined" autoComplete="off" value={source.firstname ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => ((source.person ?? false) === true && (props.autoFocus === 'name' || props.autoFocus === 'customerfirstname' || props.autoFocus === undefined)) && setFocus(input)}
                                    inputProps={{ maxLength: 35 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <TextField margin="dense" name="lastname" label="Priezvisko" fullWidth variant="outlined" autoComplete="off" value={source.lastname ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'customerlastname') && setFocus(input)}
                                    inputProps={{ maxLength: 35 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField margin="dense" name="email" label="email" fullWidth variant="outlined" autoComplete="off" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'customeremail') && setFocus(input)}
                                    inputProps={{ maxLength: 120 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AlternateEmailIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="phone" label="Telefón" fullWidth variant="outlined" autoComplete="off" value={source.phone ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'customerphone') && setFocus(input)}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {((source.phone ?? '').length > 5 || (source.email ?? '').length > 0 || ((source.firstname ?? '').length > 0 && (source.lastname ?? '').length > 0)) && (
                                <Grid item xs={12}>
                                    {(source.phone ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenCall(source.phone ?? '')} startIcon={<PhoneIcon />}>Volať</Button>}
                                    {(source.email ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenemail(source.email ?? '')} startIcon={<EmailIcon />}>email</Button>}
                                    {(source.firstname ?? '').length > 0 && (source.lastname ?? '').length > 0 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenFacebook((source.firstname ?? '') + ' ' + (source.lastname ?? ''))} startIcon={<FacebookIcon />}>Facebook</Button>}
                                    {(source.firstname ?? '').length > 0 && (source.lastname ?? '').length > 0 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenLinkedIn((source.firstname ?? '') + ' ' + (source.lastname ?? ''))} startIcon={<LinkedInIcon />}>LinkedIn</Button>}
                                </Grid>
                            )}

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Fakturačná adresa</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField margin="dense" name="street" label="Ulica" fullWidth variant="outlined" autoComplete="off" value={source.street ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'customerstreet') && setFocus(input)}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="houseNumber" label="Číslo domu" fullWidth variant="outlined" autoComplete="off" value={source.houseNumber ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'customerhouseNumber') && setFocus(input)}
                                    inputProps={{ maxLength: 15 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-country">Krajina</InputLabel>
                                    <Select labelId="filter-country" label="Krajina" value={source.countryId ?? 0} name="countryId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'customercountryId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {countries.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={8} md={4}>
                                <TextField margin="dense" name="city" label="Mesto" fullWidth variant="outlined" autoComplete="off" value={source.city ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'customercity') && setFocus(input)}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4}>
                                <TextField margin="dense" name="postcode" label="PSČ" fullWidth variant="outlined" autoComplete="off" value={source.postcode ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'customerpostcode') && setFocus(input)}
                                    inputProps={{ maxLength: 15 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(source.street ?? '').length > 0 && (source.houseNumber ?? '').length > 0 && (source.city ?? '').length > 0 && <Grid item xs={12}>
                                <Button variant="outlined" size="small" sx={{ mt: 1 }} onClick={() => handleOpenMap((source.street ?? '') + ' ' + (source.houseNumber ?? '') + ' ' + (source.city ?? ''))} startIcon={<LocationOnIcon />}>Mapa</Button>
                            </Grid>}

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Dodacia adresa</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel control={<Switch checked={(source.deliveryAddressCustom ?? false)} name="deliveryAddressCustom" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Iná ako fakturačná" />
                            </Grid>

                            {(source.deliveryAddressCustom ?? false) === true && (
                                <>
                                    <Grid item xs={12} sm={8}>
                                        <TextField margin="dense" name="deliveryAddressName" label="Meno alebo názov" fullWidth variant="outlined" autoComplete="off" value={source.deliveryAddressName ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'customerdeliveryAddressName') && setFocus(input)}
                                            inputProps={{ maxLength: 75 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <BadgeIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <TextField margin="dense" name="deliveryAddressPhone" label="Telefón" fullWidth variant="outlined" autoComplete="off" value={source.deliveryAddressPhone ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputProps={{ maxLength: 25 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <PhoneIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextField margin="dense" name="deliveryAddressStreet" label="Ulica" fullWidth variant="outlined" autoComplete="off" value={source.deliveryAddressStreet ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'customerdeliveryAddressStreet') && setFocus(input)}
                                            inputProps={{ maxLength: 50 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LocationOnIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <TextField margin="dense" name="deliveryAddressHouseNumber" label="Číslo domu" fullWidth variant="outlined" autoComplete="off" value={source.deliveryAddressHouseNumber ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'customerdeliveryAddressHouseNumber') && setFocus(input)}
                                            inputProps={{ maxLength: 15 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LocationOnIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <FormControl margin="dense" variant="outlined" fullWidth>
                                            <InputLabel id="filter-country">Krajina</InputLabel>
                                            <Select labelId="filter-country" label="Krajina" value={source.deliveryAddressCountryId ?? 0} name="deliveryAddressCountryId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                inputRef={input => props.autoFocus === 'customerdeliveryAddressCountryId' && setFocus(input)}>
                                                <MenuItem key={0} value={0}>-</MenuItem>
                                                {countries.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={4}>
                                        <TextField margin="dense" name="deliveryAddressCity" label="Mesto" fullWidth variant="outlined" autoComplete="off" value={source.deliveryAddressCity ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'customerdeliveryAddressCity') && setFocus(input)}
                                            inputProps={{ maxLength: 50 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LocationOnIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField margin="dense" name="deliveryAddressPostcode" label="PSČ" fullWidth variant="outlined" autoComplete="off" value={source.deliveryAddressPostcode ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'customerdeliveryAddressPostcode') && setFocus(input)}
                                            inputProps={{ maxLength: 15 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LocationOnIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    {(source.deliveryAddressStreet ?? '').length > 0 && (source.deliveryAddressHouseNumber ?? '').length > 0 && (source.deliveryAddressCity ?? '').length > 0 && <Grid item xs={12}>
                                        <Button variant="outlined" size="small" sx={{ mt: 1 }} onClick={() => handleOpenMap((source.deliveryAddressStreet ?? '') + ' ' + (source.deliveryAddressHouseNumber ?? '') + ' ' + (source.deliveryAddressCity ?? ''))} startIcon={<LocationOnIcon />}>Mapa</Button>
                                    </Grid>}

                                    {(customer.id ?? 0) > 0 && (customer.addresses?.length ?? 0) > 0 && (
                                        <>
                                            <Grid item xs={12}>
                                                <Button variant="contained" size="small" startIcon={<ArrowDropDownIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} aria-label="Vložiť" aria-controls="menu-addresses" aria-haspopup="true" onClick={(e) => setAddressesMenuEl(e.currentTarget)}>Dodacie adresy ({customer.addresses?.length ?? 0})</Button>
                                            </Grid>
                                            <Menu id="menu-addresses" anchorEl={addressesMenuEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} sx={{ ml: '5px' }} transformOrigin={{ vertical: 'bottom', horizontal: 'left', }} open={Boolean(addressesMenuEl)} onClose={() => setAddressesMenuEl(null)} >
                                                {customer.addresses?.map((address, index) => (
                                                    <MenuItem dense key={index} onClick={() => {
                                                        setSource(prev => ({
                                                            ...prev,
                                                            deliveryAddressName: address.name,
                                                            deliveryAddressPhone: address.phone,
                                                            deliveryAddressCity: address.city,
                                                            deliveryAddressStreet: address.street,
                                                            deliveryAddressHouseNumber: address.houseNumber,
                                                            deliveryAddressPostcode: address.postcode,
                                                            deliveryAddressCountryId: address.countryId
                                                        }));
                                                        setAddressesMenuEl(null);
                                                    }}>
                                                        {address.name} <Typography variant="body2" color="text.secondary" ml={0.5}> - {address.street} {address.houseNumber} {address.city}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    )}
                                </>
                            )}

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Bankový účet</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="bankName" label="Názov bankového účtu" fullWidth variant="outlined" autoComplete="off" value={source.bankName ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AccountBalanceIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField margin="dense" name="bankIban" label="IBAN" fullWidth variant="outlined" autoComplete="off" value={source.bankIban ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 34 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CreditCardIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="bankSwift" label="BIČ / SWIFT" fullWidth variant="outlined" autoComplete="off" value={source.bankSwift ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CreditCardIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField margin="dense" name="bankNumber" label="Číslo účtu" fullWidth variant="outlined" autoComplete="off" value={source.bankNumber ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CreditCardIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="bankCode" label="Kód banky" fullWidth variant="outlined" autoComplete="off" value={source.bankCode ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 15 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CreditCardIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(customer.id ?? 0) > 0 && (customer.banks?.length ?? 0) > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Button variant="contained" size="small" startIcon={<ArrowDropDownIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} aria-label="Vložiť" aria-controls="menu-banks" aria-haspopup="true" onClick={(e) => setBanksMenuEl(e.currentTarget)}>Bankové účty ({customer.banks?.length ?? 0})</Button>
                                    </Grid>
                                    <Menu id="menu-banks" anchorEl={banksMenuEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} sx={{ ml: '5px' }} transformOrigin={{ vertical: 'bottom', horizontal: 'left', }} open={Boolean(banksMenuEl)} onClose={() => setBanksMenuEl(null)} >
                                        {customer.banks?.map((bank, index) => (
                                            <MenuItem dense key={index} onClick={() => {
                                                setSource(prev => ({
                                                    ...prev,
                                                    bankName: bank.name,
                                                    bankIban: bank.iban,
                                                    bankNumber: bank.number,
                                                    bankCode: bank.code,
                                                    bankSwift: bank.swift,
                                                }));
                                                setBanksMenuEl(null);
                                            }}>
                                                {bank.name} <Typography variant="body2" color="text.secondary" ml={0.5}> - {bank.iban}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>

                {/* Výber zákazníka (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <CustomersDialog {...customersDialog} />

            </Dialog>

        </>
    )
}

export default CustomerBaseEdit;