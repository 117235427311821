// Komponenty
import { Box, Paper, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface Props {
    children: any;
    fullHeight?: boolean;
}

export const Content = (props: Props) => {
    const history = useHistory();

    // Funkcia pre zmenu url z externého javascriptu
    // Táto funkcia musí byť vložená do komponentu, ktorý je vložený až pod komponentom "Switch", preto nemôže byť v komponente "Layout"
    // Komponent "Content" je použitý na každej podstránke, takže je použiteľný všade, ak by nebol, stačilo byť vloži pod "Switch" čistý komponent s touto funkciou
    // @ts-ignore
    window.externalHistoryPush = (url) => {
        history.push(url);
    };

    if (props.fullHeight === true) {
        return (
            <Box sx={{ display: 'block', position: 'relative', width: '100%', height: '100%' }}>
                {props.children}
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'grid', width: '100%', height: '100%',
            gridTemplateAreas: ' "top" "bottom" ',
            gridTemplateRows: {
                xs: '105px auto',
                md: '50px auto'
            }
        }}>
            {props.children}
        </Box>
    )
}

export const ContentTop = (props: Props) => {
    return (
        <Box sx={{ gridArea: 'top' }}>
            {props.children}
        </Box>
    )
}

export const ContentBottom = (props: Props) => {
    return (
        <Box sx={{ gridArea: 'bottom' }}>
            {props.children}
        </Box>
    )
}

export const ContentScroll = (props: Props) => {
    return (
        <Box sx={{ display: 'block', position: 'relative', width: '100%', height: '100%' }}>
            <Box sx={{ display: 'block', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowY: 'auto' }}>
                {props.children}
            </Box>
        </Box>
    )
}

export const ContentPaperScroll = (props: Props) => {
    // Téma
    const theme = useTheme();

    return (
        <Paper variant="outlined" sx={{ display: 'block', position: 'relative', width: '100%', height: '100%', backgroundColor: theme.layout.content.backgroundColor }}>
            <Box sx={{ display: 'block', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowY: 'auto' }}>
                <Box sx={{ padding: '20px' }}>
                    {props.children}
                </Box>
            </Box>
        </Paper>
    )
}