import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import { AppRouteUrl } from '../../AppRoutes';
import axios from 'axios';

// Modely
import { Overview } from '../../models/Models';

// Utility
import { FormatDecimal, FormatCurrency } from '../../utility/Format';

// Komponenty
import { Link } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import OverviewDocuments from './OverviewsDocuments';

// Graf
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, } from 'chart.js';

// Ikony
import ReplayIcon from '@mui/icons-material/Replay';

// Nakoniec
ChartJS.register(ArcElement, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController);

// Predpis pre vstupné parametre
interface OverviewProps {

}

const Overviews = (props: OverviewProps) => {

    // Počet sekúnd pre automatickú obnovu
    const timerTime = 30;

    // Stav
    const [loading, setLoading] = useState<boolean>(true);
    const [source, setSource] = useState<Overview>({});
    const [timer, setTimer] = useState<number>(timerTime);

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        setTimer(timerTime);
        axios
            .get(AppConfig.ApiUri + 'overview')
            .then(response => {
                setSource(response.data as Overview);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => { loadData(); }, []);

    // Pekné:   https://demos.creative-tim.com/otis-admin-pro/#/pages/charts
    // Docs:    https://react-chartjs-2.js.org/examples/line-chart

    const chartDognutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'left' as const, },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return context.label || context.parsed || '';
                    }
                }
            }
        },
        borderWidth: 1,
    };
    const chartBarOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' as const, }
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: { stacked: true, },
            y: { stacked: true, },
        },
        scale: {
            ticks: {
                precision: 0
            }
        }
    };
    const chartPriceBarOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' as const, },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return (context.dataset.label ?? '') + ': ' + FormatCurrency(context.raw ?? 0, source.currency ?? {}, 2) || '';
                    }
                }
            }
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: { stacked: true, },
            y: { stacked: true, },
        },
        scale: {
            ticks: {
                precision: 0
            }
        }
    };

    // Vráti dáta pre graf zo zoznamu podľa indexu
    const GetData = (index: number): any => {
        return ({
            labels: [...(source?.charts !== undefined ? source?.charts[index]?.labels ?? [] : [])],
            datasets: [...(source?.charts !== undefined ? source?.charts[index]?.datasets ?? [] : [])],
        });
    }

    // Časovač pre automatickú obnovu
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prev => (prev - 1));
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        if (timer < 0) {
            loadData();
        }
    }, [timer]);

    // Zobrazím vybrané údaje v "title", aby boli viditeľné v záložke
    // const location = useLocation();
    useEffect(() => {
        // const title = AppRoutes.find(route => route.path === location.pathname)?.title ?? '';
        // @ts-ignore
        document.title = 'Dnes: ' + (source.todayOrdersCount ?? 0) + ', nevybavené: ' + (source.totalOrdersCountNotDone ?? 0);
    }, [source]);

    return (
        <>
            <Content>
                <ContentTop>
                    <Grid container alignItems="center" columnSpacing={2}>
                        <Grid item xs={9} md>
                            <Button variant="contained" sx={{ mr: 1 }} size="large"
                                startIcon={
                                    loading === true ?
                                        <ReplayIcon sx={{ animation: "spin 2s linear infinite", "@keyframes spin": { "0%": { transform: "rotate(360deg)", }, "100%": { transform: "rotate(0deg)", }, }, }} /> :
                                        <ReplayIcon />
                                }
                                onClick={() => loadData()}>Obnoviť <Typography component="div" sx={{ width: '35px', textAlign: 'right', opacity: 0.4 }}>({timer})</Typography></Button>
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <Box sx={{ display: 'block', position: 'relative', width: '100%', height: '100%' }}>
                        <Box sx={{ display: 'block', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowY: 'auto' }}>
                            <Grid container columnSpacing={1} rowSpacing={1}>

                                <Grid item xs={12} xl={4} container columnSpacing={1} rowSpacing={1}>
                                    <Grid item xs={6} lg={3} xl={6}>
                                        <Card sx={{ position: 'relative' }}>
                                            <Button component={Link} to={AppRouteUrl.DOCUMENTS + (source.ordersTypeId ?? 0).toString() + '/'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></Button>
                                            <CardHeader sx={{ pb: 1 }} title="Objednávky" subheader="dnes"></CardHeader>
                                            <CardContent sx={{ pt: 0 }}>
                                                <Typography variant="h5" color="primary">{FormatDecimal(source.todayOrdersCount ?? 0, 0)}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6} lg={3} xl={6}>
                                        <Card sx={{ position: 'relative' }}>
                                            <Button component={Link} to={AppRouteUrl.DOCUMENTS + (source.ordersTypeId ?? 0).toString() + '/?filter=notdone'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></Button>
                                            <CardHeader sx={{ pb: 1 }} title="Nevybavené" subheader="celkom"></CardHeader>
                                            <CardContent sx={{ pt: 0 }}>
                                                <Typography variant="h5" color="primary" component="div">{FormatDecimal(source.totalOrdersCountNotDone ?? 0, 0)}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6} lg={3} xl={6}>
                                        <Card>
                                            <CardHeader sx={{ pb: 1 }} title="Obrat" subheader="dnes"></CardHeader>
                                            <CardContent sx={{ pt: 0 }}>
                                                <Typography variant="h5" color="error" component="div">{(source.todayOrdersPriceSale ?? 0) >= 0 ? '+' : '-'} {FormatCurrency(source.todayOrdersPriceSale ?? 0, source.currency ?? {}, 2)}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6} lg={3} xl={6}>
                                        <Card>
                                            <CardHeader sx={{ pb: 1 }} title="Obrat" subheader="tento mesiac"></CardHeader>
                                            <CardContent sx={{ pt: 0 }}>
                                                <Typography variant="h5" color="error" component="div">{(source.thisMonthOrdersPriceSale ?? 0) >= 0 ? '+' : '-'} {FormatCurrency(source.thisMonthOrdersPriceSale ?? 0, source.currency ?? {}, 2)}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} xl={4} md={6}>
                                    <Card>
                                        <CardHeader title="Posledné objednávky" subheader="posledných 10 objednávok"></CardHeader>
                                        <CardContent sx={{ pt: 0 }}>
                                            <Box sx={{ height: '168px' }}>
                                                <OverviewDocuments type={source.ordersTypeId ?? 0} documents={source.orders ?? []} currency={source.currency ?? {}} />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} xl={4} md={6}>
                                    <Card>
                                        <CardHeader title="Stav objednávok" subheader="posledných 30 dní"></CardHeader>
                                        <CardContent sx={{ pt: 0 }}>
                                            <Box sx={{ height: '168px' }}><Doughnut data={GetData(0)} options={chartDognutOptions} /></Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <Card>
                                        <CardHeader title="Denné objednávky" subheader="posledných 30 dní"></CardHeader>
                                        <CardContent sx={{ pt: 0 }}>
                                            <Box sx={{ height: '350px' }}>
                                                <Bar data={GetData(1)} options={chartBarOptions} />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <Card>
                                        <CardHeader title="Denný obrat" subheader="posledných 30 dní"></CardHeader>
                                        <CardContent sx={{ pt: 0 }}>
                                            <Box sx={{ height: '350px' }}>
                                                <Bar data={GetData(2)} options={chartPriceBarOptions} />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>

                        </Box>
                    </Box>
                </ContentBottom>
            </Content>
        </>
    )
}

export default Overviews;