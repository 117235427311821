import React, { FormEvent, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Category, ExternalCatalog } from '../../models/Models';

// Komponenty
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, FormControl, Alert, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import CategorySelect from './CategorySelect';

// Vstupné parametre
export interface CategorySettingsProps {
    open: boolean;
    keepMounted?: boolean;
    categoryId?: number;
    categories: Category[];   // Zoznam kategórií
    onCategoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
    onSelect: (id: number, type: number, typeArgument: number) => void;
    onClose: () => void;
}

// Položka pre argument
export interface ArgumentItem {
    id?: number;
    name?: string;
}

const CategorySettings = (props: CategorySettingsProps) => {

    // Stav
    const [categoryId, setCategoryId] = useState<number>(0);
    const [externalCatalogs, setExternalCatalogs] = useState<ExternalCatalog[]>([]);
    const [type, setType] = useState<number>(0);
    const [typeArgument, setTypeArgument] = useState<number>(0);
    const [typeArgumentList, setTypeArgumentList] = useState<ArgumentItem[]>([]);

    // Pri otvorení predvolím vybranú kategóriu
    useEffect(() => {
        if (props.open) {
            setCategoryId(props.categoryId ?? 0);
        }
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam zoznam externých katalógov
    const loadExternalCatalogs = () => {
        if (!props.open || externalCatalogs.length > 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'externalcatalog')
            .then(response => {
                setExternalCatalogs(response.data);
            });
    };
    useEffect(() => loadExternalCatalogs(), [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automaticky vyplním zoznam možností pre vybrané nastavenie
    useEffect(() => {
        // (De)Aktivácia externého katalógu produktov (vyplním zoznam katalógov pre výber)
        if(type === 4 || type === 5) {
            setTypeArgumentList(externalCatalogs.map(item => ({ id: item.id, name: item.name })));
            setTypeArgument(externalCatalogs[0]?.id ?? 0);
            return;
        }
        setTypeArgumentList([]);
    }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSelect(categoryId, type, typeArgument);
        props.onClose();
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Možnosti
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <CategorySelect label='Kategória' activeCategoryId={categoryId ?? 0} categories={props.categories} onSelect={(id: number) => setCategoryId(id)} onCategoriesChanged={props.onCategoriesChanged} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="create-settings-type">Nastavenie</InputLabel>
                                    <Select required labelId="create-settings-type" label="Nastavenie" value={type} name="type" onChange={(e) => { setType(parseInt(e.target.value.toString())) }}>
                                        <MenuItem value={0}>Deaktivovať kategórie</MenuItem>
                                        <MenuItem value={1}>Aktivovať kategórie</MenuItem>
                                        <MenuItem value={2}>Nastaviť náhľady z produktov (iba chýbajúce)</MenuItem>
                                        <MenuItem value={3}>Nastaviť náhľady z produktov (všetky)</MenuItem>
                                        <MenuItem value={4}>Deaktivovať externý katalóg pre produkty v kategórii</MenuItem>
                                        <MenuItem value={5}>Aktivovať externý katalóg pre produkty v kategórii</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {typeArgumentList.length > 0 && (
                                <Grid item xs={12}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id="create-settings-type-argument">Možnosti nastavenia</InputLabel>
                                        <Select required labelId="create-settings-type-argument" label="Možnosti nastavenia" value={typeArgument} name="typeArgument" onChange={(e) => { setTypeArgument(parseInt(e.target.value.toString())) }}>
                                            {typeArgumentList.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Alert severity="info" sx={{ mt: 1 }}>
                                    Upozornenie! Nastavenie bude aplikované na celú vetvu vybranej kategórie a vykonanie nastavenia môže trvať niekoľko sekúnd až minút.
                                </Alert>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setCategoryId(0); setType(0); }} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default CategorySettings;