import React, { FormEvent, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { FileDirectory } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Predpis pre otvárací zoznam
interface SelectItem {
    id: number;
    name: string;
}

// Vstupné parametre
export interface FileMoveProps {
    open: boolean;
    ids?: number[];
    autoFocus?: string;
    keepMounted?: boolean;
    directories: FileDirectory[];   // Zoznam zložiek
    onSave?: () => void;
    onClose: () => void;
}

const FileMove = (props: FileMoveProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [directoryId, setDirectoryId] = useState<number>(0);
    const [directoriesSource, setDirectoriesSource] = useState<SelectItem[]>([]);

    // Pri otvorení predvolím všetko
    useEffect(() => {
        if (props.open && directoryId > 0) {
            setDirectoryId(0);
        }
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [directoryId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'file/move', null, {
                params: {
                    'ids': props.ids,
                    'directoryId': directoryId
                }
            })
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Pregenerovanie zdroja pre zložky po zmene dát
    useEffect(() => {
        // Zoznam zložiek
        const items: SelectItem[] = [];

        // Funkcia pre rekurzívne vygenerovanie položiek (pomocou level obmedzím rekurzívne vykreslenie kvôli zacykleniu ak bude chybný parent)
        const generate = (parentId: number, parentName: string, level?: number) => {
            if((level ?? 0) > 30) {
                return [];
            }
            props.directories.filter(d => d.parentId === parentId)?.forEach(d => {
                const name = parentName + (parentName.length > 0 ? ' / ' : '') + (d.name ?? '');
                items.push({ id: d?.id ?? 0, name: name });
                generate(d.id ?? 0, name, (level ?? 0) + 1);
            });
        };
        generate(0, '');

        // Uloženie stavu
        setDirectoriesSource(items);
    }, [props.directories]);

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Presun súborov ({props.ids?.length})
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="file-create-directory">Priečinok</InputLabel>
                                    <Select required labelId="file-create-directory" label="Priečinok" value={directoryId ?? 0} onChange={(e) => { setDirectoryId(parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'directoryId' && setFocus(input)}>
                                        <MenuItem value={0}>Všetko</MenuItem>
                                        {directoriesSource.map(directory => (<MenuItem key={directory.id} value={directory.id}>{directory.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setDirectoryId(0)} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default FileMove;