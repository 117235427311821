import { Fragment, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

//Ikony
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Elementy
import { Collapse, Divider, List, ListItemButton, ListSubheader, Tooltip, useTheme } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Predpis pre položku menu
export interface Item {
    path: string;               // URL stránky
    title: string;              // Titulok stránky
    icon: JSX.Element;          // Ikona
    items?: Item[];             // Rekurzívne zobrazenie položiek navigácie
    divider?: boolean;          // Zobrazí oddelovač za položkou
    subHeader?: string;         // Podnadpis pred položkou
    argument?: any;             // Pomocná premenná
}

// Pre uloženie stavu otváracieho menu podľa indexu položky
interface ItemOpen {
    index: number;
    open: boolean;
}

// Vstupné parametre pre položku listu
interface NavigationItemProps {
    item: Item;                 // položka navigácie
    active?: boolean;           // aktívna položka (pre zvýraznenie)
    children?: boolean;         // informácia o tom, že ide o vnorený list
    onClickToggle?: () => void; // po kliknutí na tlačidlo, môžem požadovať callback napr. pre skrytie sidebaru
}

const NavigationItem = (props: NavigationItemProps) => {
    return (
        <ListItemButton component={Link} to={props.item.path} onClick={() => {
            if (props?.onClickToggle) {
                props?.onClickToggle()
            }
        }}>
            <ListItemIcon>
                <Tooltip title={props.item.title} placement="right"
                    sx={{
                        fontSize: (props.children ? '1.1rem' : 'noset'),
                        marginLeft: (props.children ? '0.2rem' : 'noset')
                    }} disableInteractive>
                    {props.item.icon}
                </Tooltip>
            </ListItemIcon>
            <ListItemText primary={props.item.title}
                sx={{ marginLeft: '-13px', maxHeight: '25px' }}
                primaryTypographyProps={{
                    noWrap: true, fontWeight: (props.active ?? false ? 'bold' : 'noset')
                }} />
        </ListItemButton>
    )
}

// Vstupné parametre pre list
interface NavigationProps {
    items: Item[]; // položky navigácie
    children?: boolean; // informácia o tom, že ide o vnorený list
    onClickToggle?: () => void; // po kliknutí na tlačidlo, môžem požadovať callback napr. pre skrytie sidebaru
}

const Navigation = (props: NavigationProps) => {

    const theme = useTheme();

    // Získam aktulálnu URL
    const location = useLocation();

    // Overenie či položka alebo jej podpoložky obsahujú aktuálne URL
    const isActiveItem = (item: Item): boolean => {
        if (item.path === location.pathname)
            return true;
        var result = false;
        if (item.items?.length) {
            for (var i = 0, max = item.items.length; i < max; i++) {
                if (isActiveItem(item.items[i])) {
                    result = true;
                    break;
                }
            }
        }
        return result;
    };

    // Prvotný stav elementov podľa indexu na základe aktuálnej URL (aktualizuje sa vždy po zmene položiek)
    var openByUrl: ItemOpen[] = [];
    useEffect(() => {
        props.items.forEach((item, index) => {
            if (item.items?.length && isActiveItem(item)) {
                openByUrl.push({ index: index, open: true });
            }
        });
        setOpen(openByUrl);
    }, [props.items]);  // eslint-disable-line react-hooks/exhaustive-deps

    // Stav elementov podľa indexu
    const [open, setOpen] = useState<ItemOpen[]>([]);

    // Zmena stavu skupiny
    const handleOpen = (index: number) => {
        setOpen(prev => {
            const isOpen = prev.find(item => item.index === index)?.open ?? false;
            return [...prev.filter(item => item.index !== index), { index: index, open: !isOpen }]
        });
    };

    return (
        <List dense={props.children ? true : false}>
            {props.items.map((item, index) => (
                (!item.items ?
                    <Fragment key={index}>
                        {item.subHeader !== undefined && item.subHeader.length > 0 ? <ListSubheader component="div" disableSticky sx={{ maxHeight: '20px', lineHeight: '20px', background: 'none', fontSize: 'small' }}>{item.subHeader}</ListSubheader> : null}
                        <NavigationItem item={item} active={item.path === location.pathname} children={props.children} onClickToggle={props.onClickToggle} />
                        {item.divider === true ? <Divider /> : null}
                    </Fragment>
                    :
                    <Fragment key={index}>
                        {item.subHeader !== undefined && item.subHeader.length > 0 ? <ListSubheader component="div" disableSticky sx={{ maxHeight: '20px', lineHeight: '20px', background: 'none', fontSize: 'small' }}>{item.subHeader}</ListSubheader> : null}
                        <ListItemButton onClick={(e) => { handleOpen(index); e.preventDefault(); }}>
                            <ListItemIcon title={item.title}>
                                <Tooltip title={item.title} placement="right"
                                    sx={{
                                        fontSize: (props.children ? '1.1rem' : 'noset'),
                                        marginLeft: (props.children ? '0.2rem' : 'noset')
                                    }} disableInteractive>
                                    {item.icon}
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={item.title} sx={{ marginLeft: '-13px', maxHeight: '25px' }} primaryTypographyProps={{ noWrap: true }} />
                            {(open.find(item => item.index === index)?.open ?? false) ? <ExpandLess sx={{ fontSize: 'medium', color: 'silver' }} /> : <ExpandMore sx={{ fontSize: 'medium', color: 'silver' }} />}
                        </ListItemButton>
                        <Collapse className="navigation-collapse-child" in={(open.find(item => item.index === index)?.open ?? false)} sx={{ position: 'relative', backgroundColor: theme.layout.sidebar.collapse.backgroundColor }} timeout="auto" unmountOnExit>
                            <Navigation items={item.items} children={true} onClickToggle={props.onClickToggle} />
                        </Collapse>
                        {item.divider === true ? <Divider /> : null}
                    </Fragment>
                )
            ))}
        </List>
    )
}

export default Navigation;