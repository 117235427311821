
export enum PasswordStrengthType {
    Strong,
    Medium,
    Weak
}

export const PasswordStrength = (password: string) : PasswordStrengthType => {
    // Minimálne 8 znakov a z toho minimálne jedno veľké písmeno, jedno malé písmeno, jednu číslicu a jeden špeciálny znak
    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

    // Minimálne 8 znakov a z toho minimálne jedno veľké písmeno, jedno malé písmeno, jednu číslicu
    let mediumPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})');

    if(strongPassword.test(password)) {
        return PasswordStrengthType.Strong;
    } 
    else if(mediumPassword.test(password)) {
        return PasswordStrengthType.Medium;
    }
    else {
        return PasswordStrengthType.Weak;
    }
};