import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Export, ExportQueue, ExportTypeParameter, ExportTypes, ImportExportIntervals } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, Box, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExportCreateFilterProducts from './ExportCreateFilterProducts';
import ExportCreateFilterDocuments from './ExportCreateFilterDocuments';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Export => ({
    id: 0,
    actived: true,
    client: '',
    priority: 1,
    name: '',
    interval: 8,
    intervalHour: 4,
    intervalOnce: false,
    type: 1,
    format: 0,
    filter: {},
    parameters: []
});

// Vstupné parametre
export interface ExportCreateProps {
    open: boolean;
    id?: number;
    queue?: boolean; // Vytvorenie jednorázového záznamu priamo do fronty
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const ExportCreate = (props: ExportCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Export>(EmptySource());
    const [parameters, setParameters] = useState<ExportTypeParameter[]>([]);
    const [typeClear, setTypeClear] = useState<boolean>(false);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Automaticky vyplním dostupné parametre pre daný typ a pôvodný zoznam vyčistím, rovnako aj filter
    useEffect(() => {
        setParameters(ExportTypes.find(item => item.id === source.type)?.parameters ?? []);
        // Kontrolujem až po načítaní existujúceho záznamu
        if (typeClear) {
            // Ak som zmenil typ, tak vyčistím parametre a filter
            setSource(prev => ({ ...prev, parameters: [], filter: {} }));
        }
    }, [source.type]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            setTypeClear(false);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            setTypeClear(true);
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'export/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Export);
                    setTypeClear(true);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        if ((props.queue ?? false) === false) {
            // Automatický import
            axios
                .post(AppConfig.ApiUri + 'export', source)
                .then(response => {
                    if (response.data > 0) {
                        if (props.onSave) {
                            props.onSave();
                        }
                        props.onClose();
                    }
                })
                .catch(() => {
                    setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        else {
            // Jednorázový import, priamy zápis do fronty
            var queue = {
                client: source.client,
                priority: 3,
                state: 0,
                note: source.name,
                type: source.type,
                format: source.format,
                filter: source.filter,
                parameters: source.parameters
            } as ExportQueue;

            axios
                .post(AppConfig.ApiUri + 'exportqueue', queue)
                .then(response => {
                    if (response.data > 0) {
                        if (props.onSave) {
                            props.onSave();
                        }
                        props.onClose();
                    }
                })
                .catch(() => {
                    setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Export' : ((props.queue ?? false) === true ? 'Jednorázový export' : 'Nový export'))}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            {(props.queue ?? false) === false && <>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel control={<Switch checked={source.intervalOnce} name="intervalOnce" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Jednorázový" />
                                </Grid>
                            </>}

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="export-create-type">Typ</InputLabel>
                                    <Select required labelId="export-create-type" label="Typ" value={source.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        {ExportTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth disabled={(props.queue ?? false)}>
                                    <InputLabel id="export-create-interval">Interval</InputLabel>
                                    <Select labelId="export-create-interval" label="Interval" value={source.interval ?? 0} name="interval" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'interval' && setFocus(input)}>
                                        {ImportExportIntervals.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth disabled={(props.queue ?? false) || (source.interval ?? 0) === 9}>
                                    <InputLabel id="export-create-interval-hour">Čas exportu</InputLabel>
                                    <Select labelId="export-create-interval-hour" label="Čas exportu" value={source.intervalHour ?? 0} name="intervalHour" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'intervalHour' && setFocus(input)}>
                                        <MenuItem value={0}>00:00</MenuItem>
                                        <MenuItem value={1}>01:00</MenuItem>
                                        <MenuItem value={2}>02:00</MenuItem>
                                        <MenuItem value={3}>03:00</MenuItem>
                                        <MenuItem value={4}>04:00</MenuItem>
                                        <MenuItem value={5}>05:00</MenuItem>
                                        <MenuItem value={6}>06:00</MenuItem>
                                        <MenuItem value={7}>07:00</MenuItem>
                                        <MenuItem value={8}>08:00</MenuItem>
                                        <MenuItem value={9}>09:00</MenuItem>
                                        <MenuItem value={10}>10:00</MenuItem>
                                        <MenuItem value={11}>11:00</MenuItem>
                                        <MenuItem value={12}>12:00</MenuItem>
                                        <MenuItem value={13}>13:00</MenuItem>
                                        <MenuItem value={14}>14:00</MenuItem>
                                        <MenuItem value={15}>15:00</MenuItem>
                                        <MenuItem value={16}>16:00</MenuItem>
                                        <MenuItem value={17}>17:00</MenuItem>
                                        <MenuItem value={18}>18:00</MenuItem>
                                        <MenuItem value={19}>19:00</MenuItem>
                                        <MenuItem value={20}>20:00</MenuItem>
                                        <MenuItem value={21}>21:00</MenuItem>
                                        <MenuItem value={22}>22:00</MenuItem>
                                        <MenuItem value={23}>23:00</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="export-create-format">Formát</InputLabel>
                                    <Select labelId="export-create-format" label="Formát" value={source.format ?? 0} name="format" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'format' && setFocus(input)}>
                                        <MenuItem value={0}>XML</MenuItem>
                                        <MenuItem value={1}>JSON</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth disabled={(props.queue ?? false)}>
                                    <InputLabel id="export-create-priority">Priorita</InputLabel>
                                    <Select labelId="export-create-priority" label="Priorita" value={source.priority ?? 0} name="priority" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'priority' && setFocus(input)}>
                                        <MenuItem value={1}>Nízka</MenuItem>
                                        <MenuItem value={2}>Vysoká</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="export-create-parameters">Výstupné parametre (nepovinné)</InputLabel>
                                    <Select multiple labelId="export-create-parameters" label="Výstupné parametre (nepovinné)" name="parameters" value={source.parameters ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'parameters' && setFocus(input)}>
                                        {parameters.map(item => (<MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {(source.parameters ?? []).length === 0 && (
                                <Grid item xs={12}>
                                    <Alert severity="info" sx={{ mt: 0.5 }}>
                                        Ak nie sú zvolené konkrétne výstupné parametre, tak budú zahrnuté všetky.
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12} my={1}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('filter')} onChange={() => handleToggleAccordion('filter')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Filter - obmedzenie exportu</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(source.type ?? 0) === 1 && <ExportCreateFilterProducts filter={source.filter ?? {}} onChange={(filter) => setSource(prev => ({ ...prev, filter: filter }))} />}
                                        {(source.type ?? 0) === 2 && <ExportCreateFilterDocuments filter={source.filter ?? {}} onChange={(filter) => setSource(prev => ({ ...prev, filter: filter }))} />}
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            {(source.priority ?? 1) === 2 && (
                                <Grid item xs={12}>
                                    <Alert severity="info" sx={{ mt: 0.5 }}>
                                        Exporty s vysokou prioritou sú spracované skôr ako tie s nízkou prioritou, ak začiatok exportu vychádza na rovnaký čas.
                                    </Alert>
                                </Grid>
                            )}

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ExportCreate;