import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../AppRoutes';
import { AppBar, Badge, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'
import { AppConfig } from '../AppConfig';
import { AppContext } from '../AppContext';
import axios from 'axios';

// Modely
import { InfoToolbar, Domain } from '../models/Models';

// Utility
import { ResponsivePoint as HidePoint } from '../utility/ResponsivePoint';

// Komponenty
import Logout from '../views/system/Logout';
import PasswordChange from '../views/system/PasswordChange';
import NotesDialog, { NotesDialogProps } from '../views/note/NotesDialog';
import NoteNotifications from '../views/note/NoteNotifications';

// Ikony
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

interface Props {
    onToggleSidebar?: () => void;
    onToggleDarkMode?: () => void;
}

const Header = (props: Props) => {
    const theme = useTheme();
    const history = useHistory();

    // Globálne premenné
    const appContext = useContext(AppContext);
    
    // Overenie zobrazenia na mobile
    const isMobile = HidePoint().sm;

    // Lokálny stav
    const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);

    // Uchovám element na ktorý má byť ukotvené menu. Pokiaľ je NULL, tak sa menu nezobrazí
    const [anchorAccountEl, setAnchorAccountEl] = useState<null | HTMLElement>(null);
    const [anchorDomainsEl, setAnchorDomainsEl] = useState<null | HTMLElement>(null);

    // Vynútené obnovenie komponentov
    const [forceRender, setForceRender] = useState(0);
    const handleForceRender = () => setForceRender(p => (p + 1));

    // Poznámky
    const [notesDialog, setNotesDialog] = useState<NotesDialogProps>({
        open: false,
        onClose: () => handleNotesClose(),
        onChange: handleForceRender
    });
    const handleNotesOpen = () => {
        setNotesDialog(prev => ({ ...prev, open: true }));
    };
    const handleNotesClose = () => {
        setNotesDialog(prev => ({ ...prev, open: false }));
    };

    // Zoznam domén
    const [domains, setDomains] = useState<Domain[]>([]);
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data);
            });
    };
    useEffect(() => loadDomains(), []);

    // Funkcia pre načítanie dát z API
    const loadInfoToolbar = () => {
        axios
            .get(AppConfig.ApiUri + 'info/toolbar')
            .then(response => {
                setInfo(response.data);
            });
    };

    // Načítam info pre toolbar ihneď po zobrazení kontrolu
    useEffect(() => {
        loadInfoToolbar();
        // Vykonám kontrolu každú minútu
        const interval = setInterval(() => {
            loadInfoToolbar();
        }, (1 * 60 * 1000));
        return () => clearInterval(interval);
    }, [forceRender]);

    // Informácie v panely
    const [info, setInfo] = useState<InfoToolbar>({
        notesCount: 0,
        ordersCount: 0,
        ordersTypeId: 0
    });

    return (
        <>
            <NotesDialog {...notesDialog} />
            <NoteNotifications forceRender={forceRender} />

            {(passwordChangeOpen && <PasswordChange onClose={() => { setPasswordChangeOpen(!passwordChangeOpen) }} />)}
            <AppBar position="absolute" sx={{ backgroundColor: '#212120' }}>
                <Toolbar>
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => { if (props?.onToggleSidebar) { props?.onToggleSidebar() } }} sx={{ mr: 2 }} >
                        <MenuIcon />
                    </IconButton>
                    <Typography id="header-title" noWrap variant="h6" component="div" sx={{ flexGrow: 2, maxHeight: '30px', overflow: 'hidden' }}>
                        Prehľad / {appContext.userName}
                    </Typography>
                    <div style={{ whiteSpace: 'nowrap' }}>

                        {/******* Zoznam domén *******/}
                        <IconButton size={isMobile ? 'small' : 'large'} aria-label="Otvoriť doménu" aria-controls="menu-domains" aria-haspopup="true" onClick={(e) => setAnchorDomainsEl(e.currentTarget)} color="inherit">
                            <Tooltip title="Zoznam domén">
                                <LanguageIcon />
                            </Tooltip>
                        </IconButton>
                        <Menu id="menu-domains" anchorEl={anchorDomainsEl} keepMounted anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} open={Boolean(anchorDomainsEl)} onClose={() => setAnchorDomainsEl(null)} >
                            {domains.map(domain => {
                                return <MenuItem key={domain.id} onClick={() => window.open(domain.url, "_blank")}>
                                    <ListItemIcon><LinkIcon fontSize="small" /></ListItemIcon> {domain.name}
                                </MenuItem>
                            })}
                        </Menu>

                        {/******* Poznámky *******/}
                        <IconButton size={isMobile ? 'small' : 'large'} aria-label="Pripnuté poznámky" color="inherit" onClick={handleNotesOpen}>
                            <Tooltip title="Poznámky">
                                <Badge badgeContent={info.notesCount} color="warning">
                                    <NoteAltIcon />
                                </Badge>
                            </Tooltip>
                        </IconButton>

                        {/******* Nevybavené objednávky (zobrazí sa len ak je druh dokladu "prijaté objednávky" aktívny) *******/}
                        {(info.ordersTypeId ?? 0) > 0 && (
                            <IconButton size={isMobile ? 'small' : 'large'} aria-label="Objednávky (nevybavené)" color="inherit" onClick={() => { history.push(AppRouteUrl.DOCUMENTS + (info.ordersTypeId ?? 0).toString() + '/'); }}>
                                <Tooltip title="Objednávky (nevybavené)">
                                    <Badge badgeContent={info.ordersCount} color="error">
                                        <ShoppingCartIcon />
                                    </Badge>
                                </Tooltip>
                            </IconButton>
                        )}

                        {/******* Užívateľský účet *******/}
                        <IconButton size={isMobile ? 'small' : 'large'} aria-label={appContext.userName} aria-controls="menu-account" aria-haspopup="true" onClick={(e) => setAnchorAccountEl(e.currentTarget)} color="inherit">
                            <Tooltip title={appContext.userName}>
                                <AccountCircle />
                            </Tooltip>
                        </IconButton>
                        <Menu id="menu-account" anchorEl={anchorAccountEl} keepMounted anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} open={Boolean(anchorAccountEl)} onClose={() => setAnchorAccountEl(null)} >
                            <MenuItem onClick={() => { setPasswordChangeOpen(true) }}>
                                <ListItemIcon><LockIcon fontSize="small" /></ListItemIcon> Zmeniť heslo
                            </MenuItem>
                            <MenuItem onClick={() => {
                                if (props?.onToggleDarkMode) {
                                    props?.onToggleDarkMode()
                                }
                            }}>
                                {theme.palette.mode === 'light' ?
                                    <><ListItemIcon><DarkModeIcon fontSize="small" /></ListItemIcon> Zapnúť tmavý režim</> :
                                    <><ListItemIcon><LightModeIcon fontSize="small" /></ListItemIcon> Vypnúť tmavý režim</>
                                }
                            </MenuItem>
                            <Logout />
                        </Menu>

                    </div>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header