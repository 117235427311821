import React, { useState } from 'react'

// Modely
import { CustomerBase, Country } from '../../models/Models';

// Komponenty
import CustomerBaseEdit, { CustomerBaseEditProps } from './CustomerBaseEdit';
import CustomerBaseInfo from './CustomerBaseInfo';

export interface CustomerBaseComponentProps {
    customer: CustomerBase;
    countries?: Country[];
    keepMounted?: boolean;
    onCustomerChanged?: (customer: CustomerBase) => void;
}

// Slúži na zlúčenie kopomonentu pre zobrazenie základných vlastností zákazníka a komponentu pre ich editáciu
const CustomerBaseComponent = (props: CustomerBaseComponentProps) => {

    // Editácia
    const [orderCreateCustomer, setOrderCreateCustomer] = useState<CustomerBaseEditProps>({
        customer: props.customer ?? {},
        countries: [],
        open: false,
        keepMounted: props.keepMounted,
        onSave: (changed) => {
            if (props.onCustomerChanged) {
                props.onCustomerChanged(changed);
            }
        },
        onClose: () => setOrderCreateCustomer(prev => ({ ...prev, open: false }))
    });
    const handleCustomerEdit = (openCustomers?: boolean) => {
        setOrderCreateCustomer(prev => ({
            ...prev,
            open: true,
            openCustomers: openCustomers ?? false
        }));
    };

    return (
        <>
            {/* Výpis údajov */}
            <CustomerBaseInfo customer={props.customer ?? {}} onCustomerEdit={(props.onCustomerChanged !== undefined ? handleCustomerEdit : undefined)} countries={props.countries} />

            {/* Úprava údajov (ak je definovaná udalosť po zmene) */}
            {props.onCustomerChanged !== undefined && <CustomerBaseEdit {...orderCreateCustomer} customer={props.customer} countries={props.countries} />}
        </>
    )
}

export default CustomerBaseComponent;