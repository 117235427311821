import React, { FormEvent, useEffect, useState } from 'react'

// Modely
import { Category } from '../../models/Models';

// Komponenty
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';
import CategorySelect from '../category/CategorySelect';

// Vstupné parametre
export interface ProductsMoveProps {
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    categoryId?: number;
    categories: Category[];   // Zoznam kategórií
    onCategoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
    onSelect: (id: number) => void;
    onClose: () => void;
}

const ProductsMove = (props: ProductsMoveProps) => {

    // Stav
    const [categoryId, setCategoryId] = useState<number>(0);

    // Pri otvorení predvolím vybranú kategóriu, inak zachovám naposledy označnú
    useEffect(() => {
        if (props.open && (props.categoryId ?? 0) > 0) {
            setCategoryId(props.categoryId ?? 0);
        }
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSelect(categoryId);
        props.onClose();
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Presun produktov
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <CategorySelect label='Kategória' activeCategoryId={categoryId ?? 0} categories={props.categories} onSelect={(id: number) => setCategoryId(id)} onCategoriesChanged={props.onCategoriesChanged} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCategoryId(0)} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ProductsMove;