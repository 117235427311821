import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { Currency, DocumentLite } from '../../models/Models';

// Utility
import { FormatCurrency } from '../../utility/Format';
import format from 'date-fns/format';

// Komponenty
import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

// Ikony
import LaunchIcon from '@mui/icons-material/Launch';

// Vstupné parametre
export interface OverviewDocumentsProps {
    type: number;
    documents: DocumentLite[];
    currency: Currency;
}

// Komponent pre zoznam
const OverviewDocuments = (props: OverviewDocumentsProps) => {

    return (
        <TableContainer style={{ maxHeight: '100%' }}>
            <Table size="small">
                <TableBody>
                    {props.documents.map((document) => (
                        <TableRow key={document.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ pl: 0, width: '70px' }}>{(new Date(document.dateOfIssue).getFullYear() > 1 ? format(new Date(document.dateOfIssue), 'dd.MM.yyyy') : '-')}</TableCell>
                            <TableCell>{(document.person ?? false) === true ? (document.firstname ?? '') + ' ' + (document.lastname ?? '') : (document.company ?? '')}</TableCell>
                            <TableCell align="right">
                                <Typography variant="inherit" color="error">
                                    {FormatCurrency(document.priceTotalWithVat ?? 0, props.currency, 2)}
                                </Typography>
                            </TableCell>
                            <TableCell align="right" sx={{ pl: 0, ml: 0, width: '20px' }}>
                                <IconButton aria-label="Otvoriť" size="small" href={AppRouteUrl.DOCUMENTS + (props.type ?? 0).toString() + '/?id=' + (document.id ?? 0).toString()} target="_blank">
                                    <LaunchIcon fontSize="inherit" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OverviewDocuments;