import { useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { ImportQueue } from '../../../models/Models';

// Komponenty
import { Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import Grid from '@mui/material/Grid';

// Vstupné parametre
export interface ImportQueueInfoProps {
    open: boolean;
    id?: number;
    keepMounted?: boolean;
    onClose: () => void;
}

const ImportQueueInfo = (props: ImportQueueInfoProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [source, setSource] = useState<ImportQueue>({});

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open || (props.id ?? 0) === 0) {
            setSource({});
            return;
        }
        // Vyresetujem
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'importqueue/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    setSource(response.data as ImportQueue);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Pregenerovanie protokolu
    const generateProtocol = () => {
        if ((source.id ?? 0) > 0 && (source.protocol ?? '').length === 0) {
            return <Grid item xs={12}>
                <Alert severity="warning">Záznam neobsahuje žiadny protokol</Alert>
            </Grid>
        }
        return (source.protocol ?? '').split(/\r?\n/).map((item, index) =>
            <Grid item xs={12} key={index}>
                <Alert severity="info">{item}</Alert>
            </Grid>
        );
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form">
                    <DialogTitle>Informácie</DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container rowSpacing={1} columnSpacing={1} sx={{ mt: 1 }} alignItems="center">
                            {generateProtocol()}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={props.onClose} variant="contained">Zavrieť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ImportQueueInfo;