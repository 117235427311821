import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { DocumentState } from '../../models/Models';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, Grid, Paper } from '@mui/material';
import ButtonList, { Item } from '../../components/ButtonList';

// Ikony
import FlagIcon from '@mui/icons-material/Flag';

export interface DocumentsStateProps {
    open: boolean;
    documentStates?: DocumentState[];
    keepMounted?: boolean;
    onSelect: (id: number) => void;
    onClose: () => void;
}

const DocumentsState = (props: DocumentsStateProps) => {

    const [documentStates, setDocumentStates] = useState<DocumentState[]>([]);
    const [items, setItems] = useState<Item[]>([]);

    // Načítam zoznam stavov objednávky
    const loadDocumentStates = () => {
        axios
            .get(AppConfig.ApiUri + 'documentstate')
            .then(response => {
                setDocumentStates(response.data.filter((documentstate: DocumentState) => documentstate.actived));
            });
    };

    // Načítam zoznam stavov, ak nefigurujú na vstupe
    useEffect(() => {
        if (props.documentStates !== undefined) {
            setDocumentStates(props.documentStates);
        } else {
            loadDocumentStates();
        }
    }, [props.documentStates]);

    // Vygenerujem zoznam tlačidiel pre výber stavu
    useEffect(() => {
        const items: Item[] = [];
        documentStates.forEach((state, index) => {
            items.push({
                key: state.id,
                title: state.other?.translationName ?? '-',
                icon: <FlagIcon htmlColor={'#' + state.color} />,
                divider: index < (documentStates.length - 1)
            });
        });
        setItems(items);
    }, [documentStates]);

    return (
        <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => props.onClose()}>
            <Box>
                <DialogTitle>Zmena stavu</DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} mt={1}>
                            <Paper>
                                <ButtonList activedKey={undefined} items={items ?? []} onClick={(e) => {
                                    props.onSelect(e.key as number);
                                    props.onClose();
                                }} />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Späť</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default DocumentsState;