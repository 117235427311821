
export const FormatDecimal = (value: number, maxDigits?: number | undefined) : string | undefined => {
    return value.toLocaleString('sk-sk', { minimumFractionDigits: maxDigits, maximumFractionDigits: maxDigits });
};

interface Currency {
    prefix?: string;
    surfix?: string;
}

export const FormatCurrency = (value: number,  currency: Currency, maxDigits?: number) : string | undefined => {
    return ((currency.prefix ?? '').length > 0 ? currency.prefix + ' ' : '') + FormatDecimal(value, maxDigits) + ((currency.surfix ?? '').length > 0 ? ' ' + currency.surfix : '');
};

//Bežné zaokrúhlenie
// const mathRound2 = (value: number) => Math.round(value * 100) / 100;
// const mathRound4 = (value: number) => Math.round(value * 10000) / 10000;

// Pripravené, nepoužité (vyzerá, že je to OK cez pôvodné funkcie)
/*
export const MathRound = (value: number, digits: number) : number => {
    // Zaokrúhlenie v JS funguje tak, že len zakruhli desatinné číslo na celé číslo spôsobom "toZero": https://learn.microsoft.com/en-us/dotnet/api/system.midpointrounding?redirectedfrom=MSDN&view=net-7.0
    // Ak chcem dve desatiny, tak číslo najskôr vynásobím * 100, pomocou "round" získam celé číslo bez desatin a náslene vydelím / 100, aby som získať dve desatiny
    // Problém je že "toZero" zaokruhluje 5 nadol, takže: 2,325 => 2,32 ale v C# je to: 2,325 => 2,33
    // Aby JS zaokruhlilo nahor musí byť číslo 5 za polovicou, takže ak pridám 0,0001, teda: 2,3251 => 2,33.
    // Riešením bude to posunúť o ďalšie dve desatinydesatinu, kde pripočítam č 1 a následne to posuniem späť:
    // Posun pred zaokuhlením:      2,325 * 100 = 232,5
    // Fix pred zaokruhlením:       232,5 * 100 = 23250 ===> 23250 + 1 = 23251 ===> 23251 / 100 = 232,51
    // Zaokruhlenie:                232,51 = 233
    // Posun po zaokruhlení         233 / 100 = 2,33

    var shift = Math.pow(10, digits);
    value = ((((value * shift) * 100) + 1) / 100);
    value = Math.round(value) / shift;
    return value;
}
*/