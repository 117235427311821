import { useCallback, useEffect, useState } from 'react';
import { Switch, Route, RouteComponentProps, useLocation } from 'react-router-dom';
import axios from 'axios';

// Modely
import { DocumentType, ExternalDocumentType, LoggedSession } from './models/Models';

// Nastavenia
import { AppConfig } from './AppConfig';

// Utility
import { CookieCreate, CookieErase, CookieRead } from './utility/Cookies';

// Téma
import Theme from './theme/Theme';
// import ThemeDark from './theme/ThemeDark';
import GlobalStyles from './theme/GlobalStyles';

// Komponenty
import { Box, CircularProgress, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AppRoutes, AppRouteType, AppRouteUrl } from './AppRoutes';
import Layout from './layout/Layout';
import Login from './views/system/Login';
import Documents from './views/document/Documents';
import ExternalDocuments from './views/external_document/ExternalDocuments';
import { AppContext, AppContextModel } from './AppContext';

enum LoggedType {
    Verification,
    Unauthorized,
    Authorized,
}

const App = () => {

    // Stav kontextu
    const [appContext, setAppContext] = useState<AppContextModel>({
        userName: ''
    });

    // Autorizácia všetkých volaní pomocou globálnej hlavičky
    axios.defaults.headers.common['session'] = (() => {
        return CookieRead(AppConfig.CookieName.LoggedSession) ?? '';
    })();

    // Zmena tmavého režmu
    const [useDarkMode, setUseDarkMode] = useState((localStorage.getItem('state-darkmode') ?? '') === 'yes' ?? false);
    const handleToggleDarkMode = () => {
        if (useDarkMode) {
            localStorage.removeItem('state-darkmode');
        } else {
            localStorage.setItem('state-darkmode', 'yes');
        }
        setUseDarkMode(!useDarkMode);
    };

    // Kontrola prihláseného užívateľa (cookie je vytvorené kvôli automatickému prihláseniu a predĺženiu platnosti)
    const [logged, setLogged] = useState<LoggedType>(LoggedType.Verification);
    const handleLogged = useCallback(() => {
        axios
            .post(AppConfig.ApiUri + 'user/logged', null, {
                headers: {
                    'session': CookieRead(AppConfig.CookieName.LoggedSession) ?? '',
                    'sessionRemember': CookieRead(AppConfig.CookieName.LoggedSessionRemember) ?? ''
                }
            })
            .then(response => {
                const data = response.data as LoggedSession;
                // Ak prihlásenie nie je platné odstránim cookies a nastavím stav "unauthorized"
                if (data.message === 'unauthorized') {
                    CookieErase(AppConfig.CookieName.LoggedSession);
                    CookieErase(AppConfig.CookieName.LoggedSessionRemember);
                    setLogged(LoggedType.Unauthorized);
                }
                // Ak je prihlásenie platné, tak vytvorím cookies a nastavím stav "authorized"
                if (data.message === 'authorized') {
                    if (data.session !== '') {
                        CookieCreate(AppConfig.CookieName.LoggedSession, data.session ?? '', 1);
                        setLogged(LoggedType.Authorized);
                        setAppContext(prev => ({ ...prev, userName: data.userName ?? '' }));
                    }
                    if (data.sessionRemember !== '') {
                        CookieCreate(AppConfig.CookieName.LoggedSessionRemember, data.sessionRemember ?? '', 365);
                    }
                }
            })
            .catch(err => {
                // V prípade chyby odstránim cookies a nastavím stav "unauthorized"
                // Vypnuté: CookieErase(AppConfig.CookieName.LoggedSession);
                // Vypnuté: CookieErase(AppConfig.CookieName.LoggedSessionRemember);
                // Iba dočasne odhlásim, keďže mohlo jednorázovo vypadnúť API (nechcene by to mohlo odhlasovať)
                setLogged(LoggedType.Unauthorized);
                setAppContext(prev => ({ ...prev, userName: '' }));
            });
    }, []);

    // Vykonám kontrolu ihneď po zobrazení kontrolu
    useEffect(() => {
        if (logged === LoggedType.Verification) {
            handleLogged();
        }
        // Vykonám kontrolu každých 5 minút
        if (logged === LoggedType.Authorized) {
            const interval = setInterval(() => {
                handleLogged();
            }, (5 * 60 * 1000));
            return () => clearInterval(interval);
        }
    }, [logged, handleLogged]);

    // Načítam zoznam druhov interných a externých dokladov, pre ktoré vygenerujem trasovanie
    const [routes, setRoutes] = useState<AppRouteType[]>([...AppRoutes]);
    useEffect(() => {
        // Kontrolujem, len ako som prihlásený, keďže cache by mi vrátila prvé volanie, keď mi API nič nevráti (lebo som ešte neprihlásený)
        if (logged !== LoggedType.Authorized) {
            return;
        }

        axios
            .get(AppConfig.ApiUri + 'info/sidebar')
            .then(response => {
                const changed = [...AppRoutes];

                // Doklady
                if (response.data.documenttype) {
                    const types = response.data.documenttype as DocumentType[];
                    types.forEach(item => {
                        changed.push({
                            path: AppRouteUrl.DOCUMENTS + (item.id ?? 0).toString() + '/',
                            title: item.other?.translationName ?? '',
                            component: Documents,
                            exact: true,
                            props: { type: item.id, types: types }
                        });
                    });
                }

                // Externé doklady
                if (response.data.externaldocumenttype) {
                    const types = response.data.externaldocumenttype as ExternalDocumentType[];
                    types.forEach(item => {
                        changed.push({
                            path: AppRouteUrl.EXTERNAL_DOCUMENTS + (item.id ?? 0).toString() + '/',
                            title: item.other?.translationName ?? '',
                            component: ExternalDocuments,
                            exact: true,
                            props: { type: item.id, typeName: item.other?.translationName }
                        });
                    });
                }

                setRoutes(changed);
            });
    }, [logged]);

    // Pri každej zmene URL zistím nadpis aktuálnej stránky z routes
    const location = useLocation();
    useEffect(() => {
        const routeTitle = routes.find(route => route.path === location.pathname)?.title ?? '';
        var headerTitle = document.getElementById('header-title');
        if (headerTitle) {
            headerTitle.innerHTML = routeTitle;
            // @ts-ignore
            document.title = routeTitle;
        }
    }, [location, routes, logged]);


    const RenderApp = () => {
        switch (logged) {
            case LoggedType.Verification:
                return (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                        <CircularProgress />
                    </Box>
                )
            case LoggedType.Unauthorized:
                return (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                        <Login onLogged={handleLogged} />
                    </Box>
                )
            case LoggedType.Authorized:
                return (
                    <Layout onToggleDarkMode={handleToggleDarkMode}>
                        <Switch>
                            {routes.map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path.toString()}
                                        exact={route.exact}
                                        render={(props: RouteComponentProps<any>) => (
                                            <route.component
                                                title={route.title}
                                                {...props}
                                                {...route.props}
                                            />
                                        )} />
                                )
                            }
                            )}
                        </Switch>
                    </Layout>
                )
        }
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={Theme(useDarkMode)}>
                <GlobalStyles />
                <CssBaseline />
                <AppContext.Provider value={appContext}>
                    {RenderApp()}
                </AppContext.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App;