import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Note, NoteColors } from '../../models/Models';

// Utility
import { FindText } from '../../utility/Search';
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, Backdrop, CircularProgress, Fab, IconButton } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import Search from '../../components/Search';
import NoteCreate, { NoteCreateProps } from './NoteCreate';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export interface NotesProps {
    onChange?: () => void;
}

// Komponent pre zoznam
const Notes = (props: NotesProps) => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [userCreate, setNoteCreate] = useState<NoteCreateProps>({
        open: false,
        onSave: () => {
            loadData();
            if (props.onChange) {
                props.onChange();
            }
        },
        onClose: () => setNoteCreate(prev => ({ ...prev, open: false }))
    });

    // Tabuľka
    const [rows, setRows] = useState<Note[]>([]);
    const [rowsFiltered, setRowsFiltered] = useState<Note[]>([]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setNoteCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'note/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                            if (props.onChange) {
                                props.onChange();
                            }
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'note')
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    return (
        <>
            {/* Preloader */}
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <NoteCreate {...userCreate} />

            {/* Možnosti  */}
            {/* <Button fullWidth variant="contained" sx={{ mb: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nová poznámka</Button> */}
            <Search onSearch={s => setSearch(s)} onClear={() => setSearch('')} />

            <Fab color="primary" sx={{ position: 'fixed', bottom: 25, right: 25 }} onClick={() => handleCreate(0)}>
                <AddIcon />
            </Fab>

            {/* Zoznam */}
            {
                rowsFiltered.map(item => {
                    return <Alert
                        key={item.id}
                        variant="filled"
                        icon={false}
                        onDoubleClick={() => handleCreate(item?.id ?? 0)}
                        action={
                            <>
                                <IconButton aria-label="Upraviť" color="inherit" size="small" onClick={() => handleCreate(item?.id ?? 0)}>
                                    <EditIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton aria-label="Vymazať" color="inherit" size="small" onClick={() => handleDelete(item?.id ?? 0, item.text?.substring(0, 30) + '...')}>
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </>
                        }
                        sx={{
                            mt: 1,
                            backgroundColor: '#' + item.color,
                            color: '#' + NoteColors.find(color => color.backgroundColor === item.color)?.textColor
                        }}>
                        <strong>{dateFormat(new Date(item.notificationDate ?? ''), 'dd.MM.yyyy HH:mm')}</strong> - {(item.text ?? '').split('\n')?.map(s => <>{s}<br /></>)} ({item.other?.createdUserName ?? 'Administrátor'})
                    </Alert>;
                }
                )
            }
        </>
    )
}

export default Notes;