import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export interface ConfirmProps {
  children: any;
  open: boolean;
  title: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const Confirm = (props: ConfirmProps) => {

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onCancel}
        onKeyDown={e => {
          if (e.code === 'Enter' && props.onConfirm !== undefined) {
            e.preventDefault();
            e.stopPropagation();
            props.onConfirm();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel}>Nie</Button>
          <Button onClick={props.onConfirm} variant="contained" autoFocus>Áno</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Confirm;