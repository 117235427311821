import { useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { EscapeUrl } from '../../../utility/Text';

// Modely
import { Domain, DefaultPagesList, DefaultPageTypes } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Grid, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { Content, ContentPaperScroll } from '../../../layout/Content';

// Ikony
import LinkIcon from '@mui/icons-material/Link';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): DefaultPagesList => ({
    pages: []
});

const DefaultPages = () => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState(EmptySource());
    const [domains, setDomains] = useState<Domain[]>([]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'defaultpage')
            .then(response => {
                setSource({ ...EmptySource(), pages: response.data });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Načítam zoznam domén
    const loadDomains = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDomains(), []);

    // Spracujem zmeny
    const handleChange = (domainId: number, type: number, url: string) => {
        setSource(prev => ({ ...prev, pages: [...prev.pages?.filter(page => !(page.domainId === domainId && page.type === type)) ?? [], { domainId: domainId, type: type, url: url }] }));
    };

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSave = () => {
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'defaultpage/list', source)
            .then(response => {
                if (response.data === true) {
                    setWarning({ ...EmptyWarning, color: 'success', text: 'Nastavenia boli uložené!' });
                }
                else {
                    setWarning({ ...EmptyWarning, color: 'error', text: 'Nastavenia sa nepodarilo uložiť!' });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {/* Obsah */}
            <Content fullHeight={true}>
                <ContentPaperScroll>

                    {domains.map(domain =>
                        <Grid item container xs={12} columnSpacing={3} key={domain.id} mb={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom component="div">
                                    Doména: {domain.name}
                                </Typography>
                            </Grid>

                            {DefaultPageTypes.map(type =>
                                <Grid key={type.id} item xs={12} md={6} lg={4} mb={1}>
                                    <TextField key={domain.id} margin="dense" label={type.name} fullWidth variant="outlined" autoComplete="off"
                                        value={source.pages?.find(item => item.type === type.id && item.domainId === domain.id)?.url ?? ''}
                                        onChange={(e) => { handleChange((domain?.id ?? 0), (type?.id ?? 0), EscapeUrl(e.target.value, false)) }}
                                        inputProps={{ maxLength: 70 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip title="URL adresa podstránky">
                                                        <LinkIcon className="formIconHelp" />
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                            )}
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Button disabled={loading} type="button" onClick={handleSave} variant="contained" size="large">Uložiť</Button>
                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}
                    </Grid>

                </ContentPaperScroll>
            </Content>
        </>
    )
}

export default DefaultPages;