import React, { FormEvent, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { ProductsFilter, Domain, StockAvailability, Tag, ExternalCatalog, } from '../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Convert';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import ScaleIcon from '@mui/icons-material/Scale';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import TagIcon from '@mui/icons-material/Tag';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ExtensionIcon from '@mui/icons-material/Extension';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface ProductsFilteringProps {
    open: boolean;
    filter?: ProductsFilter;
    stockAvailabilities?: StockAvailability[];
    externalCatalogs?: ExternalCatalog[];
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (filter: ProductsFilter) => void;
    onClose: () => void;
}

// Prázdny filter
const EmptyFilter: ProductsFilter = {
    page: 0,
    pageSize: 25,
    sort: 0
}

const ProductsFiltering = (props: ProductsFilteringProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [stockAvailabilities, setStockAvailabilities] = useState<StockAvailability[]>([]);
    const [externalCatalogs, setExternalCatalogs] = useState<ExternalCatalog[]>([]);
    const [source, setSource] = useState<ProductsFilter>({ ...EmptyFilter });

    // Automaticky naplním zdroj
    useEffect(() => {
        setSource(props.filter ?? EmptyFilter);
    }, [props.filter, props.open]);

    // Načítam zoznam domén
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            });
    };
    useEffect(() => loadDomains(), []);

    // Načítam zoznam štítkov
    const loadTags = () => {
        axios
            .get(AppConfig.ApiUri + 'tag')
            .then(response => {
                setTags(response.data.filter((tag: Tag) => tag.actived));
            });
    };
    useEffect(() => loadTags(), []);

    // Načítam zoznam stavov skladu
    const loadStockAvailabilities = () => {
        axios
            .get(AppConfig.ApiUri + 'stockavailability')
            .then(response => {
                setStockAvailabilities(response.data);
            });
    };

    // Načítam zoznam stavov, ak nefigurujú na vstupe
    useEffect(() => {
        if (props.stockAvailabilities !== undefined) {
            setStockAvailabilities(props.stockAvailabilities);
        } else {
            loadStockAvailabilities();
        }
    }, [props.stockAvailabilities]);

    // Načítam zoznam externých katalógov
    const loadExternalCatalogs = () => {
        axios
            .get(AppConfig.ApiUri + 'externalcatalog')
            .then(response => {
                setExternalCatalogs(response.data);
            });
    };

    // Načítam zoznam stavov, ak nefigurujú na vstupe
    useEffect(() => {
        if (props.externalCatalogs !== undefined) {
            setExternalCatalogs(props.externalCatalogs);
        } else {
            loadExternalCatalogs();
        }
    }, [props.externalCatalogs]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            page: 0,
            pageSize: prev.pageSize,
            sort: prev.sort,
            categoryId: prev.categoryId,
            parametersColumn: prev.parametersColumn
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Filter
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-domain">Doména</InputLabel>
                                    <Select labelId="filter-domain" label="Doména" value={source.domainId ?? 0} name="domainId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'domainId' && setFocus(input)}>
                                        <MenuItem value={0}>Všetko</MenuItem>
                                        <MenuItem value={-1}>Nezaradené</MenuItem>
                                        {domains?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-stockavailability">Dostupnosť na sklade</InputLabel>
                                    <Select labelId="filter-stockavailability" label="Dostupnosť na sklade" value={source.stockAvailabilityId ?? 0} name="stockAvailabilityId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'stockAvailabilityId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        {stockAvailabilities?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-tag">Štítok</InputLabel>
                                    <Select labelId="filter-tag" label="Štítok" value={source.tagId ?? 0} name="tagId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'tagId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        {tags?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-actived">Aktívny</InputLabel>
                                    <Select labelId="filter-actived" label="Aktívny" value={source.actived ?? 0} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'actived' && setFocus(input)}>
                                        <MenuItem value={0}>Všetko</MenuItem>
                                        <MenuItem value={1}>Áno</MenuItem>
                                        <MenuItem value={2}>Nie</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-actived">Košík</InputLabel>
                                    <Select labelId="filter-actived" label="Košík" value={source.hideCart ?? 0} name="hideCart" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'hideCart' && setFocus(input)}>
                                        <MenuItem value={0}>Všetko</MenuItem>
                                        <MenuItem value={1}>Skrytý</MenuItem>
                                        <MenuItem value={2}>Zobrazený</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-externalcatalog">Externý katalóg</InputLabel>
                                    <Select labelId="filter-externalcatalog" label="Externý katalóg" value={source.externalCatalogId ?? 0} name="externalCatalogId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'externalCatalogId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        {externalCatalogs?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name') && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField margin="dense" name="code" label="Kód" fullWidth variant="outlined" autoComplete="off" value={source.code ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'code') && setFocus(input)}
                                    inputProps={{ maxLength: 64 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <TagIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField margin="dense" name="codeSupplier" label="Kód dodávateľa" fullWidth variant="outlined" autoComplete="off" value={source.codeSupplier ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'codeSupplier') && setFocus(input)}
                                    inputProps={{ maxLength: 64 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <TagIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField margin="dense" name="ean" label="EAN" fullWidth variant="outlined" autoComplete="off" value={source.ean ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'ean') && setFocus(input)}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <QrCodeIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField margin="dense" name="mpn" label="Kód výrobcu - MPN" fullWidth variant="outlined" autoComplete="off" value={source.mpn ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'mpn') && setFocus(input)}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <QrCodeIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField margin="dense" name="model" label="Párovací kód modelu (zoskupenie)" fullWidth variant="outlined" autoComplete="off" value={source.model ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'model') && setFocus(input)}
                                    inputProps={{ maxLength: 64 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ExtensionIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="priceFrom" type="text" label="Cena od" variant="outlined" autoComplete="off" value={source.priceFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'priceFrom' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="priceTo" type="text" label="Cena do" variant="outlined" autoComplete="off" value={source.priceTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'priceTo' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="stockAvailabilityQuantityFrom" type="text" label="Zásoby od" variant="outlined" autoComplete="off" value={source.stockAvailabilityQuantityFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'stockAvailabilityQuantityFrom' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CorporateFareIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="stockAvailabilityQuantityTo" type="text" label="Zásoby do" variant="outlined" autoComplete="off" value={source.stockAvailabilityQuantityTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'stockAvailabilityQuantityTo' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CorporateFareIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="weightFrom" type="text" label="Hmotnosť od (g)" variant="outlined" autoComplete="off" value={source.weightFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'weightFrom' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ScaleIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="weightTo" type="text" label="Hmotnosť do (g)" variant="outlined" autoComplete="off" value={source.weightTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'weightTo' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ScaleIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Použiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ProductsFiltering;