import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// Utility
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import skLocale from 'date-fns/locale/sk';
import addHours from 'date-fns/addHours';

// Modely
import { Note, NoteColor, NoteColors } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, FormControlLabel, Box, Switch, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTimePicker } from '@mui/lab';

// Ikony
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Note => ({
    id: 0,
    active: true,
    private: true,
    notificationDate: addHours(new Date(), 1),
    color: 'e9e9e9',
    text: ''
});

// Vstupné parametre
export interface NoteCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    onSave?: () => void;
    onClose: () => void;
}

const NoteCreate = (props: NoteCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Note>(EmptySource());
    
    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if(!props.open) {
            return;
        }
        if((props.id ?? 0) === 0) {
            setSource({ ...EmptySource(), notificationDate: addHours(new Date(), 1) });
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'note/' + props.id)
            .then(response => {
                if(response.data !== null) {
                    if(props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Note);
                }
            })
            .finally(() => {
                setLoading(false);
            }); 
    }, [props.id, props.copy, props.open]); 

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property:string, value:any) => {   
        setSource(prev => ({ ...prev, [property] : value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => { 
        if(warning.text.length > 0) {
            setWarning(EmptyWarning);
        }        
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'note', source)
            .then(response => {
                if(response.data > 0) {
                    if(props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                } 
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            }); 
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if(focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);
    
    return (
        <>
            <Dialog maxWidth="md" fullWidth open={props.open} scroll="body" PaperProps={{ style: { borderLeft: '10px solid #' + source.color } }} onClose={(e, r) => { if(r !== 'backdropClick' && props.onClose) { props.onClose(); }}}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        { ((props?.id ?? 0) > 0 ? 'Poznámka' : 'Nová poznámka') }
                        { (props.copy && ' (kópia)') }
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }} alignItems="flex-end">

                            <Grid item container xs={12} sm={6}>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Switch checked={source.active} name="active" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna notifikácia" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Switch checked={!source.private} name="private" onChange={(e) => { handleChange(e.target.name, !e.target.checked) }} />} label="Zobraziť všetkým užívateľom" />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>                                
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DateTimePicker inputFormat='dd.MM.yyyy HH:mm' ampm={false} disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Čas notifikácie" value={source.notificationDate} onChange={(d) => { handleChange('notificationDate', d) }}                                         
                                        inputRef={input => (props.autoFocus === 'notificationDate' || props.autoFocus === undefined) && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            { source.active && 
                                <Grid item xs={12} my={1}>
                                    <Alert  icon={false}
                                            sx={{
                                                backgroundColor: '#' + source.color,
                                                color: '#' + NoteColors.find(color => color.backgroundColor === source.color)?.textColor
                                            }}
                                    >Po dosiahnutí času notifikácie sa začne poznámka nepretržite zobrazovať v dolnej časti obrazovky.</Alert> 
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <TextField fullWidth required multiline rows={5} margin="dense" name="text" label="Text poznámky" variant="outlined" value={source.text ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'text' && setFocus(input)}
                                    inputProps={{ maxLength: 1000 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth component="fieldset">
                                    <RadioGroup row aria-label="Color" name="radio-color">
                                        { NoteColors.map((item: NoteColor, index) => <Radio key={index} sx={{ color: '#' + item.backgroundColor, '&.Mui-checked': { color: '#' + item.backgroundColor } }} checked={source.color === item.backgroundColor} value={item.backgroundColor ?? ''} name="color" onChange={(e) => { handleChange(e.target.name, e.target.value) }} />) }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={ warning.color as AlertColor }>{warning.text}</Alert>)}  

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), notificationDate: addHours(new Date(), 1), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default NoteCreate;