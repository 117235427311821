import React, { useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { CustomerBase, Country } from '../../models/Models';

// Komponenty
import { Button, Grid, Typography } from '@mui/material';

// Ikony
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

interface CustomerBaseInfoProps {
    customer: CustomerBase;
    countries?: Country[];
    onCustomerEdit?: (openCustomers?: boolean) => void;
}

const CustomerBaseInfo = (props: CustomerBaseInfoProps) => {

    // Stav
    const [countries, setCountries] = useState<Country[]>(props.countries ?? []);

    // Dynamický obsah
    let customerText = '';
    const billingName: string[] = [];
    const billingAddress: string[] = [];
    const deliveryAddress: string[] = [];
    const contactInformation: string[] = [];
    const bank: string[] = [];

    // Načítam zoznam krajín
    const loadCountries = () => {
        axios
            .get(AppConfig.ApiUri + 'country')
            .then(response => {
                setCountries(response.data.filter((country: Country) => country.actived));
            });
    };

    // Načítam krajiny, ak nefigurujú na vstupe
    useEffect(() => {
        if (props.countries !== undefined) {
            setCountries(props.countries);
        } else {
            loadCountries();
        }
    }, [props.countries]);

    // Fakturačné údaje
    if (props.customer.company && props.customer.company?.length > 0) {
        billingName.push(props.customer.company);
    }
    if (props.customer.regId && props.customer.regId?.length > 0) {
        billingName.push('IČO: ' + props.customer.regId);
    }
    if (props.customer.taxId && props.customer.taxId?.length > 0) {
        billingName.push('DIČ: ' + props.customer.taxId);
    }
    if (props.customer.vatId && props.customer.vatId?.length > 0) {
        billingName.push('IČDPH: ' + props.customer.vatId);
    }

    // Fakturačná adresa
    if ((props.customer.person ?? false) === true) {
        customerText = '';
        if (props.customer.title && props.customer.title?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.title;
        }
        if (props.customer.firstname && props.customer.firstname?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.firstname;
        }
        if (props.customer.lastname && props.customer.lastname?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.lastname;
        }
        if (customerText.length > 0) {
            billingAddress.push(customerText);
        }
    }

    customerText = '';
    if (props.customer.street && props.customer.street?.length > 0) {
        customerText += (customerText.length > 0 ? ' ' : '') + props.customer.street;
    }
    if (props.customer.houseNumber && props.customer.houseNumber?.length > 0) {
        customerText += (customerText.length > 0 ? ' ' : '') + props.customer.houseNumber;
    }
    if (customerText.length > 0) {
        billingAddress.push(customerText);
    }

    customerText = '';
    if (props.customer.postcode && props.customer.postcode?.length > 0) {
        customerText += (customerText.length > 0 ? ' ' : '') + props.customer.postcode;
    }
    if (props.customer.city && props.customer.city?.length > 0) {
        customerText += (customerText.length > 0 ? ' ' : '') + props.customer.city;
    }
    if (customerText.length > 0) {
        billingAddress.push(customerText);
    }

    customerText = countries.find(country => country.id === props.customer.countryId)?.other?.translationName ?? '';
    if (customerText.length > 0) {
        billingAddress.push(customerText);
    }

    // Dodacia adresa
    if ((props.customer.deliveryAddressCustom ?? false) === true) {
        if (props.customer.deliveryAddressName && props.customer.deliveryAddressName?.length > 0) {
            deliveryAddress.push(props.customer.deliveryAddressName);
        }

        customerText = '';
        if (props.customer.deliveryAddressStreet && props.customer.deliveryAddressStreet?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.deliveryAddressStreet;
        }
        if (props.customer.deliveryAddressHouseNumber && props.customer.deliveryAddressHouseNumber?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.deliveryAddressHouseNumber;
        }
        if (customerText.length > 0) {
            deliveryAddress.push(customerText);
        }

        customerText = '';
        if (props.customer.deliveryAddressPostcode && props.customer.deliveryAddressPostcode?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.deliveryAddressPostcode;
        }
        if (props.customer.deliveryAddressCity && props.customer.deliveryAddressCity?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.deliveryAddressCity;
        }
        if (customerText.length > 0) {
            deliveryAddress.push(customerText);
        }

        customerText = countries.find(country => country.id === props.customer.deliveryAddressCountryId)?.other?.translationName ?? '';
        if (customerText.length > 0) {
            deliveryAddress.push(customerText);
        }
    }

    if ((props.customer.deliveryAddressCustom ?? false) === false && billingAddress.length > 0) {
        deliveryAddress.push('Rovnaká ako fakturačná');
    }

    // Kontaktné údaje
    if ((props.customer.person ?? false) === false) {
        customerText = '';
        if (props.customer.firstname && props.customer.firstname?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.firstname;
        }
        if (props.customer.lastname && props.customer.lastname?.length > 0) {
            customerText += (customerText.length > 0 ? ' ' : '') + props.customer.lastname;
        }
        if (customerText.length > 0) {
            contactInformation.push(customerText);
        }
    }

    if (props.customer.phone && props.customer.phone?.length > 0) {
        contactInformation.push('Telefón: ' + props.customer.phone);
    }
    if (props.customer.email && props.customer.email?.length > 0) {
        contactInformation.push('Email: ' + props.customer.email);
    }

    // Banka
    if (props.customer.bankName && props.customer.bankName?.length > 0) {
        bank.push('Názov: ' + props.customer.bankName);
    }
    if (props.customer.bankIban && props.customer.bankIban?.length > 0) {
        bank.push('IBAN: ' + props.customer.bankIban);
    }
    if (props.customer.bankSwift && props.customer.bankSwift?.length > 0) {
        bank.push('BIC/SWIFT: ' + props.customer.bankSwift);
    }
    if (props.customer.bankNumber && props.customer.bankNumber?.length > 0) {
        bank.push('Číslo účtu: ' + props.customer.bankNumber);
    }
    if (props.customer.bankCode && props.customer.bankCode?.length > 0) {
        bank.push('Kód banky: ' + props.customer.bankCode);
    }
    
    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={12}>

                <Typography gutterBottom variant="h6" component="div">Fakturačné údaje</Typography>
                {(props.customer.person ?? false) === false && (
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {billingName.map((item, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <br />}
                                {item}
                            </React.Fragment>
                        ))}
                    </Typography>
                )}
                {billingAddress.length > 0 && (
                    <Typography variant="body2" color="text.secondary">
                        {billingAddress.map((item, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <br />}
                                {item}
                            </React.Fragment>
                        ))}
                    </Typography>
                )}
                {billingName.length === 0 && billingAddress.length === 0 && <Typography variant="body2">...</Typography>}

                <Typography gutterBottom variant="h6" component="div" mt={2}>Dodacia adresa</Typography>
                <Typography variant="body2" color="text.secondary">
                    {deliveryAddress.map((item, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {item}
                        </React.Fragment>
                    ))}
                </Typography>
                {deliveryAddress.length === 0 && <Typography variant="body2">...</Typography>}

                <Typography gutterBottom variant="h6" component="div" mt={2}>Kontaktné údaje</Typography>
                <Typography variant="body2" color="text.secondary">
                    {contactInformation.map((item, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {item}
                        </React.Fragment>
                    ))}
                </Typography>
                {contactInformation.length === 0 && <Typography variant="body2">...</Typography>}

                <Typography gutterBottom variant="h6" component="div" mt={2}>Bankový účet</Typography>
                <Typography variant="body2" color="text.secondary">
                    {bank.map((item, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {item}
                        </React.Fragment>
                    ))}
                </Typography>
                {bank.length === 0 && <Typography variant="body2">...</Typography>}

                {props.onCustomerEdit && <Button variant="contained" size="small" startIcon={<ManageAccountsIcon />} color="secondary" sx={{ mt: 2, mr: 1 }} onClick={() => { if(props?.onCustomerEdit) { props.onCustomerEdit(false); } } }>Upraviť</Button>}
                {props.onCustomerEdit && <Button size="small" startIcon={<GroupOutlinedIcon />} color="secondary" sx={{ mt: 2, mr: 0 }} onClick={() => { if(props?.onCustomerEdit) { props.onCustomerEdit(true); } } }>Vybrať</Button>}
                {(props.customer.customerId ?? 0) > 0 && <Button size="small" startIcon={<PersonSearchIcon />} color="secondary" sx={{ mt: 2 }} href={AppRouteUrl.CUSTOMERS + '?id=' + (props.customer.customerId ?? 0).toString()} target="_blank">Detail</Button>}
            </Grid>
        </Grid>
    )
}

export default CustomerBaseInfo;