import { useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';

// Modely
import { VoucherCode } from '../../../models/Models';

// Utility
import { FindText } from '../../../utility/Search';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../../utility/DataGrid';

// Komponenty
import { DataGrid, GridColDef, skSK, GridValueFormatterParams, GridValueGetterParams, GridFilterModel, GridRowId } from "@mui/x-data-grid";
import { Button, Divider, Grid, ListItemIcon, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import Search from '../../../components/Search';

// Ikony
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';

// Vstupné parametre
export interface VoucherCreateCodesProps {
    rows: VoucherCode[];
    onChange: (codes: VoucherCode[]) => void;
}

// Komponent pre zoznam 
const VoucherCreateCodes = (props: VoucherCreateCodesProps) => {

    // Lokálny stav
    const [search, setSearch] = useState('');
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    
    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'vouchercreatecodes' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'code', headerName: 'Kód', hide: false, minWidth: 50, flex: 0.8, editable: true },
        {
            field: 'orders', headerName: 'Počet objednávok', hide: false, minWidth: 50, flex: 0.8, type: 'number', align: 'center', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => (params.row.other.orders)
        },
        {
            field: 'createdDate', headerName: 'Vytvorený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => (params?.value as Date).toLocaleDateString() ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rowsEditModel, setRowsEditModel] = useState<any>({});
    const [rowsEditData, setRowsEditData] = useState<any>({});
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<any[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [pageSize, setPageSize] = useState<number>(10);

    // Úprava záznamu
    const handleEditRowsModelChange = (model: any) => {
        const editedIds = Object.keys(model);
        if (editedIds.length === 0) {

            // Získam atribút a hodnotu
            var attribute = Object.keys(rowsEditData)[0].toString();
            var value = rowsEditData[Object.keys(rowsEditData)[0]].value;

            // Získam ID riadku (v tomto prípade je ako ID použitý index vstupného pola)
            const index = parseInt(Object.keys(rowsEditModel)[0]);

            // Vytvorím nový objekt riadkov
            const rows: any = [ ...props.rows ];

            // Priamo upravím hodnotu
            rows[index][attribute] = value;

            // Vyvolám zmenu
            props.onChange(rows);
        } else {
            // Prebieha úprava, uchovám upravené dáta
            setRowsEditData(model[editedIds[0]]);
        }
        // Uchovám model, z neho vyčítam Id záznamu
        setRowsEditModel(model);
    }

    // Vymazať záznam (vynechám tie, ktoré sú naviazané na objednávky)
    const handleDelete = (item: any) => {
        if((item.other.orders ?? 0) > 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: item.code ?? '', children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                const deleted = props.rows[item.index];
                const rows = [ ...props.rows.filter(row => row !== deleted) ?? [] ];
                props.onChange([ ...rows ]);
            }
        }));
    };

    // Vymazať záznamy (vynechám tie, ktoré sú naviazané na objednávky)
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                const deleted: any[] = [ ...ids.map((index) => props.rows[index]) ];
                const rows = [ ...props.rows.filter(row => (row?.other?.orders ?? 0) > 0 || !deleted.includes(row)) ?? [] ];
                props.onChange([ ...rows ]);
                setRowsSelected([]); 
            }
        }));    
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        const indexes = rowsSelected.map(r => r as number);
        const rows = rowsFiltered.filter(row => indexes.includes(row.index));
        DataGridExport({ 
            type: type,
            columns: columnsSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            specific: [{
                field: 'orders',
                getValue: (row: any) => {
                    return row.other?.orders ?? '';
                }
            }]
         });
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => {
        var source = [ ...props.rows ].map((row, index) => ({ index: index, ...row }));
        var rows = (search.length > 0 ? FindText(source, search) : source);
         setRowsFiltered(rows);
         if(rowsSelected.length > 0) {
            setRowsSelected([]); 
         }
    }, [props.rows, search]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Horný panel */}
            <Grid container alignItems="center">

                {/* Možnosti */}
                <Grid item xs={12} md>
                    {/* Označené záznamy (možnosti) */}
                    <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                    <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                        <MenuItem onClick={() => { handleExport('csv'); }}>
                            <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                        </MenuItem>
                        <MenuItem onClick={() => { handleExport('xml'); }}>
                            <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                            <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                            <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber</MenuItem>
                    </Menu>
                </Grid>

                {/* Vyhľadávanie */}
                <Grid item xs={12} md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                    <Search onSearch={s => setSearch(s)}
                        onClear={() => {
                            setSearch('');
                            setFilterModel({ items: [], linkOperator: undefined });
                        }}
                        autoFocus={true} />
                </Grid>
            </Grid>

            {/* Zoznam */}
            <div style={{ marginTop: '10px', display: 'flex', height: '100%' }}>
                <DataGrid
                    density="compact"
                    autoHeight
                    checkboxSelection
                    disableSelectionOnClick
                    getRowId={(e) => e.index}
                    editMode='row'
                    editRowsModel={rowsEditModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pageSize={pageSize}
                    onPageSizeChange={(size) => setPageSize(size)}
                    columns={columns}
                    rows={rowsFiltered}
                    localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                    isCellEditable={(params) => (params.row.other.orders ?? 0) === 0}

                    // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                    onCellKeyDown={(e, c) => {
                        if((e.row.other.orders ?? 0) > 0) {
                            return;
                        }
                        if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                            c.preventDefault();
                            c.stopPropagation();
                            handleDelete(e.row);
                            return;
                        }
                    }}

                    // Filtrácia
                    filterModel={filterModel}
                    onFilterModelChange={e => setFilterModel(e)}

                    // Vybrané záznamy
                    selectionModel={rowsSelected}
                    onSelectionModelChange={e => setRowsSelected(e)}

                    // Stĺpce (automatické ukladanie nastavení)
                    onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                />
            </div>
        </>
    )
}

export default VoucherCreateCodes;