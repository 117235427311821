import { useEffect, useState } from 'react';
import { Box, Container, Drawer, useTheme } from '@mui/material';
import Header from './Header';
import Sidebar from './Sidebar';

// Utility
import { ResponsivePoint as HidePoint } from '../utility/ResponsivePoint';
import { LoadLocalSettings, SaveLocalSettings } from '../utility/LocalSettings';

// Ikony a assety
import Logo from '../assets/logo.svg';
import LogoIcon from '../assets/logo-icon.svg';

interface Props {
    children: any;
    window?: () => Window;
    onToggleDarkMode?: () => void;
}

const Layout = (props: Props) => {
    const theme = useTheme();
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const isMobile = HidePoint().sm;

    const [open, setOpen] = useState(LoadLocalSettings('sidebar-open', 'true') === 'true');
    const setOpenAndSave = (value: boolean) => {
        setOpen(value);
        SaveLocalSettings('sidebar-open', value.toString());
    };

    useEffect(() => {
        if (isMobile) {
            setOpen(false);
        }
    }, [isMobile]);

    return (
        <Box
            sx={{
                display: 'grid', position: 'fixed', width: '100%', height: '100%',
                gridTemplateColumns: {
                    md: (!open ? '60px auto' : '240px auto'),
                    xs: '0px auto'
                },
                gridTemplateRows: 'auto',
                gridTemplateAreas: '"sidebar content"'
            }}
        >
            <Drawer container={container} variant="temporary" open={open} onClose={() => setOpen(!open)} ModalProps={{ keepMounted: false }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px' },
                }}
            >
                <div style={{ textAlign: 'center', margin: '30px 0 10px 0' }}>
                    <img alt="" src={Logo} style={{ width: '60%' }} />
                </div>
                <Sidebar open={true} onClickToggle={() => setOpen(false)} />
            </Drawer>
            <Box
                sx={{
                    gridArea: 'sidebar', overflowX: 'hidden', overflowY: 'auto', backgroundColor: theme.layout.sidebar.backgroundColor, borderRightWidth: '1px',
                    borderRightColor: theme.layout.sidebar.borderColor, borderRightStyle: 'solid'
                }}>
                {open ? <div style={{ textAlign: 'center', margin: '30px 0 10px 0' }}>
                    <img alt="" src={Logo} style={{ width: '60%' }} />
                </div>
                    : <div style={{ textAlign: 'center', margin: '15px 0 10px 0' }}>
                        <img alt="" src={LogoIcon} style={{ width: '60%' }} />
                    </div>}
                <Sidebar open={open} />
            </Box>
            <Box sx={{ gridArea: 'content', overflowY: 'auto', position: 'relative' }}>
                <Header onToggleSidebar={() => setOpenAndSave(!open)} onToggleDarkMode={props.onToggleDarkMode} />
                <Container maxWidth={false} sx={{
                    position: 'absolute', height: '100%', boxSizing: 'border-box',
                    padding: { xs: '75px 10px 10px 10px' }
                }}>
                    {props.children}
                </Container>
            </Box>
        </Box>
    )
}

export default Layout;
