// Komponenty
import { Box, Drawer } from '@mui/material';
import Notes from './Notes';

// Vstupné parametre
export interface NotesDialogProps {
    open: boolean;
    argument?: any; //môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    keepMounted?: boolean;
    onClose?: () => void;
    onChange?: () => void;
}

const NotesDialog = (props: NotesDialogProps) => {
    return (
        <>
            <Drawer keepMounted={props.keepMounted ?? false} anchor="right" open={props.open} onClose={props.onClose}>
                <Box sx={{ width: '500px' }} padding={1}>
                    <Notes onChange={props.onChange} />
                </Box>
            </Drawer>
        </>
    )
}

export default NotesDialog;