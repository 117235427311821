import React, { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { FileDirectory } from '../../models/Models';

// Komponenty
import { Backdrop, Box, Button, CircularProgress, Divider, IconButton, ListItemIcon, Menu, Paper, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ButtonList, { Item } from '../../components/ButtonList';
import FileDirectoryCreate, { FileDirectoryCreateProps } from './FileDirectoryCreate';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import MessageBox, { MessageBoxProps } from '../../components/MessageBox';

// Ikony
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';

// Predpis pre otvárací zoznam
interface SelectItem {
    id: number;
    name: string;
}

interface FilesDirectoriesProps {
    directories: FileDirectory[];   // Zoznam zložiek
    activeDirectoryId: number;      // Id aktívnej zložky
    onChanged?: () => void;         // Udalosť po zmene v zozname
    onClick?: (id: number) => void; // Udalosť po kliknutí
}

const FilesDirectories = (props: FilesDirectoriesProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [directoriesSource, setDirectoriesSource] = useState<Item[]>([]);
    const [directoriesSourceSelect, setDirectoriesSourceSelect] = useState<SelectItem[]>([]);
    const [fileDirectoryCreate, setFileDirectoryCreate] = useState<FileDirectoryCreateProps>({
        open: false,
        keepMounted: true,
        onSave: props.onChanged,
        onClose: () => setFileDirectoryCreate(prev => ({ ...prev, open: false }))
    });
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({
        open: false, title: '', children: null, onClose: () => {
            setMessageBox(prev => ({
                ...prev, open: false
            }));
        }
    });

    // Kontextové menu
    const [directoriesMenuItem, setDirectoriesMenuItem] = useState<Item>();
    const [directoriesMenuEl, setDirectoriesMenuEl] = useState<null | HTMLElement>(null);

    // Pregenerovanie zdroja pre zložky po zmene dát
    useEffect(() => {
        // Funkcia pre rekurzívne vygenerovanie položiek pre button list (pomocou level obmedzím rekurzívne vykreslenie kvôli zacykleniu ak bude chybný parent)
        const generate = (): Item[] | null => {
            const byParent = (id: number, level?: number): Item[] => {
                if((level ?? 0) > 30) {
                    return [];
                }
                return props.directories.filter(d => d.parentId === id)?.map(d => ({
                    key: d.id ?? 0,
                    title: d.name ?? '',
                    icon: <FolderOpenIcon />,
                    items: byParent(d.id ?? -1, (level ?? 0) + 1),
                    onClickMore: handleDirectoriesMenuOpen
                })) ?? null;
            };
            return byParent(0);
        };
        const items: Item[] = [
            { key: 0, title: 'Všetko', icon: <FolderIcon />, divider: true },
            ...(generate() ?? []),
            //{ key: -1,  title: 'Nepožité',  icon: <SnippetFolderIcon /> },
            { key: -2, title: 'Kôš', icon: <DeleteIcon /> }
        ];
        setDirectoriesSource(items);

        // Funkcia pre rekurzívne vygenerovanie položiek (pomocou level obmedzím rekurzívne vykreslenie kvôli zacykleniu ak bude chybný parent)
        const itemsSelect: SelectItem[] = [];
        const generateSelect = (parentId: number, parentName: string, level?: number) => {
            if ((level ?? 0) > 30) {
                return [];
            }
            props.directories.filter(d => d.parentId === parentId)?.forEach(d => {
                const name = parentName + (parentName.length > 0 ? ' / ' : '') + (d.name ?? '');
                itemsSelect.push({ id: d?.id ?? 0, name: name });
                generateSelect(d.id ?? 0, name, (level ?? 0) + 1);
            });
        };
        generateSelect(0, '');
        //itemsSelect.push({ id: -1, name: 'Nepoužité' });
        itemsSelect.push({ id: -2, name: 'Kôš' });
        setDirectoriesSourceSelect(itemsSelect);

    }, [props.directories]);

    // Funkcia pre otvorenie kontexového menu zložky
    const handleDirectoriesMenuOpen = (e: HTMLElement, item: Item) => {
        setDirectoriesMenuItem(item);
        setDirectoriesMenuEl(e);
    };

    // Funkcia pre otvorenie kontexového menu zložky
    const handleDirectoriesSelectMenuOpen = (e: HTMLElement, id: number) => {
        const item = props.directories.find(d => d.id === id);
        if (item) {
            handleDirectoriesMenuOpen(e, {
                key: item.id ?? 0,
                title: item.name ?? ''
            });
        }
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number, parentId?: number) => {
        setFileDirectoryCreate(prev => ({
            ...prev,
            id: id,
            parentId: parentId,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        // Systémový adresár nie je možné zmazať
        var directoryType = props.directories.find(d => d.id === id)?.type ?? 0;
        if (directoryType > 0) {
            setMessageBox(prev => ({
                ...prev,
                open: true,
                title: 'Systémový priečinok',
                children: 'Tento priečinok nie je povolené vymazať.'
            }));
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento priečinok a jeho pod-priečinky? Súbory budú presunuté medzi všetky súbory.', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'filedirectory/' + id)
                    .then(response => {
                        if (response.data === true && props.onChanged !== undefined) {
                            props.onChanged();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    return (
        <React.Fragment>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Upozornenie */}
            <MessageBox {...messageBox} />

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <FileDirectoryCreate {...fileDirectoryCreate} />

            {/* Kontextové menu položky */}
            <Menu id="menu-directories" anchorEl={directoriesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(directoriesMenuEl)} onClose={() => setDirectoriesMenuEl(null)} >
                <MenuItem dense onClick={() => { handleCreate(0, directoriesMenuItem?.key ?? 0); setDirectoriesMenuEl(null); }}>
                    <ListItemIcon><AddIcon fontSize="small" /></ListItemIcon> Vložiť nový priečinok
                </MenuItem>
                <MenuItem dense onClick={() => { handleCreate(directoriesMenuItem?.key ?? 0, 0); setDirectoriesMenuEl(null); }}>
                    <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Upraviť priečinok
                </MenuItem>
                <MenuItem dense onClick={() => { handleDelete(directoriesMenuItem?.key ?? 0, directoriesMenuItem?.title ?? ''); setDirectoriesMenuEl(null); }}>
                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať priečinok
                </MenuItem>
            </Menu>

            {/* Zoznam priečinkov (harmonika) */}
            <Paper sx={{ display: { xs: 'none', md: 'inherit' } }}>
                <ButtonList activedKey={props.activeDirectoryId} dense={true} items={directoriesSource ?? []} onClick={(e) => {
                    if (props.onClick !== undefined) {
                        props.onClick(e.key as number);
                    }
                }} />
                <Box padding={1}>
                    <Button fullWidth variant="outlined" color="secondary" onClick={() => handleCreate(0, (props.activeDirectoryId > 0 ? props.activeDirectoryId : 0))}>Nový priečinok</Button>
                </Box>
            </Paper>

            {/* Zoznam priečinkov (otvárací zoznam) */}
            <Paper sx={{ display: { xs: 'inline-flex', md: 'none' }, p: '3px', alignItems: 'center', width: '100%' }}>
                <Select fullWidth size="small" value={props.activeDirectoryId ?? 0} sx={{ flex: 1 }}
                    onChange={(e) => { if (props.onClick !== undefined) { props.onClick((parseInt(e.target.value.toString()))); } }}>
                    <MenuItem key={0} value={0}>Všetko</MenuItem>
                    {directoriesSourceSelect.map(directory => (<MenuItem key={directory.id} value={directory.id}>{directory.name}</MenuItem>))}
                </Select>
                <IconButton type="submit" aria-label="Možnosti" title="Možnosti" disabled={props.activeDirectoryId <= 0} onClick={(e) => { handleDirectoriesSelectMenuOpen(e.currentTarget, props.activeDirectoryId); }}>
                    <MoreVertIcon fontSize="small" />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton aria-label="Nový priečinok" title="Nový priečinok" onClick={() => { handleCreate(0, (props.activeDirectoryId > 0 ? props.activeDirectoryId : 0)); }}>
                    <CreateNewFolderIcon fontSize="small" />
                </IconButton>
            </Paper>

        </React.Fragment>
    )
}

export default FilesDirectories;