// Komponenty
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import Customers from './Customers';

// Modely
import { CustomerLite, CustomersFilter } from '../../models/Models';
import { useState } from 'react';

// Vstupné parametre
export interface CustomersDialogProps {
    open: boolean;
    argument?: any; //môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    keepMounted?: boolean;
    filter?: CustomersFilter;
    onSelect?: (customers: CustomerLite[], argument?: any) => void;
    onClose?: () => void;
}

const CustomersDialog = (props: CustomersDialogProps) => {

    // Označené záznamy
    const [selected, setSelected] = useState<CustomerLite[]>([]);

    // Zmena výberu s možnosťou potvrdenia výberu
    const handleSelectionChanged = (items: CustomerLite[], confirm: boolean) => { 
        if(confirm) {
            handleConfirm(items); // Pri potvrdení rovno odovzdám aktuálny zoznam, nemôžem čakať na zmenu stavu
        } else {
            setSelected(items); // Zmením aktuálny výber
        }
    };

    // Potvrdenie výberu (nepoužívam zoznam zo stavu keďže zmnena stavu sa môže dokončiť neskôr ako príde potvrdenie)
    const handleConfirm = (items: CustomerLite[]) => {
        if(props?.onSelect) {
            props?.onSelect(items, props.argument);
        }
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick' && props.onClose) { props.onClose(); }}}>
                {/* <DialogTitle>Adresár</DialogTitle> */}
                <DialogContent sx={{ height: '87vh' }}>
                    <Customers filter={props.filter} onSelectionChanged={handleSelectionChanged} />
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button onClick={props.onClose}>Späť</Button>
                    <Button onClick={() => handleConfirm(selected)} type="submit" variant="contained">Potvrdiť výber</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomersDialog;