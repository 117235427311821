import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, Grid, FormControlLabel, TextField, Alert, InputAdornment, AlertColor, DialogActions, Button, Switch } from '@mui/material';

// Modely
import { DocumentNote } from '../../models/Models';

// Ikony
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): DocumentNote => ({
    id: 0,
    private: true,
    note: '',
    other: {
        createdUserName: ''
    }
});

// Vstupné parametre
export interface DocumentCreateNoteProps {
    note?: DocumentNote;
    noteIndex?: number;
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (note: DocumentNote, noteIndex?: number) => void;
    onClose: () => void;
}

const DocumentCreateNote = (props: DocumentCreateNoteProps) => {

    // Globálne premenné
    const appContext = useContext(AppContext);
    
    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<DocumentNote>(EmptySource());

    // Automaticky vyplním okno
    useEffect(() => {
        if (!props.open) {
            return;
        }
        // Použijem props.item alebo emptySource (automaticky spustím prepočet, aby sa doplnili dopočítavané hodnoty)
        setSource(props.note ?? { ...EmptySource(), createdDate: new Date(), other: { createdUserName: appContext.userName ?? '' } });
    }, [props.note, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps


    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            ...prev,
            private: false,
            note: ''
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source, props.noteIndex);
        props.onClose();
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick' && props.onClose) { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.noteIndex === undefined ? 'Nová poznámka' : 'Poznámka'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }} alignItems="flex-end">
                            <Grid item xs={12}>
                                <FormControlLabel control={<Switch checked={source.private ?? false} name="private" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Interná poznámka" />
                            </Grid>
                            { source.private && 
                                <Grid item xs={12} my={1}>
                                    <Alert severity="info">Táto poznámka sa zákazníkovi nezobrazí</Alert> 
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TextField fullWidth required multiline rows={8} margin="dense" name="note" label="Text poznámky" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                    inputProps={{ maxLength: 1000 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                        </Grid>
                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default DocumentCreateNote;