import React, { useState } from 'react';

// Modely
import { DocumentNote } from '../../models/Models';
import { DocumentCreateComponentProps } from './DocumentCreate';
import DocumentCreateNote, { DocumentCreateNoteProps } from './DocumentCreateNote';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, AlertTitle, Button, Grid, IconButton } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from '@mui/icons-material/Comment';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

// Vstupné parametre
export interface DocumentCreateNotesProps extends DocumentCreateComponentProps {

}

const DocumentCreateNotes = (props: DocumentCreateNotesProps) => {

    // Lokálny stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Stav (editácia položky)
    const [documentCreateNote, setDocumentCreateNote] = useState<DocumentCreateNoteProps>({
        open: false,
        onSave: (note, noteIndex) => {
            props.setSource(prev => {
                if (noteIndex !== undefined && noteIndex >= 0) {
                    // Aktualizácia existujúceho záznamu
                    const notes = [...prev.notes ?? []];
                    notes[noteIndex] = { ...note };
                    return ({ ...prev, notes: [...notes ?? []] });
                }
                else {
                    // Nový záznam
                    return ({ ...prev, notes: [...prev.notes ?? [], note] });
                }
            });
        },
        onClose: () => setDocumentCreateNote(prev => ({ ...prev, open: false }))
    });

    // Pridať upraviť záznam
    const handleCreate = (index?: number) => {
        let note: DocumentNote | undefined = undefined;

        // Úprava položky podľa indexu
        if (index !== undefined && index >= 0) {
            note = (props.source?.notes ?? [])[index ?? 0];
        }

        setDocumentCreateNote(prev => ({
            ...prev,
            note: note,
            noteIndex: index,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (index: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                props.setSource(prev => {
                    const notes = prev.notes ?? [];
                    return ({ ...prev, notes: [...notes.filter(note => note !== notes[index]) ?? []] });
                });
            }
        }));
    };

    // Zákaznícke poznámky nie je povolené meniť
    const isCustomerNote = (note: DocumentNote): boolean => {
        return !((note.id ?? 0) === 0 || (note.createdUserId ?? 0) > 0);
    }

    return (
        <>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nová poznámka */}
            <DocumentCreateNote {...documentCreateNote} />

            <Grid container columnSpacing={1}>

                <Grid item xs={12} mb={1}>
                    <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} aria-label="Vložiť" aria-controls="menu-add" aria-haspopup="true" onClick={() => handleCreate()}>Nová poznámka</Button>
                </Grid>

                {/* Zoznam */}
                {
                    props.source.notes?.map((note, index) => {
                        return (
                            <Grid item xs={12} mt={1} key={index}>
                                <Alert
                                    variant="standard"
                                    severity={(note.id ?? 0) === 0 || (note.createdUserId ?? 0) > 0 ? 'warning' : 'success'}
                                    icon={note.private === true ? <GppMaybeIcon /> : <CommentIcon />}
                                    onDoubleClick={() => {
                                        if (!isCustomerNote(note)) {
                                            handleCreate(index);
                                        }
                                    }}
                                    action={
                                        !isCustomerNote(note) &&
                                        <>
                                            <IconButton aria-label="Upraviť" color="inherit" size="small" onClick={() => handleCreate(index)}>
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="Vymazať" color="inherit" size="small" onClick={() => handleDelete(index, note.note?.substring(0, 30) + '...')}>
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </>
                                    }>
                                    <AlertTitle>
                                        {note.createdDate === undefined ? 'Nová poznámka' : dateFormat(new Date(note.createdDate ?? new Date()), 'dd.MM.yyyy HH:mm')}
                                    </AlertTitle>
                                    {isCustomerNote(note) ? <strong>Zákazník</strong> : <strong>{note.other?.createdUserName ?? 'Administrátor'}</strong>}: {note.note}
                                </Alert>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default DocumentCreateNotes