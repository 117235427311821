import { useTheme } from '@mui/material';

interface ResponsivePointType {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

export function ResponsivePoint() : ResponsivePointType {
    const theme = useTheme();
    return {
        xs: window.innerWidth <= theme.breakpoints.values.xs,
        sm: window.innerWidth <= theme.breakpoints.values.sm,
        md: window.innerWidth <= theme.breakpoints.values.md,
        lg: window.innerWidth <= theme.breakpoints.values.lg,
        xl: window.innerWidth <= theme.breakpoints.values.xl,
    }
}