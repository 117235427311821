import { useState } from 'react';

// Komponenty
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import ExternalDocuments from './ExternalDocuments';

// Modely
import { ExternalDocument, ExternalDocumentType } from '../../models/Models';

// Vstupné parametre
export interface ExternalDocumentsDialogProps {
    open: boolean;
    argument?: any; //môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    keepMounted?: boolean;
    types?: ExternalDocumentType[]; // Druhy dokladu - číselník
    type?: number; // Druh dokladu - id
    onSelect?: (documents: ExternalDocument[], argument?: any) => void;
    onClose?: () => void;
}

const ExternalDocumentsDialog = (props: ExternalDocumentsDialogProps) => {

    // Označené produkty
    const [selected, setSelected] = useState<ExternalDocument[]>([]);
    // const [documentType, setExternalDocumentType] = useState<ExternalDocumentType>({ id: props?.type ?? 0});

    // Automaticky nájdem aktuálny typ dokladu po zmene id druhu dokladu, alebo načítania číselníku
    // useEffect(() => {
    //     if((props?.type ?? 0) === 0 || (props.types?.length ?? 0) === 0) {
    //         return;
    //     }
    //     setExternalDocumentType(props.types?.find(item => item.id === props?.type) ?? { });
    // }, [props?.type, props?.types]);

    // Zmena výberu s možnosťou potvrdenia výberu
    const handleSelectionChanged = (items: ExternalDocument[], confirm: boolean) => { 
        if(confirm) {
            handleConfirm(items); // Pri potvrdení rovno odovzdám aktuálny zoznam, nemôžem čakať na zmenu stavu
        } else {
            setSelected(items); // Zmením aktuálny výber
        }
    };

    // Potvrdenie výberu (nepoužívam zoznam zo stavu keďže zmnena stavu sa môže dokončiť neskôr ako príde potvrdenie)
    const handleConfirm = (items: ExternalDocument[]) => {
        if(props?.onSelect) {
            props?.onSelect(items, props.argument);
        }
    };
    
    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick' && props.onClose) { props.onClose(); }}}>
                {/* <DialogTitle>{(documentType?.id ?? 0) > 0 ? documentType.other?.translationName : 'Doklady'}</DialogTitle> */}
                <DialogContent sx={{ height: '87vh' }}>
                    <ExternalDocuments onSelectionChanged={handleSelectionChanged} type={props.type} />
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button onClick={props.onClose}>Späť</Button>
                    <Button onClick={() => handleConfirm(selected)} type="submit" variant="contained">Potvrdiť výber</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ExternalDocumentsDialog;
