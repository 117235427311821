import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Import, ImportExportIntervals } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, Tooltip, Box, FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Import => ({
    id: 0,
    actived: true,
    client: '',
    priority: 1,
    name: '',
    source: '',
    interval: 8,
    intervalHour: 4,
    intervalOnce: false
});

// Vstupné parametre
export interface ImportCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const ImportCreate = (props: ImportCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Import>(EmptySource());

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'import/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Import);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'import', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Import' : 'Nový import')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel control={<Switch checked={source.intervalOnce} name="intervalOnce" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Jednorázový" />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="import-create-priority">Priorita</InputLabel>
                                    <Select labelId="import-create-priority" label="Priorita" value={source.priority ?? 0} name="priority" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'priority' && setFocus(input)}>
                                        <MenuItem value={1}>Nízka</MenuItem>
                                        <MenuItem value={2}>Vysoká</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="source" label="Zdroj (url, cesta)" fullWidth variant="outlined" autoComplete="off" value={source.source ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'source') && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="URL adresa alebo cesta k zdrojovému súboru pre import.">
                                                    <InsertLinkIcon />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="import-create-interval">Interval</InputLabel>
                                    <Select labelId="import-create-interval" label="Interval" value={source.interval ?? 0} name="interval" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'interval' && setFocus(input)}>
                                        {ImportExportIntervals.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="import-create-interval-hour">Čas importu</InputLabel>
                                    <Select disabled={(source.interval ?? 0) === 9} labelId="import-create-interval-hour" label="Hodina importu" value={source.intervalHour ?? 0} name="intervalHour" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'intervalHour' && setFocus(input)}>
                                        <MenuItem value={0}>00:00</MenuItem>
                                        <MenuItem value={1}>01:00</MenuItem>
                                        <MenuItem value={2}>02:00</MenuItem>
                                        <MenuItem value={3}>03:00</MenuItem>
                                        <MenuItem value={4}>04:00</MenuItem>
                                        <MenuItem value={5}>05:00</MenuItem>
                                        <MenuItem value={6}>06:00</MenuItem>
                                        <MenuItem value={7}>07:00</MenuItem>
                                        <MenuItem value={8}>08:00</MenuItem>
                                        <MenuItem value={9}>09:00</MenuItem>
                                        <MenuItem value={10}>10:00</MenuItem>
                                        <MenuItem value={11}>11:00</MenuItem>
                                        <MenuItem value={12}>12:00</MenuItem>
                                        <MenuItem value={13}>13:00</MenuItem>
                                        <MenuItem value={14}>14:00</MenuItem>
                                        <MenuItem value={15}>15:00</MenuItem>
                                        <MenuItem value={16}>16:00</MenuItem>
                                        <MenuItem value={17}>17:00</MenuItem>
                                        <MenuItem value={18}>18:00</MenuItem>
                                        <MenuItem value={19}>19:00</MenuItem>
                                        <MenuItem value={20}>20:00</MenuItem>
                                        <MenuItem value={21}>21:00</MenuItem>
                                        <MenuItem value={22}>22:00</MenuItem>
                                        <MenuItem value={23}>23:00</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Alert severity="info" sx={{ mt: 1 }}>
                                    Import spracováva dátové súbory vo formáte XML alebo JSON, ktoré spĺňaujú presnú štruktúru danú systémom s maximálnou veľkosťou 200 MB. 
                                    Pre univerzálny import je nutné využiť kontektor, ktorý vygeneruje zdrojový súbor určený k následnému importu. 
                                </Alert>
                            </Grid>

                            {(source.priority ?? 1) === 2 && (
                                <Grid item xs={12}>
                                    <Alert severity="info" sx={{ mt: 1 }}>
                                        Importy s vysokou prioritou sú spracované skôr ako tie s nízkou prioritou, ak začiatok importu vychádza na rovnaký čas.
                                    </Alert>
                                </Grid>
                            )}

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ImportCreate;