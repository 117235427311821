import React, { useCallback, useEffect, useState } from 'react';

// Modely
import {  AutomationAction, AutomationTriggerTypeSetting, AutomationActionSetting, AutomationActionType } from '../../../models/Models';

// Komponenty
import { TableRow, TableCell, TextField, InputAdornment, MenuItem, Select, Grid, FormControl, InputLabel, Table, TableBody, TableHead, Button } from '@mui/material';

// Ikony
import TuneIcon from '@mui/icons-material/Tune';

interface Props {
    action: AutomationAction;
    actionTypes: AutomationActionType[];
    onChange: (action: AutomationAction) => void;
    onDelete: () => void;
}

const AutomationCreateAction = (props: Props) => {

    // Stav
    const [automationActionSettings, setAutomationTriggerTypeSetting] = useState<AutomationTriggerTypeSetting[]>([])

    // Zmeny vo formulári
    const handleChange = (property:string, value:any) => {   
        props.onChange(({ ...props.action, [property] : value }))
    }
    const handleChangeSetting = (uid: string, property:string, value:any) => {   
        const item: AutomationActionSetting = { ...props.action.settings?.find(item => item.uid === uid), uid: uid, [property]: value };
        handleChange('settings', [ ...props.action.settings?.filter(item => item.uid !== uid) ?? [], item ]);
    }

    // Funkcia pre získanie zoznamu nastavení
    const loadSettings = useCallback(() => {
        if(props.action.type === 0) {
            setAutomationTriggerTypeSetting([]);
        }
        else {
            setAutomationTriggerTypeSetting(props.actionTypes.find(item => item.id === props.action.type)?.settings ?? []);
        }
    }, [props.actionTypes, props.action.type]);
    useEffect(() => loadSettings(), [loadSettings, props.action.type]);
    
    return (
        <Grid container columnSpacing={1}>

            <Grid item xs={8} sm={10}>
                <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                    <InputLabel id="automation-create-type">Typ akcie *</InputLabel>
                    <Select required labelId="automation-create-type" label="Typ akcie *" value={props.action.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}>
                        <MenuItem value={0}>Žiadna</MenuItem>
                        { props.actionTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)) }
                    </Select>
                </FormControl>
            </Grid>    

            <Grid item xs={4} sm={2}>
                <Button fullWidth variant="contained" color="secondary" onClick={props.onDelete} sx={{ mt: 1.2 }}>Vymazať</Button>
            </Grid>

            { (automationActionSettings?.length ?? 0) > 0 && 
                <Grid item xs={12} mt={1}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Parameter</TableCell>
                                <TableCell>Nastavenie</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {automationActionSettings?.map((setting, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    <strong>{setting.uid}</strong> - {setting.name}
                                </TableCell>
                                <TableCell sx={{ width: '50%', p: 0.5, pl: 0 }}>
                                    <TextField fullWidth size="small" name={setting.uid} variant="outlined" autoComplete="off" 
                                        value={props.action.settings?.find(item => item.uid === setting.uid)?.value ?? ''} 
                                        onChange={(e) => { handleChangeSetting(e.target.name, 'value', e.target.value) }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <TuneIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Grid>   
            }

        </Grid>
    )
}

export default AutomationCreateAction
