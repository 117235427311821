import React from 'react';
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { EscapeSlug } from '../../../utility/Text';
import { ConvertToInt } from '../../../utility/Convert';

// Modely
import { CustomField, CustomFieldTranslation, Language, ItemType } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Switch, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SortIcon from '@mui/icons-material/Sort';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): CustomField => ({
    id: 0,
    actived: true,
    tag: '',
    ordinalNumber: 0,
    required: false,
    languages: false,
    translations: [],
});

// Vstupné parametre
export interface CustomFieldCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const CustomFieldCreate = (props: CustomFieldCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<CustomField>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [itemTypes, setItemTypes] = useState<ItemType[]>([]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Načítam zoznam agénd
    const loadItemTypes = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'itemtype')
            .then(response => {
                setItemTypes(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadItemTypes(), []);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customfield/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as CustomField);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: CustomFieldTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if ((source.itemTypeId ?? 0) === 0) {
            setWarning({ ...EmptyWarning, name: 'itemTypeId', text: 'Musíte vybrať agendu!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'customfield', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Vlastné pole' : 'Nové vlastné pole')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={4}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívne" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControlLabel control={<Switch checked={source.required} name="required" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label='Povinné' />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControlLabel control={<Switch checked={source.languages} name="languages" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label='Jazyky' />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="customergroup-create-agend">Agenda *</InputLabel>
                                    <Select required labelId="customergroup-create-agend" label="Agenda *" value={source.itemTypeId ?? 0} name="itemTypeId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        disabled={(source.id ?? 0) > 0}
                                        inputRef={input => props.autoFocus === 'agend' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Žiadna</MenuItem>
                                        {itemTypes?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="tag" label="Kľúč (značka)" fullWidth variant="outlined" autoComplete="off" value={source.tag ?? ''}
                                    onChange={(e) => { handleChange(e.target.name, EscapeSlug(e.target.value)) }}
                                    inputRef={input => (props.autoFocus === 'tag' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Na mieste, kde bude vložená táto značka (v hranatých zátvorkách) sa zobrazí vlastný text.">
                                                    <LocalOfferIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {languages.map((language, index) => {
                                if (!source.languages && index > 0) {
                                    return null;
                                }
                                return (
                                    <React.Fragment key={language.id}>
                                        <Grid item xs={8}>
                                            <TextField required margin="dense" name='name' label={'Názov' + (source.languages ? ' (' + language.code + ')' : '')} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => index === 0 && props.autoFocus === 'name' && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Tento názov bude zobrazený ako popisok užívateľského pola (vstupné textové pole).">
                                                                <EditIcon className="formIconHelp" />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField margin="dense" name='default' label={'Predvolená hodnota' + (source.languages ? ' (' + language.code + ')' : '')} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => index === 0 && props.autoFocus === 'default' && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.default ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Tento text bude použitý ako predvolená hodnota pola pri novom zázname.">
                                                                <EditIcon className="formIconHelp" />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}

                            {(source.id ?? 0) === 0 &&
                                <Grid item xs={12} mt={1}>
                                    <Alert severity="warning">
                                        Pozor! Agendu je možné vybrať iba pri vytváraní nového pola a teda po vytvorení už nebude možné zmeniť agendu.
                                    </Alert>
                                </Grid>
                            }

                            {(source.tag?.length ?? 0) > 0 &&
                                <Grid item xs={12} mt={1}>
                                    <Alert severity="info">
                                        Pre zobrazenie textu z poľa je potrebné na požadované podporované miesto vložiť kľúč (značku) v hranatých zátvorkách: <strong>[{source.tag}]</strong>.
                                        Pozor, systém môže obsahovať rovnaký kľúč pre zobrazenie systémového textu.
                                        Text vlastného poľa má ale vyššiu prioritu a preto bude v prípade zhody uprednostnený.
                                    </Alert>
                                </Grid>
                            }
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default CustomFieldCreate;