import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../../utility/Convert';

// Modely
import { PriceList } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Switch, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CustomFieldValues from '../custom_field/CustomFieldValues';

// Ikony
import PercentIcon from '@mui/icons-material/Percent';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FunctionsIcon from '@mui/icons-material/Functions';
import EuroIcon from '@mui/icons-material/Euro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): PriceList => ({
    id: 0,
    actived: true,
    name: '',
    // minimalPrice: 0,
    // discount: 0,
    highlightPriceNoVat: false,
    allowVoucher: true,
    allowVolumeDiscount: true,
    discounts: []
});

// Vstupné parametre
export interface PriceListCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const PriceListCreate = (props: PriceListCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<PriceList>(EmptySource());

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'pricelist/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as PriceList);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeDiscounts = (index: number, property: string, value: any) => {
        setSource((p) => {
            const changed = p.discounts ?? [];
            changed[index] = { ...changed[index], [property]: value };
            return { ...p, discounts: changed };
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'pricelist', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Cenník' : 'Nový cenník')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth margin="dense" name="discount" type="text" label="Zľava (%)" variant="outlined" autoComplete="off" value={source.discount ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'discount' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Ak produkt neobsahuje konkrétnu cenu pre tento cenník, tak bude táto zľava aplikovaná na základnú cenu produktu.">
                                                    <PercentIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth margin="dense" name="minimalPrice" type="text" label="Minimálna suma objednávky" variant="outlined" autoComplete="off" value={source.minimalPrice ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'minimalPrice' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Suma (vrátane DPH) za ktorú musí zákazník nakúpiť, aby mohol dokončiť objednávku.">
                                                    <EuroIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('discounts')} onChange={() => handleToggleAccordion('discounts')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Množstevné zľavy  ({(source.discounts?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(source.discounts?.length ?? 0) > 0 &&
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Od množstva</TableCell>
                                                        <TableCell>Zľava %</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {source.discounts?.map((discount, index) => (
                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                <TextField required fullWidth size="small" name="quantity" type="text" variant="outlined" autoComplete="off" value={discount.quantity ?? ''}
                                                                    onChange={(e) => { handleChangeDiscounts(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Tooltip title="Množstvo od ktorého je uplatnená zľava">
                                                                                    <FunctionsIcon className="formIconHelp" />
                                                                                </Tooltip>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                <TextField required fullWidth size="small" name="discount" type="text" variant="outlined" autoComplete="off" value={discount.discount ?? ''}
                                                                    onChange={(e) => { handleChangeDiscounts(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Tooltip title="Zľava, ktorá je uplatnená po dosiahnutí zadaného množstva">
                                                                                    <PercentIcon className="formIconHelp" />
                                                                                </Tooltip>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                <IconButton aria-label="Vymazať" size="small" onClick={() => setSource((p) => ({ ...p, discounts: p.discounts?.filter(item => item !== discount) ?? [] }))}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        }
                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => setSource((p) => ({ ...p, discounts: [...p.discounts ?? [], { quantity: 0 }] }))}>Pridať zľavu</Button>
                                        <Alert severity="warning" sx={{ mt: 1 }}>
                                            Ak je pri produkte nastavená akákoľvek množstevná zľava (bez ohľadu na vybraný cenník), tak pre daný produkt neplatia žiadne iné nastavenia množstevných zliav.
                                            V tomto prípade je potrebné nastaviť všetky požadované množstevné zľavy pre všetky cenníky priamo pri produkte (nastavenia z cenníkov sú ignorované).
                                        </Alert>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('settings')} onChange={() => handleToggleAccordion('settings')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Nastavenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControlLabel control={<Switch checked={source.highlightPriceNoVat} name="highlightPriceNoVat" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Zvýrazniť cenu bez DPH" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControlLabel control={<Switch checked={source.allowVoucher} name="allowVoucher" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Povoliť použitie poukážky" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControlLabel control={<Switch checked={source.allowVolumeDiscount} name="allowVolumeDiscount" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Povoliť objemové zľavy" />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <CustomFieldValues itemTypeId={8} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default PriceListCreate;