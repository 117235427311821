import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { TemplateEmail, TemplateEmailTranslation, Language, File } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, Typography, Table, IconButton, TableBody, TableCell, TableRow, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Iframe from '../../../components/Iframe';
import ContentTagsDialog, { ContentTagsDialogProps } from '../content_tag/ContentTagsDialog';
import TinyMCEDialog, { TinyMCEDialogProps } from '../../tinymce/TinyMCEDialog';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TabContext, TabPanel } from '@mui/lab';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): TemplateEmail => ({
    id: 0,
    name: '',
    translations: [],
    files: []
});

// Predpis pre argumenty TinyMCE
interface TinyMCEArgument {
    languageId: number;
    property: string;
}

// Vstupné parametre
export interface TemplateEmailCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const TemplateEmailCreate = (props: TemplateEmailCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<TemplateEmail>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [tabLanguageValue, setTabLanguageValue] = useState<string>('0');
    const [contentTagsDialog, setContentTagsDialog] = useState<ContentTagsDialogProps>({
        ids: [],
        open: false,
        onClose: () => handleContentTagsClose()
    });
    const [tinyMCEDialog, setTinyMCEDialog] = useState<TinyMCEDialogProps>({
        open: false,
        content: '',
        onSave: (content: string, argument: TinyMCEArgument) => {
            handleChangeTranslation(argument.languageId, argument.property, content);
            handleTinymceClose();
        },
        onClose: () => handleTinymceClose()
    });
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {
            if (files.length > 0) {
                setSource(prev => ({
                    ...prev,
                    files: [...prev.files ?? [], ...files.map(file => { return { fileId: file.id, name: file.name, fileName: file.fileName } })]
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Zobrazenie okna značiek
    const handleContentTagsOpen = () => {
        setContentTagsDialog(prev => ({ ...prev, open: true }));
    };
    const handleContentTagsClose = () => {
        setContentTagsDialog(prev => ({ ...prev, open: false }));
    };

    // Zobrazenie HTML editoru
    const handleTinymceOpen = (argument: TinyMCEArgument, title: string, content: string) => {
        setTinyMCEDialog(prev => ({ ...prev, open: true, title: title, argument: argument, content: content, contentTagsIds: [] }));
    };
    const handleTinymceClose = () => {
        setTinyMCEDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        if ((props.id ?? 0) === 0) {
            setSource(EmptySource());
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'templateemail/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as TemplateEmail);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: TemplateEmailTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Vloženie vzorovej šablóny
    const handleDefaultTemplate = (languageId: number) => {
        axios
            .get(AppConfig.TemplatesUri + 'email.html')
            .then(response => {
                handleChangeTranslation(languageId, 'template', response.data);
            });
    };

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'templateemail', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <FilesDialog {...filesDialog} />
            <ContentTagsDialog {...contentTagsDialog} />
            <TinyMCEDialog {...tinyMCEDialog} />

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Šablóna pre email' : 'Nová šablóna pre email')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Názov šablóny - interný údaj, nikde sa nezobrazuje.">
                                                    <EditIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <React.Fragment key={language.id}>
                                        <Grid item xs={12}>
                                            <TextField required margin="dense" name="subject" label={'Predmet emailu (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => index === 0 && props.autoFocus === 'subject' && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.subject ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <EditIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}

                            <Grid item xs={12}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabLanguageValue} onChange={(e, v) => setTabLanguageValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                        {languages.map((language, index) => <Tab key={language.id} value={index.toString()} label={'Náhľad (' + language.code + ')'} />)}
                                    </Tabs>
                                </Box>
                                <TabContext value={tabLanguageValue}>
                                    {languages.map((language, index) => {
                                        return (
                                            <TabPanel value={index.toString()} sx={{ px: '0', pb: 0, pt: 1 }} key={language.id}>
                                                <Grid container columnSpacing={1}>
                                                    <Grid item xs={12} sx={{ height: '400px' }}>
                                                        <Iframe title={'Šablóna (' + language.code + ')'} content={source.translations?.find(item => item.languageId === language.id)?.template ?? ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleDefaultTemplate(language.id ?? 0)}>Vložiť vzor</Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }}
                                                            onClick={() => {
                                                                handleTinymceOpen({
                                                                    languageId: language.id ?? 0,
                                                                    property: 'template'
                                                                },
                                                                    'Úprava šablóny (' + language.code + ')',
                                                                    source.translations?.find(item => item.languageId === language.id)?.template ?? '');
                                                            }}>Upraviť</Button>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                        )
                                    })}
                                </TabContext>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="info" action={<Button variant="contained" color="primary" size="small" onClick={handleContentTagsOpen}>Značky</Button>}>
                                    V obsahu šablóny ale aj v parametroch (napr. predmet) je možné použiť značky pre vloženie dynamických premenných (napr. meno, dátum...).
                                    V obsahu šablóny je možné použiť značku s podmienkou napr.: <strong>[paid(Uhradená)]</strong> alebo <strong>[paid(Neuhradená)!]</strong>.
                                    Ak obsah značky nie je prázdny, tak sa zobrazí text v zátvorke. Ak je za zátvorkou výkričník, tak platí opačná podmienka.
                                </Alert>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Súbory v prílohe</Typography>
                                {(source.files?.length ?? 0) > 0 &&
                                    <Table size="small">
                                        <TableBody>
                                            {source.files?.map((file, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>
                                                        {file.name}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                        <IconButton aria-label="Vymazať" size="small" onClick={() => setSource((p) => ({ ...p, files: p.files?.filter(item => item !== file) ?? [] }))}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                }
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={handleFilesOpen}>Pridať súbor</Button>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default TemplateEmailCreate;