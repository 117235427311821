import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { StockAvailability } from '../../models/Models';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, Grid, Paper } from '@mui/material';
import ButtonList, { Item } from '../../components/ButtonList';

// Ikony
import FlagIcon from '@mui/icons-material/Flag';

export interface ProductsAvailabilityProps {
    open: boolean;
    stockAvailabilities?: StockAvailability[];
    keepMounted?: boolean;
    onSelect: (id: number) => void;
    onClose: () => void;
}

const ProductsAvailability = (props: ProductsAvailabilityProps) => {

    const [stockAvailabilities, setStockAvailabilities] = useState<StockAvailability[]>([]);
    const [items, setItems] = useState<Item[]>([]);

    // Načítam zoznam stavov objednávky
    const loadStockAvailabilities = () => {
        axios
            .get(AppConfig.ApiUri + 'stockavailability')
            .then(response => {
                setStockAvailabilities(response.data);
            });
    };

    // Načítam zoznam stavov, ak nefigurujú na vstupe
    useEffect(() => {
        if (props.stockAvailabilities !== undefined) {
            setStockAvailabilities(props.stockAvailabilities);
        } else {
            loadStockAvailabilities();
        }
    }, [props.stockAvailabilities]);

    // Vygenerujem zoznam tlačidiel pre výber stavu
    useEffect(() => {
        const items: Item[] = [];
        stockAvailabilities.forEach((state, index) => {
            items.push({
                key: state.id,
                title: state.other?.translationName ?? '-',
                icon: <FlagIcon htmlColor={'#' + state.color} />,
                divider: index < (stockAvailabilities.length - 1)
            });
        });
        setItems(items);
    }, [stockAvailabilities]);

    return (
        <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => props.onClose()}>
            <Box>
                <DialogTitle>Zmena dostupnosti</DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} mt={1}>
                            <Paper>
                                <ButtonList activedKey={undefined} items={items ?? []} onClick={(e) => {
                                    props.onSelect(e.key as number);
                                    props.onClose();
                                }} />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Späť</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ProductsAvailability;