import React, { useState } from 'react';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { DocumentType, DocumentLite, DocumentRelated, DocumentRelatedLoad, ExternalDocument } from '../../models/Models';

// Komponenty
import { Button, Grid, IconButton, ListItemIcon, ListSubheader, Menu, MenuItem, Table, TableBody, TableCell, TableRow } from '@mui/material';
import DocumentsDialog, { DocumentsDialogProps } from '../document/DocumentsDialog';
import ReportDocument, { ReportDocumentProps } from '../report/ReportDocument';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

// Pomocné parametre pre callback výberu dokladov
interface DocumentsDialogArgument {
    documentType?: DocumentType;
}

// Vstupné parametre
export interface DocumentCreateRelatedProps {
    source: ExternalDocument;
    documentTypes: DocumentType[];
    setSource: (action: React.SetStateAction<ExternalDocument>) => void;
}

const DocumentCreateRelated = (props: DocumentCreateRelatedProps) => {

    // Stav
    const [addDocumentEl, setAddDocumentEl] = useState<null | HTMLElement>(null);

    // Zoznam dokladov
    const [documentsDialog, setDocumentsDialog] = useState<DocumentsDialogProps>({
        open: false,
        keepMounted: false,
        onSelect: (documents: DocumentLite[], argument: DocumentsDialogArgument) => {
            if (documents.length > 0) {
                props.setSource(prev => {
                    const changed: DocumentRelated[] = [...prev.relatedDocuments ?? []];
                    documents.forEach(document => {
                        if (changed.find(item => item.id === document.id) === undefined && document.id !== props.source.id) {
                            changed.push(DocumentRelatedLoad(document, argument.documentType?.id ?? 0, argument.documentType?.other?.translationName ?? ''));
                        }
                    });
                    return ({ ...prev, relatedDocuments: changed });
                });
            }
            handleDocumentsClose();
        },
        onClose: () => handleDocumentsClose()
    });

    // Zobrazenie dokladov
    const handleDocumentsOpen = (documentType: DocumentType, argument: DocumentsDialogArgument) => {
        setDocumentsDialog(prev => ({ ...prev, type: documentType.id ?? 0, argument: argument, open: true }));
    };
    const handleDocumentsClose = () => {
        setDocumentsDialog(prev => ({ ...prev, open: false }));
    };

    // Vybrať doklad
    const handleSelectDocument = (documentType: DocumentType) => {
        handleDocumentsOpen(documentType, {
            documentType: documentType
        });
    };

    // Tlač dokladu
    const [documentPrint, setReportDocument] = useState<ReportDocumentProps>({
        documentId: 0,
        documentTypeId: 0,
        open: false,
        preview: true,
        disableHtml: true,
        keepMounted: true,
        onClose: () => setReportDocument(prev => ({ ...prev, open: false }))
    });

    // Vygenerovať nový alebo stiahnuť nahraný doklad
    const handlePrint = (id: number, typeId: number) => {
        setReportDocument(prev => ({ ...prev, documentId: id, documentTypeId: typeId, open: true }));
    };

    return (
        <>
            <Menu id="menu-adddocument" anchorEl={addDocumentEl} open={Boolean(addDocumentEl)} onClose={() => setAddDocumentEl(null)}>
                {props.documentTypes.find(item => item.sale === true) !== undefined && <ListSubheader component="div" disableSticky sx={{ maxHeight: '20px', lineHeight: '20px', background: 'none', fontSize: 'small' }}>Predaj</ListSubheader>}
                {props.documentTypes.filter(item => item.sale === true)?.map((item, index) =>
                    <MenuItem key={item.id ?? 0} onClick={() => { handleSelectDocument(item); setAddDocumentEl(null); }}><ListItemIcon><DescriptionIcon fontSize="small" /></ListItemIcon> {item.other?.translationName}</MenuItem>
                )}
                {props.documentTypes.find(item => item.sale === false) !== undefined && <ListSubheader component="div" disableSticky sx={{ maxHeight: '20px', lineHeight: '20px', background: 'none', fontSize: 'small' }}>Nákup</ListSubheader>}
                {props.documentTypes.filter(item => item.sale === false)?.map((item, index) =>
                    <MenuItem key={item.id ?? 0} onClick={() => {  handleSelectDocument(item); setAddDocumentEl(null); }}><ListItemIcon><DescriptionOutlinedIcon fontSize="small" /></ListItemIcon> {item.other?.translationName}</MenuItem>
                )}
            </Menu>

            <Grid container columnSpacing={1}>

                <Grid item xs={12}>
                    <DocumentCreateRelatedList
                        source={props.source.relatedDocuments}
                        onPrint={(document) => handlePrint(document.id ?? 0, document.typeId ?? 0)}
                        onDelete={(document) => props.setSource((p) => ({ ...p, relatedDocuments: p.relatedDocuments?.filter(item => item !== document) ?? [] }))}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button size="small" variant="contained" startIcon={<ExpandMoreIcon />} color="secondary" sx={{ mt: 1, ml: 1 }} aria-label="Vybrať doklad" aria-controls="menu-adddocument" aria-haspopup="true" onClick={(e) => { setAddDocumentEl(e.currentTarget); }}>Vybrať</Button>
                </Grid>

            </Grid>

            {/* Tlač dokladu */}
            <ReportDocument {...documentPrint} />

            {/* Výber dokladov */}
            <DocumentsDialog {...documentsDialog} types={props.documentTypes} />

        </>
    )
}

export default DocumentCreateRelated;

interface DocumentCreateRelatedListProps {
    source?: DocumentRelated[];
    onPrint: (document: DocumentRelated) => void;
    onDelete: (document: DocumentRelated) => void;
}

const DocumentCreateRelatedList = (props: DocumentCreateRelatedListProps) => {
    return (
        <>
            {(props.source?.length ?? 0) > 0 &&
                <Table size="small">
                    <TableBody>
                        {props.source?.map((document, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    <strong>{document.number}</strong> - {document.typeName} {document.paid === true && '(uhradené)'}
                                </TableCell>
                                <TableCell style={{ padding: 0, width: '150px', textAlign: 'right' }}>
                                    <IconButton aria-label="Vytlačiť" size="small" onClick={() => props.onPrint(document)}>
                                        <PrintIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="Upraviť" size="small" href={AppRouteUrl.DOCUMENTS + (document.typeId ?? 0).toString() + '/?id=' + (document.id ?? 0).toString()} target="_blank">
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="Vymazať" size="small" onClick={() => props.onDelete(document)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </>
    )
};