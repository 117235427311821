import React from 'react'

interface BlankProps {
    title?: string;
}

const Blank = (props: BlankProps) => {
  return (
    <div>
        Stránka <strong>{props.title?.toLowerCase()}</strong> v príprave...
    </div>
  )
}

export default Blank;