import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Automation, AutomationTriggerType, AutomationActionType, Domain, AutomationRule, AutomationAction } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import AutomationCreateRule from './AutomationCreateRule';
import AutomationCreateAction from './AutomationCreateAction';
import ContentTagsDialog, { ContentTagsDialogProps } from '../content_tag/ContentTagsDialog';

// Ikony
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Automation => ({
    id: 0,
    actived: true,
    name: '',
    trigger: 0,
    domains: [],
    rules: [],
    actions: []
});

// Vstupné parametre
export interface AutomationCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const AutomationCreate = (props: AutomationCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Automation>(EmptySource());
    const [automationTriggerTypes, setAutomationTriggerTypes] = useState<AutomationTriggerType[]>([]);
    const [automationActionTypes, setAutomationActionTypes] = useState<AutomationActionType[]>([]);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [contentTagsDialog, setContentTagsDialog] = useState<ContentTagsDialogProps>({
        ids: [],
        open: false,
        onClose: () => handleContentTagsClose()
    });

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'automation/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Automation);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zobrazenie okna značiek
    const handleContentTagsOpen = () => {
        setContentTagsDialog(prev => ({ ...prev, open: true }));
    };
    const handleContentTagsClose = () => {
        setContentTagsDialog(prev => ({ ...prev, open: false }));
    };

    // Načítam zoznam typov spúšťačov
    const loadAutomationTriggerTypes = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'automationtriggertype')
            .then(response => {
                setAutomationTriggerTypes(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadAutomationTriggerTypes(), []);

    // Načítam zoznam typov pravidiel
    const loadAutomationActionTypes = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'automationactiontype')
            .then(response => {
                setAutomationActionTypes(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadAutomationActionTypes(), []);

    // Načítam zoznam domén
    const loadDomains = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDomains(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeRule = (index: number, changed: AutomationRule) => {
        setSource(prev => ({
            ...prev, rules: [
                ...prev.rules?.slice(0, index) ?? [],
                changed,
                ...prev.rules?.slice(index + 1) ?? [],
            ]
        }));
    }
    const handleChangeAction = (index: number, changed: AutomationAction) => {
        setSource(prev => ({
            ...prev, actions: [
                ...prev.actions?.slice(0, index) ?? [],
                changed,
                ...prev.actions?.slice(index + 1) ?? [],
            ]
        }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if ((source.trigger ?? 0) === 0) {
            setWarning({ ...EmptyWarning, name: 'trigger', text: 'Musíte vybrať spúšťač automatickej služby!' });
            return;
        }
        if ((source.actions?.filter(item => (item?.type ?? 0) > 0)?.length ?? 0) === 0) {
            setWarning({ ...EmptyWarning, name: 'actions', text: 'Musíte nastaviť minimálne jednu akciu pre automatickú službu!' });
            return;
        }

        // Získam dostupné parametre pre pravidlá
        const triggerSettings = automationTriggerTypes.find(trigger => trigger.id === source.trigger)?.settings ?? [];

        // Ponechám len pravidlá, ktoré sú platné pre daný typ (ak som zmenil typ, tak pole zostalo naplnené starými hodnotami) a akcie, ktoré majú nastavený typ akcie
        source.rules = source.rules?.filter(item => triggerSettings.find(setting => setting.uid === item.name) !== undefined) ?? [];
        source.actions = source.actions?.filter(item => (item?.type ?? 0) > 0) ?? [];

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'automation', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <ContentTagsDialog {...contentTagsDialog} />
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Automatická služba' : 'Nová automatická služba')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="automation-create-trigger">Spúšťač *</InputLabel>
                                    <Select required labelId="automation-create-trigger" label="Spúšťač *" value={source.trigger ?? 0} name="trigger" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'trigger' && setFocus(input)}>
                                        <MenuItem value={0}>Žiadny</MenuItem>
                                        {automationTriggerTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="automation-create-domain">Domény</InputLabel>
                                    <Select multiple labelId="automation-create-domain" label="Domény" name="domains" value={source.domains ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'domains' && setFocus(input)}>
                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Alert severity="info" action={<Button variant="contained" color="primary" size="small" onClick={handleContentTagsOpen}>Značky</Button>}>
                                    V hodnotách pravidiel, ale aj v parametroch akcií (napr. email príjemcu) je možné použiť značky pre vloženie dynamických premenných.
                                    Dynamická premenná môže obsahovať rôzne údaje ako aktuálny dátum, údaje z dokladu a podobne.
                                </Alert>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="info">
                                    Pri obmedzeniach (napr. domény) platí pravidlo, že ak je nie je označená žiadna možnosť, tak záznam platí pre všetko.
                                </Alert>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Pravidlá</Typography>
                            </Grid>

                            {(source.rules?.length ?? 0) > 1 &&
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        Pre viacero pravidiel platí podmienka "a súčasne" (and). Aby bola vykonaná akcia musia byť preto splnené všetky podmienky.
                                        Alternatívou pre podmienku "alebo" (or) je vytvorenie viacerých samostatných automatických služieb.
                                        Ak nie je nastavené žiadne pravidlo, tak bude akcia vykonaná vždy po spúšťačom.
                                    </Alert>
                                </Grid>
                            }

                            {(source.rules?.length ?? 0) > 0 &&
                                <Grid item xs={12}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Parameter</TableCell>
                                                <TableCell>Podmienka</TableCell>
                                                <TableCell>Hodnota</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {source.rules?.map((rule, index) => (
                                                <AutomationCreateRule
                                                    key={index}
                                                    rule={rule} trigger={automationTriggerTypes.find(trigger => trigger.id === source.trigger) ?? {}}
                                                    onDelete={() => setSource((p) => ({ ...p, rules: p.rules?.filter(item => item !== rule) ?? [] }))}
                                                    onChange={(changed) => { handleChangeRule(index, changed) }} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => setSource((p) => ({ ...p, rules: [...p.rules ?? [], { condition: 0, name: '', value: '' }] }))}>Pridať pravidlo</Button>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Akcie</Typography>
                            </Grid>

                            {source.actions?.map((action, index) => (
                                <Grid key={index} item xs={12} mt={1}>
                                    <Paper elevation={3} sx={{ p: 2 }}>
                                        <AutomationCreateAction
                                            key={index}
                                            action={action}
                                            actionTypes={automationActionTypes}
                                            onDelete={() => setSource((p) => ({ ...p, actions: p.actions?.filter(item => item !== action) ?? [] }))}
                                            onChange={(changed) => { handleChangeAction(index, changed) }} />
                                    </Paper>
                                </Grid>
                            ))}

                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => setSource((p) => ({ ...p, actions: [...p.actions ?? [], { type: 0, settings: [] }] }))}>Pridať akciu</Button>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default AutomationCreate;