import { Fragment, useEffect, useState } from 'react'

//Ikony
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Elementy
import { Checkbox, Collapse, Divider, IconButton, List, ListItemButton, useTheme } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Predpis pre položku menu
export interface Item {
    key: any;                   // Argument (napríklad ID záznamu a podobne)
    title: string;              // Titulok tlačidla
    icon?: JSX.Element;         // Ikona
    items?: Item[];             // Podpoložky (rekurzívne zobrazenie položiek)
    divider?: boolean;          // Zobrazí oddelovač za položkou
    style?: ItemStyle;          // Štýl položky
    onClickMore?: (e: HTMLElement, item: Item) => void;  // Funkcia pre vyvolanie kontextového menu (ak je nastavená, tak sa zobrazí aj ikona pre vyvolanie)
}

// Štýl položky
export enum ItemStyle {
    Disabled
}

// Pre uloženie stavu otváracieho menu podľa indexu položky
interface ItemOpen {
    index: number;
    open: boolean;
}

// Vstupné parametre pre položku listu
interface ButtonListItemProps {
    item: Item;                     // Položka
    active?: boolean;               // Aktívna položka (pre zvýraznenie)
    selected?: boolean;             // Označená položka (zaškrtávače)
    level?: number;                 // Úroveň vnoreného listu (doplní sa automaticky od ďalšej úrovne)
    checklist?: boolean;            // Zobraziť zaškrtávacie políčka
    onClick?: (item: Item) => void; // Udalosť po kliknutí
    onClickMore?: (e: HTMLElement, item: Item) => void;     // Ak tlačidlo obsahuje menu, tak sa zobrazí ikona pre rozbalenie menu
    onSelectionChanged?: (key: any) => void;                // Udalosť po zmene označenia
}

// Jedna položka menu (tlačidlo)
const ButtonListItem = (props: ButtonListItemProps) => {

    // Zmena označenia
    const handleChecked = (key: any) => {
        if (props.onSelectionChanged) {
            props.onSelectionChanged(key);
        }
    }

    return (
        <ListItemButton sx={{ opacity: (props.item.style === ItemStyle.Disabled ? 0.4 : 1) }}
            onClick={() => {
                if (props?.onClick) {
                    props?.onClick(props.item)
                }
            }}>
            <ListItemIcon title={props.item.title}>
                {(props.checklist ?? false) === false || props.item.key === 0 ? props.item.icon :
                    <Checkbox checked={props.selected ?? false} sx={{ m: 0, p: 0 }}
                        onChange={(e) => {
                            handleChecked(props.item.key);
                        }} />}
            </ListItemIcon>
            <ListItemText primary={props.item.title}
                sx={{ marginLeft: '-18px', maxHeight: '25px' }}
                primaryTypographyProps={{
                    noWrap: true, fontWeight: (props.active ?? false ? 'bold' : 'noset')
                }} />
            {(props.onClickMore !== undefined) && (
                <IconButton edge="end" aria-label="Možnosti" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (props.onClickMore !== undefined) {
                        props?.onClickMore(e.currentTarget, props.item);
                    }
                }}>
                    <MoreVertIcon sx={{ fontSize: 'small' }} />
                </IconButton>
            )}
        </ListItemButton>
    )
}

// Vstupné parametre pre list
interface ButtonListProps {
    items: Item[];                  // Položky navigácie
    activedKey: any | undefined;    // Aktívny kľúč pre zvýraznenie tlačidla (ak sa zmení na "undefined" tak sa zbalia všetky tlačidlá rovnako ako keď sa klikne na tlačidlo s key=0)
    selectedKeys?: any[];           // Kľúče označených tlačidiel
    level?: number;                 // Úroveň vnoreného listu (doplní sa automaticky od ďalšej úrovne)
    dense?: boolean;                // Zúžené zobrazenie (napr. pre podpoložky)
    checklist?: boolean;            // Zobraziť zaškrtávacie políčka
    onClick?: (item: Item) => void;                 // Udalosť po kliknutí
    onSelectionChanged?: (keys: any[]) => void;     // Udalosť po zmene označenia
}

// Zoznam položiek menu
const ButtonList = (props: ButtonListProps) => {
    const theme = useTheme();

    // Overenie, či položka alebo jej podpoložky obsahujú aktívny kľúč
    const isActiveItem = (item: Item): boolean => {
        if (item.key === props.activedKey)
            return true;
        var result = false;
        if (item.items?.length) {
            for (var i = 0, max = item.items.length; i < max; i++) {
                if (isActiveItem(item.items[i])) {
                    result = true;
                    break;
                }
            }
        }
        return result;
    };

    // Pre získanie stavu elementov podľa indexu na základe aktívneho klúča
    const getOpenByActive = () => {
        var openByActive: ItemOpen[] = [];
        props.items.forEach((item, index) => {
            if (item.items?.length && isActiveItem(item)) {
                openByActive.push({ index: index, open: true });
            }
        });
        return openByActive;
    }

    // Stav elementov podľa indexu
    const [open, setOpen] = useState<ItemOpen[]>([]);

    // Zmena stavu skupiny
    const handleOpen = (index: number) => {
        setOpen(prev => {
            const isOpen = prev.find(item => item.index === index)?.open ?? false;
            return [...prev.filter(item => item.index !== index), { index: index, open: !isOpen }]
        });
    };

    // Kliknutie na položku
    const handleClick = (item: Item) => {
        // Ak kliknem na "všetko", tak zatvorim celé menu
        if (item.key === 0) {
            setOpen([]);
        }
        // Vyvolám onClick z parametrov
        if (props?.onClick) {
            props?.onClick(item);
        }
    };

    // Ak sa zmení activeKey tak rozbalím alebo zbalím celý zoznam
    useEffect(() => {
        // Ak sa zmení activeKey na "undefined", tak zatvorim celé menu
        if (props.activedKey === undefined && open.length > 0) {
            setOpen([]);
            return;
        }

        // Ak je nastavené aktívne ID, ale nič nie je otvorené, tak automaticky otvorim zoznam podľa aktívneho ID
        if ((props.activedKey ?? 0) > 0 && props.items.length > 0 && open.length === 0) {
            var openByActive = getOpenByActive();
            if (openByActive.length > 0) {
                setOpen(openByActive);
            }
        }
    }, [props.activedKey, props.items]); // eslint-disable-line react-hooks/exhaustive-deps

    // Označenie položky
    const handleChecked = (key: any) => {
        if (props.onSelectionChanged) {
            if (props?.selectedKeys?.includes(key)) {
                props.onSelectionChanged([...props?.selectedKeys?.filter(k => k !== key)]);
            }
            else {
                props.onSelectionChanged([...(props?.selectedKeys ?? []), key]);
            }
        }
    }

    return (
        <List dense={props.dense ?? false}>
            {props.items.map((item, index) => (
                ((item?.items?.length ?? 0) === 0 ?
                    <Fragment key={index}>
                        <ButtonListItem item={item} active={item.key === props.activedKey} selected={(props?.selectedKeys?.includes(item.key))} level={(props.level ?? 0)} checklist={props.checklist} onClickMore={item.onClickMore} onClick={handleClick} onSelectionChanged={handleChecked} />
                        {item.divider === true ? <Divider /> : null}
                    </Fragment>
                    :
                    <Fragment key={index}>
                        <ListItemButton sx={{ opacity: (item.style === ItemStyle.Disabled ? 0.4 : 1) }}
                            onClick={(e) => {
                                if (!(open.find(item => item.index === index)?.open ?? false)) {
                                    handleOpen(index);
                                }
                                handleClick(item);
                            }}>
                            <ListItemIcon title={item.title}>
                                {(props.checklist ?? false) === false ? item.icon : <Checkbox checked={(props?.selectedKeys?.includes(item.key))} sx={{ m: 0, p: 0 }} onChange={(e) => { handleChecked(item.key); }} />}
                            </ListItemIcon>
                            <ListItemText sx={{ marginLeft: '-18px', maxHeight: '25px' }} primaryTypographyProps={{ noWrap: true, fontWeight: (item.key === props.activedKey ? 'bold' : 'noset') }} >
                                {item.title}
                            </ListItemText>
                            <IconButton edge="end" aria-label="Možnosti" sx={{ marginRight: '-6px' }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleOpen(index);
                            }}>
                                {(open.find(item => item.index === index)?.open ?? false) ? <ExpandLess sx={{ fontSize: 'small', color: 'silver' }} /> : <ExpandMore sx={{ fontSize: 'small', color: 'silver' }} />}
                            </IconButton>
                            {(item.onClickMore !== undefined) && (
                                <IconButton edge="end" aria-label="Možnosti" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (item.onClickMore !== undefined) {
                                        item?.onClickMore(e.currentTarget, item);
                                    }
                                }}>
                                    <MoreVertIcon sx={{ fontSize: 'small' }} />
                                </IconButton>
                            )}
                        </ListItemButton>
                        <Collapse className={'buttonlist-level buttonlist-level-' + (props.level ?? 0)} in={(open.find(item => item.index === index)?.open ?? false)} sx={{ position: 'relative', backgroundColor: theme.layout.sidebar.collapse.backgroundColor }} timeout="auto" unmountOnExit>
                            <ButtonList items={item.items ?? []} activedKey={props.activedKey} selectedKeys={props.selectedKeys} dense={true} level={(props.level ?? 0) + 1} checklist={props.checklist} onClick={handleClick} onSelectionChanged={props.onSelectionChanged} />
                        </Collapse>
                        {item.divider === true ? <Divider /> : null}
                    </Fragment>
                )
            ))}
        </List>
    )
}

export default ButtonList;