import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { TemplateSms, TemplateSmsTranslation, Language } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ContentTagsDialog, { ContentTagsDialogProps } from '../content_tag/ContentTagsDialog';

// Ikony
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): TemplateSms => ({
    id: 0,
    name: '',
    translations: []
});

// Vstupné parametre
export interface TemplateSmsCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const TemplateSmsCreate = (props: TemplateSmsCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<TemplateSms>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [contentTagsDialog, setContentTagsDialog] = useState<ContentTagsDialogProps>({
        ids: [],
        open: false,
        onClose: () => handleContentTagsClose()
    });

    // Zobrazenie okna značiek
    const handleContentTagsOpen = () => {
        setContentTagsDialog(prev => ({ ...prev, open: true }));
    };
    const handleContentTagsClose = () => {
        setContentTagsDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'templatesms/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as TemplateSms);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: TemplateSmsTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'templatesms', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <ContentTagsDialog {...contentTagsDialog} />

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Šablóna pre SMS' : 'Nová šablóna pre SMS')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Názov šablóny - interný údaj, nikde sa nezobrazuje.">
                                                    <EditIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} my={1}>
                                <Alert severity="info" action={<Button variant="contained" color="primary" size="small" onClick={handleContentTagsOpen}>Značky</Button>}>
                                    V obsahu šablóny je možné použiť značky pre vloženie dynamických textov.
                                    Dynamický text môže obsahovať rôzne údaje ako aktuálny dátum, údaje z dokladu a podobne.
                                </Alert>
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <Grid key={language.id} item xs={12}>
                                        <TextField required fullWidth multiline rows={5} margin="dense" name="template" label={'Obsah šablóny (' + language.code + '), znakov: ' + (source.translations?.find(item => item.languageId === language.id)?.template?.length ?? 0)} variant="outlined" autoComplete="off"
                                            value={source.translations?.find(item => item.languageId === language.id)?.template ?? ''}
                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                            inputRef={input => index === 0 && props.autoFocus === 'template' && setFocus(input)}
                                            inputProps={{ maxLength: 1000 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EditIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                )
                            })}

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default TemplateSmsCreate;