import React, { useState } from 'react';

// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { Parameter } from '../../models/Models';

// Komponenty
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, FormControl, IconButton, InputAdornment, InputLabel, Link, MenuItem, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import ParameterItemsDialog, { ParameterItemsDialogProps } from '../settings/parameter_item/ParameterItemsDialog';
import Search from '../../components/Search';
import { TabContext, TabPanel } from '@mui/lab';

// Utility
import { SearchInText } from '../../utility/Text';

// Ikony
import SettingsIcon from '@mui/icons-material/Settings';
import ExtensionIcon from '@mui/icons-material/Extension';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppRouteUrl } from '../../AppRoutes';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateParameterProps extends ProductCreateComponentProps {
    parameters: Parameter[];
    onParameterItemsChanged?: () => void; // Udalosť pre oznámenie zmeny položiek niektorého z parametrov (obnovím zoznam)
};

const ProductCreateParameter = (props: ProductCreateParameterProps) => {

    // Stav
    const [tabPropertiesValue, setTabPropertiesValue] = useState<string>('0');
    const [parameterItemsDialog, setParameterItemsDialog] = useState<ParameterItemsDialogProps>({
        open: false,
        parameterId: 0,
        onChanged: props.onParameterItemsChanged,
        onClose: () => handleParameterItemsClose()
    });

    // Zobrazenie hodnôt parametru
    const handleParameterItemsOpen = (Id: number, Name?: string) => {
        setParameterItemsDialog(prev => ({ ...prev, parameterId: Id, parameterName: Name, open: true }));
    };
    const handleParameterItemsClose = () => {
        setParameterItemsDialog(prev => ({ ...prev, open: false }));
    };

    // Zlúči id položiek parametra s id položiek ostatných parametrov tak, že najskôr premaže všetky id položiek parametra a potom pridá nové
    const handleParameterValues = (parameterId: number, values: number[]) => {
        const items = props.parameters.find(item => item.id === parameterId)?.items?.map(item => item.id ?? 0) ?? [];
        const parameters = [...(props.source.parameters?.filter(item => !items.includes(item)) ?? []), ...values.filter(item => item > 0)];
        props.onChange('parameters', parameters);
    };

    // Pridať, upraviť alebo vymazať charakteristiku
    const handlePropertiesAdd = (id: number) => {
        const properties = [...props.source.translations?.find(item => item.languageId === id)?.properties ?? [], { name: '', value: '' }];
        props.onChangeTranslation(id, 'properties', properties);
    }
    const handlePropertiesDelete = (id: number, index: number) => {
        const properties = props.source.translations?.find(item => item.languageId === id)?.properties ?? [];
        props.onChangeTranslation(id, 'properties', properties.filter(item => item !== properties[index]) ?? []);

    }
    const handlePropertiesChange = (id: number, index: number, property: string, value: string) => {
        const properties = [...props.source.translations?.find(item => item.languageId === id)?.properties ?? []];
        properties[index] = { ...properties[index], [property]: value };
        props.onChangeTranslation(id, 'properties', properties);
    }

    // Vráti vybrané id položiek, ktoré patria pod daný parameter
    const getParameterValues = (parameterId: number): number[] => {
        return props.parameters.find(item => item.id === parameterId)?.items?.filter(item => props.source.parameters?.includes(item.id ?? 0))?.map(item => item.id ?? 0) ?? [];
    };

    // Filtrácia
    const [searchVariant, setSearchVariant] = useState<string>('');
    const [searchProperties, setSearchProperties] = useState<string>('');
    const [searchConfigurator, setSearchConfigurator] = useState<string>('');

    return (
        <>

            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('variant')} onChange={() => { props.onToggleAccordion('variant') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Variant</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>

                        <Grid item xs={12}>
                            <Alert severity="info" sx={{ mb: 1 }}>
                                Pre každý parameter, ktorý označuje variantu daného produktu je možné vybrať jednu z možností. <Link href={AppRouteUrl.PRODUCT_PARAMETERS} target="_blank">Pridať nové parametre</Link> je možné v časti nastavenia.
                            </Alert>
                        </Grid>

                        {(props.source.model?.length ?? 0) === 0 && (
                            <Grid item xs={12}>
                                <Alert severity="warning" sx={{ mb: 1 }}>
                                    Pozor! Pre správnu funkčnosť výberu varianty je potrebné vyplniť párovací kód / označenie modelu pre zoskupenie.
                                    Všetky produkty s rovnakým označením budú vzájomne zoskupené.
                                </Alert>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <TextField margin="dense" name="model" label="Párovací kód modelu (označenie pre zoskupenie)" fullWidth variant="outlined" autoComplete="off" value={props.source.model} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                inputRef={input => props.autoFocus === 'model' && props.setFocus(input)}
                                inputProps={{ maxLength: 64 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Jedinečné označenie modelu, podľa ktorého budú produkty zoskupené pre výber varianty.">
                                                <ExtensionIcon className="formIconHelp" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} sx={{ my: 1 }}>
                            <Search text={searchVariant} onSearch={s => setSearchVariant(s)} onClear={() => { setSearchVariant(''); }} autoFocus={false} />
                        </Grid>

                        {props.parameters?.filter(item => item.actived && item.type === 1 && (searchVariant === '' || SearchInText(searchVariant, item.other?.translationName ?? '') === true)).map((parameter, item) => (
                            <React.Fragment key={parameter.id}>
                                <Grid item xs={12} md={6} sx={{ display: 'inline-flex' }}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id={'product-parameter-' + parameter.id}>{parameter.other?.translationName + (parameter.required === true ? ' *' : '')}</InputLabel>
                                        <Select required={parameter.required ?? false} labelId={'product-parameter-' + parameter.id} label={parameter.other?.translationName + (parameter.required === true ? ' *' : '')} name="parameters"
                                            value={getParameterValues(parameter.id ?? 0)[0] ?? 0}
                                            IconComponent={() => null}
                                            endAdornment={<IconButton size="small" onClick={(e) => handleParameterItemsOpen(parameter.id ?? 0, parameter.other?.translationName)}>
                                                <SettingsIcon fontSize="inherit" />
                                            </IconButton>}
                                            onChange={(e) => handleParameterValues(parameter.id ?? 0, [e.target.value] as number[])}>
                                            <MenuItem value={0}>-</MenuItem>
                                            {parameter.items?.filter(item => item.actived).map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </React.Fragment>
                        ))}

                    </Grid>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('properties')} onChange={() => { props.onToggleAccordion('properties') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Vlastnosti</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>

                        <Grid item xs={12}>
                            <Alert severity="info" sx={{ mb: 1 }}>
                                Pre každý parameter je možné označiť všetky položky, ktoré charakterizujú vlastnosti daného produktu. <Link href={AppRouteUrl.PRODUCT_PARAMETERS} target="_blank">Pridať nové parametre</Link> je možné v časti nastavenia.
                            </Alert>
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} sx={{ my: 1 }}>
                            <Search text={searchProperties} onSearch={s => setSearchProperties(s)} onClear={() => { setSearchProperties(''); }} autoFocus={false} />
                        </Grid>

                        {props.parameters?.filter(item => item.actived && item.type === 0 && (searchProperties === '' || SearchInText(searchProperties, item.other?.translationName ?? '') === true)).map((parameter, item) => (
                            <React.Fragment key={parameter.id}>
                                <Grid item xs={12} md={6} sx={{ display: 'inline-flex' }}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id={'product-parameter-' + parameter.id}>{parameter.other?.translationName + (parameter.required === true ? ' *' : '')}</InputLabel>
                                        <Select required={parameter.required ?? false} multiple labelId={'product-parameter-' + parameter.id} label={parameter.other?.translationName + (parameter.required === true ? ' *' : '')} name="parameters"
                                            value={getParameterValues(parameter.id ?? 0)}
                                            IconComponent={() => null}
                                            endAdornment={<IconButton size="small" onClick={(e) => handleParameterItemsOpen(parameter.id ?? 0, parameter.other?.translationName)}>
                                                <SettingsIcon fontSize="inherit" />
                                            </IconButton>}
                                            onChange={(e) => handleParameterValues(parameter.id ?? 0, e.target.value as number[])}>
                                            {parameter.items?.filter(item => item.actived).map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>



            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('parameters')} onChange={() => { props.onToggleAccordion('parameters') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Charakteristika</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Alert severity="info">
                        Charakteristika predstavuje jednoduché textové vlastnosti produktu, pre ktoré nie je potrebné vytvárať číselníky hodnôt ako pri parametroch vlastností.
                        Väčšinou sú to informácie, pre ktoré nie sú potrebné špeciálne funkcie ako napríklad zobrazenie vo filtri a majú len informatívny charakter.
                    </Alert>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabPropertiesValue} onChange={(e, v) => setTabPropertiesValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                            {props.languages.map((language, index) => <Tab key={language.id} value={index.toString()} label={language.code} />)}
                        </Tabs>
                    </Box>

                    <TabContext value={tabPropertiesValue}>
                        {props.languages.map((language, index) => {
                            return (
                                <TabPanel value={index.toString()} sx={{ px: '0', pb: 0 }} key={language.id}>
                                    <Grid container columnSpacing={1}>
                                        <Grid item xs={12}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Názov</TableCell>
                                                        <TableCell>Hodnota</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {props.source.translations?.find(item => item.languageId === language.id)?.properties?.map((property, index) => (
                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                <TextField required fullWidth size="small" name="name" variant="outlined" autoComplete="off" value={property.name ?? ''}
                                                                    onChange={(e) => handlePropertiesChange(language.id ?? 0, index, e.target.name, e.target.value)}
                                                                    inputProps={{ maxLength: 255 }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <EditIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                <TextField required fullWidth size="small" name="value" variant="outlined" autoComplete="off" value={property.value ?? ''}
                                                                    onChange={(e) => handlePropertiesChange(language.id ?? 0, index, e.target.name, e.target.value)}
                                                                    inputProps={{ maxLength: 255 }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <EditIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                <IconButton aria-label="Vymazať" size="small" onClick={() => handlePropertiesDelete(language.id ?? 0, index)}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handlePropertiesAdd(language.id ?? 0)}>Pridať riadok</Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            )
                        })}
                    </TabContext>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('configurator')} onChange={() => { props.onToggleAccordion('configurator') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Konfigurátor</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={12}>
                            <Alert severity="info" sx={{ mb: 1 }}>
                                Pre každý parameter je možné označiť položky, ktoré budú slúžiť ako výber pre upresnenie konfigurácie daného produktu.
                                Hodnoty parametrov môžu byť v rámci konfigurátora spoplatnené. Spoplatnené parametre budú automaticky pripočítané k jednotkovej cene produktu.
                                Takto je možné spoplatniť napríklad voliteľné príslušenstvo, výbavu, materiál a podobne. <Link href={AppRouteUrl.PRODUCT_PARAMETERS} target="_blank">Pridať nové parametre</Link> je možné v časti nastavenia.
                            </Alert>
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} sx={{ my: 1 }}>
                            <Search text={searchConfigurator} onSearch={s => setSearchConfigurator(s)} onClear={() => { setSearchConfigurator(''); }} autoFocus={false} />
                        </Grid>

                        {props.parameters?.filter(item => item.actived && (item.type === 2 || item.type === 3) && (searchConfigurator === '' || SearchInText(searchConfigurator, item.other?.translationName ?? '') === true)).map((parameter, item) => (
                            <React.Fragment key={parameter.id}>
                                <Grid item xs={12} md={6} sx={{ display: 'inline-flex' }}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id={'product-parameter-' + parameter.id}>{parameter.other?.translationName + (parameter.required === true ? ' *' : '')}</InputLabel>
                                        <Select required={parameter.required ?? false} multiple labelId={'product-parameter-' + parameter.id} label={parameter.other?.translationName + (parameter.required === true ? ' *' : '')} name="parameters"
                                            IconComponent={() => null}
                                            endAdornment={<IconButton size="small" onClick={(e) => handleParameterItemsOpen(parameter.id ?? 0, parameter.other?.translationName)}>
                                                <SettingsIcon fontSize="inherit" />
                                            </IconButton>}
                                            value={getParameterValues(parameter.id ?? 0)}
                                            onChange={(e) => handleParameterValues(parameter.id ?? 0, e.target.value as number[])}>
                                            {parameter.items?.filter(item => item.actived).map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>


            {/* Hodnoty parametru */}
            <ParameterItemsDialog {...parameterItemsDialog} />

        </>
    )
};

export default ProductCreateParameter;