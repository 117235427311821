import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToInt } from '../../../utility/Convert';

// Modely
import { NumberSequence, DocumentType } from '../../../models/Models';

// Komponenty
import { Alert, AlertTitle, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, Tooltip, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import NumbersIcon from '@mui/icons-material/Numbers';
import EventIcon from '@mui/icons-material/Event';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): NumberSequence => ({
    id: 0,
    documentTypeId: 0,
    year: new Date().getFullYear(),
    lastNumber: 0,
    template: 'RRCCCCC'
});

// Vstupné parametre
export interface NumberSequenceCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const NumberSequenceCreate = (props: NumberSequenceCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<NumberSequence>(EmptySource());
    const [sourceDocumentType, setSourceDocumentType] = useState<DocumentType[]>([]);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if(!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'numbersequence/' + props.id)
            .then(response => {
                if(response.data !== null) {
                    if(props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as NumberSequence);
                }
            })
            .finally(() => {
                setLoading(false);
            }); 
    }, [props.id, props.copy, props.open]); 

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam typov
    const loadDocumentTypes = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'documenttype')
            .then(response => {
                setSourceDocumentType(response.data);
            })
            .finally(() => {
                setLoading(false);
            }); 
    };
    useEffect(() => loadDocumentTypes(), []);

    // Zmeny vo formulári
    const handleChange = (property:string, value:any) => {   
        setSource(prev => ({ ...prev, [property] : value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => { 
        if(warning.text.length > 0) {
            setWarning(EmptyWarning);
        }        
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if(source.documentTypeId === 0) {
            setWarning({ ...EmptyWarning, name: 'documentTypeId', text: 'Musíte vybrať druh dokladu!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'numbersequence', source)
            .then(response => {
                if(response.data > 0) {
                    if(props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                } 
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            }); 
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if(focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);
    
    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick') { props.onClose(); }}}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        { ((props?.id ?? 0) > 0 ? 'Číslovanie dokladu' : 'Nové číslovanie dokladu') }
                        { (props.copy && ' (kópia)') }
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="numbersequence-create-documenttype">Druh dokladu *</InputLabel>
                                    <Select required labelId="numbersequence-create-documenttype" label="Druh dokladu *" value={source.documentTypeId ?? 0} name="documentTypeId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'documentTypeId' && setFocus(input)}>
                                        <MenuItem value={0}>Žiadny</MenuItem>
                                        { sourceDocumentType?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''} {(item.sale ?? false) === true ? ' - predaj' : ' - nákup'}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="year" type="number" label="Rok" variant="outlined" autoComplete="off" value={source.year ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }} 
                                    inputRef={input => props.autoFocus === 'year' && setFocus(input)}
                                    inputProps={{ maxLength: 18, min: 2000, max: 2200, step: '1' }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Rok pre ktorý je toto číslovanie platné.">
                                                    <EventIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            
                            <Grid item xs={12} sm={4}>
                                <TextField required margin="dense" name="template" label="Šablóna (formát čísla)" fullWidth variant="outlined" autoComplete="off" value={source.template ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'template' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 32 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Šablóna podľa ktorej sa číslo naformátuje. V šablóne je možné použiť nasledovné značky: CCCC, RR/RRRR, M/MM/MMM, D/DD/DDD, UU/UUU.">
                                                    <NumbersIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="lastNumber" type="number" label="Posledné použité číslo" variant="outlined" autoComplete="off" value={source.lastNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }} 
                                    inputRef={input => props.autoFocus === 'lastNumber' && setFocus(input)}
                                    inputProps={{ maxLength: 18, min: 0, max: 999999, step: '1' }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Posledné použité číslo na doklade. Číslo na novom doklade bude o jedno väčšie.">
                                                    <NumbersIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="info">
                                    <AlertTitle>Formátovacie značky</AlertTitle>
                                    <strong>CCCCC</strong> - poradové číslo (ľubovoľná dĺžka), napr.: 00001.<br />
                                    <strong>RR</strong> - skrátený rok, napríklad: 21.<br />
                                    <strong>RRRR</strong> - celý rok, napríklad: 2021.<br />
                                    <strong>M</strong> - skrátený mesiac, napríklad: 9.<br />
                                    <strong>MM</strong> - celý mesiac, napríklad: 09.<br />
                                    <strong>MMM</strong> - slovná skratka mesiaca, napríklad: SEP.<br />
                                    <strong>D</strong> - skrátený deň, napríklad: 1.<br />
                                    <strong>DD</strong> - celý deň, napríklad: 01.<br />
                                    <strong>DDD</strong> - slovná skratka mesiaca, napríklad: PON
                                </Alert>
                            </Grid>

                        </Grid>
                        
                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={ warning.color as AlertColor }>{warning.text}</Alert>)}  

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default NumberSequenceCreate;