import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { DocumentType, DocumentTypeTranslation, DocumentTypeTypes, Language } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import SortIcon from '@mui/icons-material/Sort';
import TodayIcon from '@mui/icons-material/Today';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): DocumentType => ({
    id: 0,
    system: false,
    type: 0,
    actived: true,
    sale: true,
    ordinalNumber: 0,
    fillDateOfIssue: -1,
    fillDateOfTaxLiability: -1,
    fillDateOfDue: -1,
    fillDateOfDelivery: -1,
    fillDateOfExpiration: -1,
    hideDateOfIssue: false,
    hideDateOfTaxLiability: false,
    hideDateOfDue: false,
    hideDateOfDelivery: false,
    hideDateOfExpiration: false,
    hideTrackingNumber: false,
    hideSymbols: false,
    hidePayments: false,
    hideDocuments: false,
    hideNotes: false,
    hideFiles: false,
    translations: []
});

// Vstupné parametre
export interface DocumentTypeCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const DocumentTypeCreate = (props: DocumentTypeCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<DocumentType>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'documenttype/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                        response.data.system = false;
                    }
                    setSource(response.data as DocumentType);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: DocumentTypeTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.id === 0) {
            source.system = false;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'documenttype', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Druh dokladu' : 'Nový druh dokladu')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }} alignItems="center">

                            <Grid item xs={12} md={5}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <FormControlLabel control={<Switch checked={source.sale} disabled={source.system ?? false} name="sale" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label='Predajný doklad' />
                            </Grid>

                            <Grid item xs={12} my={1}>
                                <Alert severity="info">
                                    {source.sale === true ?
                                        'Na predajnom doklade figuruje druhá strana ako odberateľ.' :
                                        'Na nákupnom doklade figuruje druhá strana ako dodávateľ.'}
                                </Alert>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="documenttype-create-type">Typ</InputLabel>
                                    <Select disabled required labelId="documenttype-create-type" label="Typ" value={source.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        {DocumentTypeTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? 0} onChange={(e) => { handleChange(e.target.name, Number.parseInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <Grid key={language.id} item xs={12}>
                                        <TextField required margin="dense" name='name' label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                            inputRef={input => index === 0 && (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                            value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                            inputProps={{ maxLength: 255 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EditIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('fill')} onChange={() => handleToggleAccordion('fill')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Automaticky doplniť údaje</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={(source.fillDateOfIssue ?? 0) >= 0} name="fillDateOfIssue" onChange={(e) => { handleChange(e.target.name, e.target.checked === false ? -1 : 0) }} />} label="Dátum vystavenia" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={(source.fillDateOfDue ?? 0) >= 0} name="fillDateOfDue" onChange={(e) => { handleChange(e.target.name, e.target.checked === false ? -1 : 0) }} />} label="Dátum splatnosti" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={(source.fillDateOfTaxLiability ?? 0) >= 0} name="fillDateOfTaxLiability" onChange={(e) => { handleChange(e.target.name, e.target.checked === false ? -1 : 0) }} />} label="Dátum daňovej povinnosti" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={(source.fillDateOfDelivery ?? 0) >= 0} name="fillDateOfDelivery" onChange={(e) => { handleChange(e.target.name, e.target.checked === false ? -1 : 0) }} />} label="Dátum dodania" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={(source.fillDateOfExpiration ?? 0) >= 0} name="fillDateOfExpiration" onChange={(e) => { handleChange(e.target.name, e.target.checked === false ? -1 : 0) }} />} label="Dátum platnosti" />
                                            </Grid>

                                            <Grid item xs={12} my={1}>
                                                <Alert severity="info">Pri vytvorení nového dokladu budú automaticky pred vyplnené označené dátumy aktuálnym dátumom.</Alert>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('date')} onChange={() => handleToggleAccordion('date')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Automaticky posunúť dátum</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} md={6}>
                                                <TextField fullWidth margin="dense" name="fillDateOfIssue" type="number" label="Dátum vystavenia" variant="outlined" value={(source.fillDateOfIssue ?? 0) >= 0 ? source.fillDateOfIssue : ''} onChange={(e) => { handleChange(e.target.name, e.target.value.length > 0 ? Number.parseInt(e.target.value) : -1) }}
                                                    inputRef={input => props.autoFocus === 'fillDateOfIssue' && setFocus(input)}
                                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TodayIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField fullWidth margin="dense" name="fillDateOfDue" type="number" label="Dátum splatnosti" variant="outlined" value={(source.fillDateOfDue ?? 0) >= 0 ? source.fillDateOfDue : ''} onChange={(e) => { handleChange(e.target.name, e.target.value.length > 0 ? Number.parseInt(e.target.value) : -1) }}
                                                    inputRef={input => props.autoFocus === 'fillDateOfDue' && setFocus(input)}
                                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TodayIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField fullWidth margin="dense" name="fillDateOfTaxLiability" type="number" label="Dátum daňovej povinnosti" variant="outlined" value={(source.fillDateOfTaxLiability ?? 0) >= 0 ? source.fillDateOfTaxLiability : ''} onChange={(e) => { handleChange(e.target.name, e.target.value.length > 0 ? Number.parseInt(e.target.value) : -1) }}
                                                    inputRef={input => props.autoFocus === 'fillDateOfTaxLiability' && setFocus(input)}
                                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TodayIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField fullWidth margin="dense" name="fillDateOfDelivery" type="number" label="Dátum dodania" variant="outlined" value={(source.fillDateOfDelivery ?? 0) >= 0 ? source.fillDateOfDelivery : ''} onChange={(e) => { handleChange(e.target.name, e.target.value.length > 0 ? Number.parseInt(e.target.value) : -1) }}
                                                    inputRef={input => props.autoFocus === 'fillDateOfDelivery' && setFocus(input)}
                                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TodayIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField fullWidth margin="dense" name="fillDateOfExpiration" type="number" label="Dátum platnosti" variant="outlined" value={(source.fillDateOfExpiration ?? 0) >= 0 ? source.fillDateOfExpiration : ''} onChange={(e) => { handleChange(e.target.name, e.target.value.length > 0 ? Number.parseInt(e.target.value) : -1) }}
                                                    inputRef={input => props.autoFocus === 'fillDateOfExpiration' && setFocus(input)}
                                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TodayIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} my={1}>
                                                <Alert severity="info">Aktuálny dátum bude pri automatickom vyplnení posunutý o nastavený počet dní (dnes + počet dní).</Alert>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('hide')} onChange={() => handleToggleAccordion('hide')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Skryť vybrané údaje</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideDateOfIssue} name="hideDateOfIssue" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Dátum vystavenia" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideDateOfDue} name="hideDateOfDue" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Dátum splatnosti" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideDateOfTaxLiability} name="hideDateOfTaxLiability" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Daňovej povinnosti" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideDateOfDelivery} name="hideDateOfDelivery" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Dátum dodania" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideDateOfExpiration} name="hideDateOfExpiration" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Dátum platnosti" />
                                            </Grid>

                                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideTrackingNumber} name="hideTrackingNumber" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Sledovacie číslo" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideSymbols} name="hideSymbols" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Symboly (VS, KS, ŠS)" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hidePayments} name="hidePayments" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Úhrady" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideDocuments} name="hideDocuments" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Relačné doklady" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideNotes} name="hideNotes" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Poznámky" />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.hideFiles} name="hideFiles" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Prílohy" />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id, type: prev.type, system: prev.system, sale: prev.sale }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default DocumentTypeCreate;