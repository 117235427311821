import React from 'react';

// Modely
import { AutomationTriggerType, AutomationRule, ConditionTypes } from '../../../models/Models';

// Komponenty
import { TableRow, TableCell, TextField, InputAdornment, IconButton, MenuItem, Select } from '@mui/material';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    trigger: AutomationTriggerType;
    rule: AutomationRule;
    onChange: (rule: AutomationRule) => void;
    onDelete: () => void;
}

const AutomationCreateRule = (props: Props) => {

    // Zmeny vo formulári
    const handleChange = (property:string, value:any) => {   
        props.onChange(({ ...props.rule, [property] : value }))
    }

    return (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ width: '25%', p: 0.5, pl: 0 }}>
                    <Select required fullWidth size="small" value={props.rule.name ?? '0'} name="name" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        {props.trigger?.id === undefined && <MenuItem value="">Vyberte spúšťač</MenuItem>}
                        {props.trigger.settings?.map(item => (<MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>)) }
                    </Select>
                </TableCell>
                <TableCell sx={{ width: '25%', p: 0.5, pl: 0 }}>
                    <Select required fullWidth size="small" value={props.rule.condition ?? 0} name="condition" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        { ConditionTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)) }
                    </Select>
                </TableCell>
                <TableCell sx={{ p: 0.5, pl: 0 }}>
                    <TextField required fullWidth size="small" name="value" value={props.rule.value ?? ''} variant="outlined" autoComplete="off" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }} 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EditIcon />
                                </InputAdornment>
                            ),
                        }} />
                </TableCell>
                <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                    <IconButton aria-label="Vymazať" size="small" onClick={props.onDelete}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </TableCell>
            </TableRow>
    )
}

export default AutomationCreateRule
