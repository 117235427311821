import React, { FormEvent, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Country, CustomerGroup, CustomersFilter, Domain, PriceList } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import NumbersIcon from '@mui/icons-material/Numbers';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface CustomersFilteringProps {
    open: boolean;
    filter?: CustomersFilter;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (filter: CustomersFilter) => void;
    onClose: () => void;
}

// Prázdny filter
const EmptyFilter: CustomersFilter = {
    page: 0,
    pageSize: 25,
    sort: 0
}

const CustomersFiltering = (props: CustomersFilteringProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);
    const [source, setSource] = useState<CustomersFilter>({ ...EmptyFilter });

    // Automaticky naplním zdroj
    useEffect(() => {
        setSource(props.filter ?? EmptyFilter);
    }, [props.filter, props.open]);
    
    // Načítam zoznam domén
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            });
    };
    useEffect(() => loadDomains(), []);

    // Načítam zoznam zákazníckych skupín
    const loadCustomerGroups = () => {
        axios
            .get(AppConfig.ApiUri + 'customergroup')
            .then(response => {
                setCustomerGroups(response.data);
            });
    };
    useEffect(() => loadCustomerGroups(), []);

    // Načítam zoznam cenníkov
    const loadPriceLists = () => {
        axios
            .get(AppConfig.ApiUri + 'pricelist')
            .then(response => {
                setPriceLists(response.data.filter((pricelist: PriceList) => pricelist.actived));
            });
    };
    useEffect(() => loadPriceLists(), []);

    // Načítam zoznam krajín
    const loadCountries = () => {
        axios
            .get(AppConfig.ApiUri + 'country')
            .then(response => {
                setCountries(response.data.filter((country: Country) => country.actived));
            });
    };
    useEffect(() => loadCountries(), []);

    // Zmeny vo formulári
    const handleChange = (property:string, value:any) => {   
        setSource(prev => ({ ...prev, [property] : value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => { 
        if(warning.text.length > 0) {
            setWarning(EmptyWarning);
        }        
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            page: 0,
            pageSize: prev.pageSize,
            sort: prev.sort
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if(focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);
    
    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick') { props.onClose(); }}}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Filter
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-domain">Doména</InputLabel>
                                    <Select labelId="filter-domain" label="Doména" value={source.domainId ?? 0} name="domainId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'domainId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { domains?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-customergroup">Zákaznícka skupina</InputLabel>
                                    <Select labelId="filter-customergroup" label="Zákaznícka skupina" value={source.customerGroupId ?? 0} name="customerGroupId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'customerGroupId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { customerGroups?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-pricelist">Cenník</InputLabel>
                                    <Select labelId="filter-pricelist" label="Cenník" value={source.priceListId ?? 0} name="priceListId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'priceListId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { priceLists?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-country">Krajina</InputLabel>
                                    <Select labelId="filter-country" label="Krajina" value={source.countryId ?? 0} name="countryId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'countryId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { countries?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-actived">Aktívny</InputLabel>
                                    <Select labelId="filter-actived" label="Aktívny" value={source.actived ?? 0} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'actived' && setFocus(input)}>
                                        <MenuItem value={0}>Všetko</MenuItem>
                                        <MenuItem value={1}>Áno</MenuItem>
                                        <MenuItem value={2}>Nie</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-person">Typ</InputLabel>
                                    <Select labelId="filter-person" label="Typ" value={source.person ?? 0} name="person" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'person' && setFocus(input)}>
                                        <MenuItem value={0}>Všetko</MenuItem>
                                        <MenuItem value={1}>Osoba</MenuItem>
                                        <MenuItem value={2}>Firma</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>
                            
                            <Grid item xs={12}>
                                <TextField margin="dense" name="email" label="Email" fullWidth variant="outlined" autoComplete="off" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'email') && setFocus(input)}
                                    inputProps={{ maxLength: 120 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AlternateEmailIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="firstname" label="Meno" fullWidth variant="outlined" autoComplete="off" value={source.firstname ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'firstname') && setFocus(input)}
                                    inputProps={{ maxLength: 35 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            
                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="lastname" label="Priezvisko" fullWidth variant="outlined" autoComplete="off" value={source.lastname ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'lastname') && setFocus(input)}
                                    inputProps={{ maxLength: 35 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            
                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="phone" label="Telefón" fullWidth variant="outlined" autoComplete="off" value={source.phone ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'phone') && setFocus(input)}
                                    inputProps={{ maxLength: 25 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>
                            
                            <Grid item xs={12}>
                                <TextField margin="dense" name="company" label="Názov firmy (spoločnosť)" fullWidth variant="outlined" autoComplete="off" value={source.company ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'company') && setFocus(input)}
                                    inputProps={{ maxLength: 50 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CorporateFareIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="regId" label="IČO" fullWidth variant="outlined" autoComplete="off" value={source.regId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'regId') && setFocus(input)}
                                    inputProps={{ maxLength: 15 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="taxId" label="DIČ" fullWidth variant="outlined" autoComplete="off" value={source.taxId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'taxId') && setFocus(input)}
                                    inputProps={{ maxLength: 18 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="vatId" label="IČDPH" fullWidth variant="outlined" autoComplete="off" value={source.vatId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'vatId') && setFocus(input)}
                                    inputProps={{ maxLength: 20 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={ warning.color as AlertColor }>{warning.text}</Alert>)}  

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Použiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default CustomersFiltering;