
// Vráti uložené natavenie alebo predvolenú hodnotu
export function LoadLocalSettings(name: string, valueDefault?: string): string {
    try {
        const value = localStorage.getItem(name);
        if (value !== null && value.length > 0) {
            return value;
        }
    }
    catch (error) { }
    return valueDefault ?? '';
}

// Uloží vybrané nastavenie (vráti 'true', ak bola hodnota iná ako akutálna) 
export function SaveLocalSettings(name: string, value: string, valueDefault?: string): boolean {
    // Ukladám len ak sa nastavenie zmenilo
    if (LoadLocalSettings(name, valueDefault) === value) {
        return false;
    }
    try {
        // Ak je hodnota rovnaká ako predvolená, tak nastavenie mažem
        if (valueDefault !== undefined && valueDefault === value) {
            localStorage.removeItem(name);
        }
        else {
            localStorage.setItem(name, value);
        }
    }
    catch (error) { }
    return true;
}
