import { FormEvent, useEffect, useState } from 'react'

// Modely
import { ParameterItem } from '../../../models/Models';

// Komponenty
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';

// Vstupné parametre
export interface ParameterItemsMergeProps {
    open: boolean;
    keepMounted?: boolean;
    items: ParameterItem[];
    onSave: (id: number, ids: number[]) => void;
    onClose: () => void;
}

const ParameterItemsMerge = (props: ParameterItemsMergeProps) => {

    // Stav
    const [id, setId] = useState<number>(0);

    // Predvolím prvý v poradí
    useEffect(() => setId((props.open === true && props.items.length > 0 ? (props.items[0].id ?? 0) : 0)), [props.open, props.items]);

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(id, props.items.map(item => item.id ?? 0));
        props.onClose();
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>Zlúčiť hodnoty</DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={12} mt={1}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="parameter-item-target">Zlúčiť do</InputLabel>
                                    <Select labelId="parameter-item-target" label="Zlúčiť do" value={id} name="fileDirectoryId" onChange={(e) => { setId(parseInt(e.target.value.toString())) }}>
                                        {props.items.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} mt={1}>
                                <Alert severity="info">Všetky označené hodnoty budú zlúčené do vybranej hodnoty.</Alert>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ParameterItemsMerge; 