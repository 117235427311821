import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Note, NoteColors } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, Box, IconButton } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import CloseIcon from '@mui/icons-material/Close';

// Vstupné parametre
export interface NoteNotificationsProps {
    forceRender?: any
}

// Komponent pre zoznam
const NoteNotifications = (props: NoteNotificationsProps) => {

    // Lokálny stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Tabuľka
    const [rows, setRows] = useState<Note[]>([]);

    // Načítam dáta ihneď po zobrazení kontrolu
    useEffect(() => {
        loadData();
        // Vykonám kontrolu každú minútu
        const interval = setInterval(() => {
            loadData();
        }, (1 * 60 * 1000));
        return () => clearInterval(interval);
    }, [props.forceRender]);

    // Deaktiovať záznam
    const handleDeactivate = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vypnúť zobrazenie notifikácie?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                axios
                    .post(AppConfig.ApiUri + 'note/deactivate/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    });
            }
        }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {        
        axios
            .get(AppConfig.ApiUri + 'note/notifications')
            .then(response => {
                setRows(response.data);
            });
    };

    const render = () => {
        return <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Zoznam */}
            <Box sx={{ position: 'fixed', width: '100%', bottom: '0px', left: '0px', zIndex: 'modal', padding: 1, maxHeight: '50%', overflowY: 'auto' }}>
            { 
                rows.map(item => 
                    {
                        return <Alert 
                                    key={item.id} 
                                    variant="filled" 
                                    icon={false}
                                    action={
                                        <>
                                            <IconButton
                                                aria-label="Vypnúť notifikáciu"
                                                color="inherit"
                                                size="small"
                                                onClick={() => handleDeactivate(item?.id ?? 0, item.text?.substring(0, 30) + '...') }
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        </>
                                    }
                                    sx={{
                                        mt: 1,
                                        backgroundColor: '#' + item.color,
                                        color: '#' + NoteColors.find(color => color.backgroundColor === item.color)?.textColor
                                    }}>
                                    <strong>{dateFormat(new Date(item.notificationDate ?? ''), 'dd.MM.yyyy HH:mm')}</strong> - {(item.text ?? '').split('\n')?.map(s => <>{s}<br /></>)}
                                </Alert>;
                    }
                )
            }
            </Box>
        </>
    }

    return (
        <>
            {rows.length > 0 && render() }
        </>
    )
}

export default NoteNotifications;