
// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { Category } from '../../models/Models';

// Komponenty
import { Button, FormControl, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import CategorySelect from '../category/CategorySelect';

// Ikony
import DeleteIcon from '@mui/icons-material/Delete';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateCategoryProps extends ProductCreateComponentProps {
    categories: Category[];             // Zoznam kategórii
    onCategoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
};

const ProductCreateCategory = (props: ProductCreateCategoryProps) => {

    const handleAddCategory = () => {
        props.onChange('categories', [...(props.source.categories ?? []), 0]);
    };

    const handleUpdateCategory = (index: number, value: number) => {
        const categories = (props.source.categories ?? []);
        categories[index] = value;
        props.onChange('categories', categories);
    };

    const handleDeleteCategory = (index: number) => {
        const categories = (props.source.categories ?? []);
        categories.splice(index, 1);
        props.onChange('categories', categories);
    };

    return (
        <>
            {props.source.categories?.map((category, index) => {
                return (
                    <Grid item xs={12} sx={{ display: 'inline-flex' }} key={index}>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <CategorySelect label={'Kategória č.' + (index + 1)} activeCategoryId={category} categories={props.categories} onSelect={(id: number) => handleUpdateCategory(index, id)} onCategoriesChanged={props.onCategoriesChanged} />
                        </FormControl>
                        <IconButton size="small" sx={{ p: '20px' }} onClick={(e) => handleDeleteCategory(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                )
            })}

            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleAddCategory()}>Pridať kategóriu</Button>
            </Grid>

        </>
    )
};

export default ProductCreateCategory;
