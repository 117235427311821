import { createContext } from 'react';

// Predpis pre globálne premenné
export interface AppContextModel {
    userName: string;
};

// Zabezpečuje prenos globálnych premenných, nachádza sa v najvyššej úrovni aplikácie
export const AppContext = createContext<AppContextModel>({
    userName: ''
});
