// Komponenty
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DocumentStates from './DocumentStates';

// Vstupné parametre
export interface DocumentStatesDialogProps {
    open: boolean;
    documentTypeId: number;
    documentTypeName?: string;
    argument?: any;             // Môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    onChanged?: () => void;     // Udalosť po zmene v zozname
    onClose?: () => void;
}

const DocumentStateDialog = (props: DocumentStatesDialogProps) => {
    return (
        <>
            <Dialog maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick' && props.onClose) { props.onClose(); }}}>
                <DialogTitle>
                    { props.documentTypeName !== undefined && props.documentTypeName.length > 0 ? props.documentTypeName : 'Stav dokladu' }
                </DialogTitle>
                <DialogContent>
                    <DocumentStates documentTypeId={props.documentTypeId} documentTypeName={props.documentTypeName} onChanged={props.onChanged} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} type="submit" variant="contained">Zavrieť</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DocumentStateDialog;