import { useContext, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import { AppContext } from '../../AppContext';
import axios from 'axios';

// Modely
import { Document, DocumentType, DocumentCopy, DocumentRelatedLoad } from '../../models/Models';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, Grid, Alert, Paper, Backdrop, CircularProgress, Typography } from '@mui/material';
import ButtonList, { Item } from '../../components/ButtonList';
import DocumentCreate, { DocumentCreateProps } from './DocumentCreate';

// Ikony
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

export interface DocumentsGenerateProps {
    documentIds: number[];                  // id dokladov pre zlúčenie
    documentTypes: DocumentType[];          // typy dokladov (číselník)
    generateParent?: boolean;               // generovať nadradený (predchádzajúci doklad) alebo podradený (nasledujúci doklad), predvolene sa generuje nasledujúci doklad
    open: boolean;
    keepMounted?: boolean;
    onClose: () => void;
}

const DocumentsGenerate = (props: DocumentsGenerateProps) => {

    // Globálne premenné
    const appContext = useContext(AppContext);
    
    // Funkcia pre pozastavenie vlákna
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    // Zoznam možností
    const [generateItems, setGenerateItems] = useState<Item[]>([]);
    const [generateItemsActiveKey, setGenerateItemsActiveKey] = useState<number | undefined>();

    // Vygenerujem zoznam pre výber
    useEffect(() => {
        if (props.documentTypes.length === 0) {
            return;
        }
        const items: Item[] = [];

        // Predaj
        const itemSale: Item = {
            key: -1,
            title: 'Predaj',
            icon: <DescriptionIcon />,
            divider: true,
            items: []
        };

        // Nákup
        const itemPurchase: Item = {
            key: -2,
            title: 'Nákup',
            icon: <DescriptionOutlinedIcon />,
            items: []
        };

        // Prejdem druhy dokladov a rozdelím podľa typu predaj/nákup
        props.documentTypes.forEach(item => {
            if (item.sale === true) {
                itemSale.items?.push({
                    key: item.id ?? 0,
                    title: (item.other?.translationName ?? 'Bez názvu'),
                    icon: <DescriptionIcon />,
                });
            }
            else {
                itemPurchase.items?.push({
                    key: item.id ?? 0,
                    title: (item.other?.translationName ?? 'Bez názvu'),
                    icon: <DescriptionOutlinedIcon />,
                });
            }
        });
        items.push(itemSale);
        items.push(itemPurchase);

        // Použijem zoznam možností
        setGenerateItems(items);
    }, [props.documentTypes]); // eslint-disable-line react-hooks/exhaustive-deps

    // Nový doklad
    const [documentCreate, setDocumentCreate] = useState<DocumentCreateProps>({
        documentType: {},
        documentTypes: [],
        open: false,
        keepMounted: true,
        disableSaveAction: true,
        onSave: (id?: number) => {
            if ((id ?? 0) > 0) {
                // ... ok
            }
        },
        onClose: () => setDocumentCreate(prev => ({ ...prev, open: false }))
    });

    // Pri zavrení okna, nastavím "undefined" a pri otvorení "0", aby sa automaticky zbalil zoznam tlačidiel
    useEffect(() => { setGenerateItemsActiveKey(props.open ? 0 : undefined); }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Preloader
    const [loadingList, setLoadingList] = useState<boolean>(false);
    const [loadingListText, setLoadingListText] = useState<string>('');

    // Vybraný druh dokladu a samotné doklady, ktoré sú načítané v samotstnom vlákne
    const [documentType, setDocumentType] = useState<DocumentType>()
    const [documentsToLoad, setDocumentsToLoad] = useState<number[]>();
    const [documentsLoaded, setDocumentsLoaded] = useState<Document[]>([]);

    // Funkcia pre generovanie dokladu
    const handleGenerate = (typeId: number) => {
        if (typeId <= 0) {
            return;
        }

        // Vyplním vybraný typ dokladu
        setDocumentType(props.documentTypes.find(item => item.id === typeId));

        // Vyčistím zoznam a vyplním pole id-čiek, aby sa zoznam začal načítavať vo vlastnom vlákne, na konci sa zobrazí okno dokladu
        setDocumentsLoaded([]);
        setDocumentsToLoad(props.documentIds);
    };

    // Automaticky získam ďalšiu položku po zmene (spracovanú odoberiem, až sa dostanem na koniec zoznamu)
    useEffect(() => { documentsToLoadCallback(); }, [documentsToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vždy keď sa vyvolá táto funcia, tak spracujem jednu položku a uložím nový stav aby sa funkcia zopakovala, na konci resetujem hodnotu a zavriem okno
    const documentsToLoadCallback = async () => {

        // Pokračujem, len ak zoznam ešte nie je vynulovaný
        if (documentsToLoad === undefined) {
            return;
        }

        // Ak som na konci zoznamu, tak vygenerujem súhrný doklad a zobrazím okno dokladu
        if (documentsToLoad.length === 0) {
            setDocumentsToLoad(undefined);
            setLoadingList(false);
            setLoadingListText('');

            // Vygenerujem spoločnú kópiu dokladov
            let document = DocumentCopy(documentsLoaded, appContext.userName ?? '');

            // Automaticky vyplním zoznam nadradených alebo podradených dokladov (väzba medzi dokladmi)
            if ((props.generateParent ?? false) === true) {
                // Generujem nadradené doklady, takže tie z ktorých vychádzam vložím ako podradené
                documentsLoaded.forEach(related => {
                    var relatedDocumentType = props.documentTypes.find(t => t.id === related.documentTypeId) ?? { };
                    document.relatedChildren?.push(DocumentRelatedLoad(related, relatedDocumentType.id ?? 0, relatedDocumentType?.other?.translationName ?? ''));
                });
            }
            else {
                // Generujem podradené doklady, takže tie z ktorých vychádzam vložím ako nadradené
                documentsLoaded.forEach(related => {
                    var relatedDocumentType = props.documentTypes.find(t => t.id === related.documentTypeId) ?? { };
                    document.relatedParents?.push(DocumentRelatedLoad(related, relatedDocumentType.id ?? 0, relatedDocumentType?.other?.translationName ?? ''));
                });
            }

            // Zmením typ dokladu
            document.documentTypeId = documentType?.id ?? 0;

            // Vynulujem stav dokladu, aby sa automaticky doplnil po naplnení číselníku stavov (mal by byť vynulovaný ale pre istotu)
            document.documentStateId = undefined;

            // Zobrazím formulár
            setDocumentCreate(prev => ({
                ...prev,
                open: true,
                document: document,
                documentType: documentType ?? {},
                documentTypes: props.documentTypes
            }));
            return;
        }

        // Zavriem okno
        if (props.open) {
            props.onClose();
        }

        // Ďalší produkt na spracovanie (podržím v pamäti)
        const documentId = documentsToLoad[0];

        // Získam kompletné triedy položiek a vložím do zoznamu
        setLoadingList(true);
        setLoadingListText('Spracovávam doklad ID: ' + (documentId ?? 0).toString());

        // Pozastavím vlákno, aby sa stihol aktualizovať stav nadradeného komponentu (zoznam zostávajúcich položiek)
        sleep(250);

        // Získam kompletnú triedu položky a vložím do zoznamu
        axios
            .get(AppConfig.ApiUri + 'document/' + documentId)
            .then(response => {
                if (response.data !== null) {
                    setDocumentsLoaded(prev => {
                        const loaded = response.data as Document;
                        if (prev.find(item => item.id === loaded.id) === undefined) {
                            prev.push(loaded);
                        }
                        return [...prev];
                    });
                }

                // Odstránim spracovanú položku a uložím stav aby sa spracoval ďalší v poradí
                setDocumentsToLoad(prev => {
                    var changed = [...(prev ?? [])];
                    if (changed.length > 0) {
                        changed.shift();
                    }
                    return changed;
                });
            });
    };

    return (
        <>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loadingList}>
                <CircularProgress color="inherit" />
                <Typography ml={2}>{loadingListText}</Typography>
            </Backdrop>

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => props.onClose()}>
                <Box>
                    <DialogTitle>
                        {(props.generateParent ?? false) === false ? 'Generovať nasledujúci doklad' : 'Generovať predchádzajúci doklad'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            {generateItems.length === 0 && (
                                <Grid item xs={12} mt={1}>
                                    <Alert severity="warning">Chybajú druhy dokladov</Alert>
                                </Grid>
                            )}
                            {generateItems.length > 0 && (
                                <Grid item xs={12} mt={1}>
                                    <Paper>
                                        <ButtonList activedKey={generateItemsActiveKey} items={generateItems ?? []} onClick={(e) => { handleGenerate(e.key as number); }} />
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>Späť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            {/* Formulár pre nový záznam */}
            <DocumentCreate {...documentCreate} documentTypes={props.documentTypes} />
        </>
    )
}

export default DocumentsGenerate;