import { useState } from 'react';

// Komponenty
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import Files from './Files';

// Modely
import { File } from '../../models/Models';

// Vstupné parametre
export interface FilesDialogProps {
    open: boolean;
    argument?: any; //môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    keepMounted?: boolean;
    directoryId?: number;       // Adresár do ktorého sa má nahrať súbor
    directoryType?: number;     // Typ adresáru do ktorého sa má nahrať súbor (systémové označenie napr. produkty)
    watermark?: boolean;        // Zapne alebo vypne (predvolené) automatickú aktiváciu vodoznak na nahraných fotkách
    resize?: boolean;           // Zapne (predvolené) alebo vypne automatické zmenšovanie fotiek
    resizeMaxSize?: number;     // Maximálna veľkosť pre automatické zmenšenie súboru (predvolené podľa nastavenia: AppConfig.FileUpload.ResizeMaxSize)
    onSelect?: (files: File[], argument?: any) => void;
    onClose?: () => void;
}

const FilesDialog = (props: FilesDialogProps) => {

    // Označené súbory
    const [selected, setSelected] = useState<File[]>([]);

    // Zmena výberu s možnosťou potvrdenia výberu
    const handleSelectionChanged = (files: File[], confirm: boolean) => { 
        if(confirm) {
            handleConfirm(files); // Pri potvrdení rovno odovzdám aktuálny zoznam, nemôžem čakať na zmenu stavu
        } else {
            setSelected(files); // Zmením aktuálny výber
        }
    };

    // Potvrdenie výberu (nepoužívam zoznam zo stavu keďže zmnena stavu sa môže dokončiť neskôr ako príde potvrdenie)
    const handleConfirm = (files: File[]) => {
        if(props?.onSelect) {
            props?.onSelect(files, props.argument);
        }
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick' && props.onClose) { props.onClose(); }}}>
                {/* <DialogTitle>Súbory</DialogTitle> */}
                <DialogContent sx={{ height: '87vh' }}>
                    <Files onSelectionChanged={handleSelectionChanged} watermark={props.watermark} resize={props.resize} resizeMaxSize={props.resizeMaxSize} directoryId={props.directoryId} directoryType={props.directoryType} />
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button onClick={props.onClose}>Späť</Button>
                    <Button onClick={() => handleConfirm(selected)} type="submit" variant="contained">Potvrdiť výber</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FilesDialog;