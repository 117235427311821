import React, { FormEvent, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { BillingInformationSettings, CustomerLite, CustomersFilter } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Content, ContentPaperScroll } from '../../../layout/Content';
import CustomersDialog, { CustomersDialogProps } from '../../customer/CustomersDialog';

// Utility
import { ConvertToInt } from '../../../utility/Convert';

// Ikony
import PersonIcon from '@mui/icons-material/Person';
import ContactsIcon from '@mui/icons-material/Contacts';
import { AppRouteUrl } from '../../../AppRoutes';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

const BillingInformations = () => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    // const [domains, setDomains] = useState<Domain[]>([]);
    const [settings, setSettings] = useState<BillingInformationSettings>({
        customers: []
    });

    // Výber existujúceho partnera
    const [customersDialog, setCustomersDialog] = useState<CustomersDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (customers: CustomerLite[], argument: any) => {
            if (customers.length > 0) {
                handleChangeSettingsCustomer(ConvertToInt(argument) ?? 0, customers[0].id ?? 0, (customers[0].person ?? false) === true ? (customers[0].firstname ?? '') + ' ' + (customers[0].lastname ?? '') : (customers[0].company ?? ''));
            }
            handleCustomersClose();
        },
        onClose: () => handleCustomersClose()
    });

    // Zobrazenie partnerov
    const handleCustomersOpen = (argument: any, filter: CustomersFilter) => {
        setCustomersDialog(prev => ({ ...prev, open: true, argument: argument, filter: filter }));
    };
    const handleCustomersClose = () => {
        setCustomersDialog(prev => ({ ...prev, open: false }));
    };

    // Načítam zoznam domén
    // const loadDomains = () => {
    //     setLoading(true);
    //     axios
    //         .get(AppConfig.ApiUri + 'domain')
    //         .then(response => {
    //             setDomains(response.data.filter((domain: Domain) => domain.actived));
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };
    // useEffect(() => loadDomains(), []);

    // Načítam nastavenia
    const loadSettings = () => {
        axios
            .get(AppConfig.ApiUri + 'billinginformation/settings')
            .then(response => {
                setSettings(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadSettings(), []);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uložím nastavenia
    const handleSaveSettings = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'billinginformation/settings', settings)
            .then(response => {
                if (response.data === true) {
                    setWarning({ ...EmptyWarning, color: 'success', text: 'Nastavenia boli uložené!' });
                }
                else {
                    setWarning({ ...EmptyWarning, color: 'error', text: 'Nastavenia sa nepodarilo uložiť!' });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Spracujem zmeny nastavení
    const handleChangeSettingsCustomer = (domainId: number, customerId: number, customerName: string) => {
        setSettings(prev => {
            return { ...prev, customers: [...prev?.customers?.filter(item => item.domainId !== domainId) ?? [], { domainId: domainId, customerId: customerId, customerName: customerName }] };
        });
    }

    return (
        <>
            {/* Obsah */}
            <Content fullHeight={true}>
                <ContentPaperScroll>
                    <Grid container columnSpacing={3} component="form" onSubmit={handleSaveSettings}>

                        <Grid item xs={12}>
                            <Typography variant="h4" component="div">Fakturačné údaje</Typography>
                            <Typography variant="body1" gutterBottom component="div">Zmena údajov nemá vplyv na už existujúce doklady.</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <TextField margin="dense" label="Hlavné fakturačné údaje" fullWidth variant="outlined" autoComplete="off" disabled onClick={() => handleCustomersOpen(0, { person: 2 })}
                                    value={settings.customers?.find(item => item.domainId === 0)?.customerName ?? ''}
                                    inputProps={{ style: { cursor: 'pointer' } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" size="small" startIcon={<ContactsIcon />} color="secondary" sx={{ mr: 1 }} onClick={() => handleCustomersOpen(0, { person: 2 })}>Vybrať</Button>
                            {(settings.customers?.find(item => item.domainId === 0)?.customerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mr: 1 }} href={AppRouteUrl.CUSTOMERS + '?id=' + (settings.customers?.find(item => item.domainId === 0)?.customerId ?? 0).toString()} target="_blank">Detail</Button>}
                            {(settings.customers?.find(item => item.domainId === 0)?.customerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mr: 1 }} onClick={() => { handleChangeSettingsCustomer(0, 0, ''); }}>Zrušiť prepojenie</Button>}
                        </Grid>

                        {/* -------------------------------------------------------------------- */}
                        {/* Fakturačné údaje podľa domény - pripravené, nepoužíva sa  */}
                        {/* -------------------------------------------------------------------- */}
                        {/* 
                        <Grid item xs={12} mt={4}>
                            <Typography variant="h4" component="div">Podľa domény</Typography>
                            <Typography variant="body1" gutterBottom component="div">
                                Fakturačné údaje podľa domény sa štandardne nepoužívajú, keďže interné doklady neumožňujú evidenciu zmiešaných účtovných jednotiek.<br />
                                Preto je možné fakturačné údaje podľa domény použiť iba v rámci externých dokladov (napr. pre integráciu systému a podobne).
                            </Typography>
                        </Grid>
                        {domains.map(domain =>
                            <React.Fragment key={domain.id}>
                                <Grid item xs={12}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <TextField margin="dense" label={'Fakturačné údaje pre: ' + domain.name} fullWidth variant="outlined" autoComplete="off" disabled onClick={() => handleCustomersOpen(domain.id, { person: 2 })}
                                            value={settings.customers?.find(item => item.domainId === domain.id)?.customerName ?? ''}
                                            inputProps={{ style: { cursor: 'pointer' } }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <PersonIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <Button variant="contained" size="small" startIcon={<ContactsIcon />} color="secondary" sx={{ mr: 1 }} onClick={() => handleCustomersOpen(domain.id, { person: 2 })}>Vybrať</Button>
                                    {(settings.customers?.find(item => item.domainId === domain.id)?.customerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mr: 1 }} onClick={() => { handleChangeSettingsCustomer(domain.id ?? 0, 0, ''); }}>Zrušiť prepojenie</Button>}
                                </Grid>
                            </React.Fragment>
                        )}
                        */}

                        <Grid item xs={12} mt={2}>
                            <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                        </Grid>

                    </Grid>

                    {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                </ContentPaperScroll>
            </Content>

            {/* Výber z adresára (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
            <CustomersDialog {...customersDialog} />
        </>
    )
}

export default BillingInformations;