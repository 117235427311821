import React from 'react';

// Modely
import { History } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, AlertTitle, Grid } from '@mui/material';

// Ikony
import UpdateIcon from '@mui/icons-material/Update';

interface DocumentCreateHistoryProps {
    history?: History[];
}

const DocumentCreateHistory = (props: DocumentCreateHistoryProps) => {

    return (
        <>
            <Grid container columnSpacing={1}>
                {/* Zoznam */}
                {
                    props.history?.map((history, index) => {
                        return (
                            <Grid item xs={12} mt={1} key={index}>
                                <Alert
                                    variant="standard"
                                    icon={<UpdateIcon />}
                                    severity="info">
                                    <AlertTitle>
                                        {history.createdDate === undefined ? '-' : dateFormat(new Date(history.createdDate ?? new Date()), 'dd.MM.yyyy HH:mm')}
                                    </AlertTitle>
                                    <strong>{history.name}</strong> - {history.note}
                                </Alert>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default DocumentCreateHistory