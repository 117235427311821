// Modely
import { CustomerAddress, Country } from '../../models/Models';

// Komponenty
import { FormControl, InputLabel, MenuItem, Select, InputAdornment, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';

// Ikony
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BadgeIcon from '@mui/icons-material/Badge';

export interface CustomerCreateAddressProps {
    address: CustomerAddress;
    countries: Country[];
    index: number;
    onChange: (index: number, property: string, value: any) => void;
}

const CustomerCreateAddress = (props: CustomerCreateAddressProps) => {
    return (
        <Grid container columnSpacing={1}>

            <Grid item xs={12} sm={8}>
                <TextField margin="dense" name="name" label="Meno alebo názov" fullWidth variant="outlined" autoComplete="off" value={props.address?.name ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <BadgeIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField margin="dense" name="phone" label="Telefón" fullWidth variant="outlined" autoComplete="off" value={props.address?.phone ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 25 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <PhoneIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={8}>
                <TextField margin="dense" name="street" label="Ulica" fullWidth variant="outlined" autoComplete="off" value={props.address?.street ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField margin="dense" name="houseNumber" label="Číslo domu" fullWidth variant="outlined" autoComplete="off" value={props.address?.houseNumber ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 15 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} md={4}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="filter-country">Krajina</InputLabel>
                    <Select labelId="filter-country" label="Krajina" value={props.address?.countryId ?? 0} name="countryId" onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}>
                        <MenuItem key={0} value={0}>-</MenuItem>
                        {props.countries?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={8} md={4}>
                <TextField margin="dense" name="city" label="Mesto" fullWidth variant="outlined" autoComplete="off" value={props.address?.city ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
                <TextField margin="dense" name="postcode" label="PSČ" fullWidth variant="outlined" autoComplete="off" value={props.address?.postcode ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 15 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

        </Grid>
    )
}

export default CustomerCreateAddress;