import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../../utility/Convert';

// Modely
import { Currency, CurrencyCode } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Switch, MenuItem, InputLabel, Select, FormControl, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import CalculateIcon from '@mui/icons-material/Calculate';
import NumbersIcon from '@mui/icons-material/Numbers';
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Currency => ({
    id: 0,
    system: false,
    code: '',
    exchange: 1.00,
    exchangeImport: false,
    exchangeImportCalculate: false,
    exchangeImportCalculateMethod: 0,
    exchangeImportCalculateValue: 0,
    prefix: '',
    surfix: '',
    separatorOfDecimal: ',',
    separatorOfThousands: '',
    roundingMethod: 0,
    roundingAccurancy: 0
});

// Vstupné parametre
export interface CurrencyCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const CurrencyCreate = (props: CurrencyCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Currency>(EmptySource());
    const [sourceCode, setSourceCode] = useState<CurrencyCode[]>([]);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'currency/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Currency);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadSourceCodes = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'currencycode')
            .then(response => {
                setSourceCode(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadSourceCodes(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.id === 0) {
            source.system = false;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'currency', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Mena' : 'Nová mena')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="domain-create-code">Mena *</InputLabel>
                                    <Select required labelId="domain-create-code" label="Mena *" value={source.code ?? ''} name="code" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'code' || props.autoFocus === undefined) && setFocus(input)} >
                                        <MenuItem key={0} value={0}>Žiadna</MenuItem>
                                        {sourceCode?.map(item => <MenuItem key={item.code} value={item.code}>{item.code + ' - ' + item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="prefix" type="text" label="Predný symbol" variant="outlined" autoComplete="off" value={source.prefix ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'prefix' && setFocus(input)}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Predný symbol zobrazený pred sumou, napr.: '$ 19,25'.">
                                                    <EditIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="exchange" type="text" label="Kurz" variant="outlined" autoComplete="off" value={source.exchange ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    disabled={source.system === true}
                                    inputRef={input => props.autoFocus === 'exchange' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Kurz voči základnej (hlavnej) mene.">
                                                    <CalculateIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="surfix" type="text" label="Zadný symbol" variant="outlined" autoComplete="off" value={source.surfix ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'surfix' && setFocus(input)}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Zadný symbol zobrazený za sumou, napr.: '19,25 €'">
                                                    <EditIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="separatorOfThousands" label="Oddelovač tisícov" fullWidth variant="outlined" autoComplete="off" value={source.separatorOfThousands ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'separatorOfThousands' && setFocus(input)}
                                    inputProps={{ maxLength: 3 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Oddelovač tisícov (napr. čiarka, medzera, ...)">
                                                    <NumbersIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required margin="dense" name="separatorOfDecimal" type="text" label="Oddelovač desatín" fullWidth variant="outlined" autoComplete="off" value={source.separatorOfDecimal ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'separatorOfDecimal' && setFocus(input)}
                                    inputProps={{ maxLength: 3 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Oddelovač desatín (napr. čiarka, bodka, ...)">
                                                    <NumbersIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="placesOfDecimal" type="number" label="Počet desatín" variant="outlined" autoComplete="off" value={source.placesOfDecimal ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'placesOfDecimal' && setFocus(input)}
                                    inputProps={{ maxLength: 1, min: 0, max: 8, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalculateIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="info">Niektoré nastavenia ako zaokrúhlenie, počet desatín, oddelovače a podobne sú aplikované iba v rámci objednávkového systému pre zákazníka (eshop a podobne).</Alert>
                            </Grid>

                            {source.system === false && (<>

                                <Grid item xs={12} sx={{ my: 2 }}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id="domain-create-rounding-method">Metóda zaokrúhlenia</InputLabel>
                                        <Select required labelId="domain-create-rounding-method" label="Metóda zaokrúhlenia" value={source.roundingMethod ?? 0} name="roundingMethod" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                            inputRef={input => props.autoFocus === 'roundingMethod' && setFocus(input)}>
                                            <MenuItem value={0}>Matematicky</MenuItem>
                                            <MenuItem value={1}>Nahor</MenuItem>
                                            <MenuItem value={2}>Nadol</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id="domain-create-rounding-accurancy">Presnosť zaokrúhlenia</InputLabel>
                                        <Select required labelId="domain-create-rounding-accurancy" label="Presnosť zaokrúhlenia" value={source.roundingAccurancy ?? 0} name="roundingAccurancy" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                            inputRef={input => props.autoFocus === 'roundingAccurancy' && setFocus(input)}>
                                            <MenuItem value={0}>Stotiny jednotky meny (1,99 USD)</MenuItem>
                                            <MenuItem value={1}>Stotiny po 5 stotín jednotky meny (1,95 USD)</MenuItem>
                                            <MenuItem value={2}>Stotiny po 10 stotín jednotky meny (1,90 USD)</MenuItem>
                                            <MenuItem value={3}>Tisíciny jednotky meny (1,999 USD)</MenuItem>
                                            <MenuItem value={4}>Celá jednotka meny (2 USD)</MenuItem>
                                            <MenuItem value={5}>Celé desiatky jednotky meny (120 HUF)</MenuItem>
                                            <MenuItem value={6}>Celé stovky jednotky meny (100 HUF)</MenuItem>
                                            <MenuItem value={7}>Bez zaokrúhlenia (4 desatinné miesta)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} mt={1}>
                                    <Alert severity="info">Nastavenia zaokrúhlenia sú aplikované len pre informačný prepočet zo základnej meny. Nastavenia nemajú vplyv na presnosť matematického výpočtu v rámci základnej meny. Prepočet cudzej meny a následné zaokrúhlenie má len informatívny charakter, smerodajná je vždy hlavná mena.</Alert>
                                </Grid>

                                <Grid item xs={12} sx={{ my: 2 }}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel control={<Switch checked={source.exchangeImport ?? false} name="exchangeImport" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label={source.exchangeImport ? 'Import kurzu z ECB je aktívny' : 'Importovať kurz z ECB'} />
                                </Grid>

                                {source.exchangeImport && (<>

                                    <Grid item xs={12} mb={1}>
                                        <FormControlLabel control={<Switch checked={source.exchangeImportCalculate} name="exchangeImportCalculate" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Upraviť kurz z ECB" />
                                    </Grid>

                                    {source.exchangeImportCalculate && (<>

                                        <Grid item xs={12} sm={4}>
                                            <FormControl margin="dense" variant="outlined" fullWidth>
                                                <InputLabel id="domain-create-import-calculate-method">Znamienko</InputLabel>
                                                <Select required labelId="domain-create-import-calculate-method" label="Znamienko" value={source.exchangeImportCalculateMethod ?? 0} name="exchangeImportCalculateMethod" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                    inputRef={input => props.autoFocus === 'exchangeImportCalculateMethod' && setFocus(input)}>
                                                    <MenuItem value={0}>+</MenuItem>
                                                    <MenuItem value={1}>-</MenuItem>
                                                    <MenuItem value={2}>*</MenuItem>
                                                    <MenuItem value={3}>/</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={8}>
                                            <TextField required fullWidth margin="dense" name="exchangeImportCalculateValue" type="text" label="Hodnota" variant="outlined" autoComplete="off" value={source.exchangeImportCalculateValue ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                inputRef={input => props.autoFocus === 'exchangeImportCalculateValue' && setFocus(input)}
                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Táto hodnota bude pripočítaná (podľa vybraného znamienka) k získanému kurzu z ECB.">
                                                                <CalculateIcon className="formIconHelp" />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>

                                    </>)}
                                </>)}
                            </>)}
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id, system: source.system }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default CurrencyCreate;