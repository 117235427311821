import { useState, ChangeEvent } from 'react';

// Utility
import Debounce from '../utility/Debounce';

// Komponenty
import { Badge, Divider, InputBase, Paper } from '@mui/material';

// Ikony
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface SearchProps {
    delay?: number;
    autoFocus?: boolean;
    filter?: boolean;
    filterCount?: number;
    text?: string; // predvolený text v políčku - ak by som komponent odpojil a pripojil, tak by bolo políčko prázdne aj keď hodnota v nadradenom komponente je stále vyplnená, takto ju môžem predvyplniť (použité napr. v accordeon alebo tabpanel)
    onSearch: (s: string) => void;
    onFilter?: () => void;
    onClear: () => void;
}

const Search = (props: SearchProps) => {

    const [searchText, setSearchText] = useState(props.text ?? '');

    const handleSearch = Debounce((s: string) => {
        props.onSearch(s);
    }, props.delay ?? 500);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        handleSearch(e.target.value);
    };

    const handleClear = () => {
        setSearchText('');
        props.onClear();
    };

    return (
        <Paper sx={{ p: '4px 4px 4px 0px', display: 'inline-flex', alignItems: 'center', width: '100%' }}
            onKeyDown={(e) => {
                if (e.code === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onSearch(searchText);
                }
                if (e.code === 'Escape') {
                    if (searchText !== '') {
                        // Iba ak nulujem text nevykonám ďalšie udalosti, aby som vedel použiť ESC pre zavretie dialógu
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    handleClear();
                }
            }}>
            <InputBase
                value={searchText}
                sx={{ ml: 1, pl: 1, flex: 1 }}
                placeholder="Zadajte kľúčové slovo"
                inputProps={{ 'aria-label': 'Zadajte kľúčové slovo' }}
                onChange={handleChange}
                autoFocus={props.autoFocus}
            />
            <IconButton size="small" type="button" aria-label="Hľadať" title="Hľadať" onClick={() => props.onSearch(searchText)}>
                <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
            {(props.filter ?? false) === true && (
                <>
                    <IconButton size="small" type="button" aria-label="Filter" title="Filter" onClick={props.onFilter}>
                        <Badge badgeContent={props.filterCount} color="primary">
                            {(props.filterCount ?? 0) === 0 ? <FilterAltOutlinedIcon /> : <FilterAltIcon />}
                        </Badge>
                    </IconButton>
                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                </>
            )}
            <IconButton size="small" aria-label="Vyčistiť" title="Vyčistiť" onClick={handleClear}>
                <ClearIcon />
            </IconButton>
        </Paper>
    )
}

export default Search
