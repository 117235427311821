// Komponenty
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ContentTags from './ContentTags';

// Vstupné parametre
export interface ContentTagsDialogProps {
    open: boolean;
    ids: number[];
    keepMounted?: boolean;
    onClose?: () => void;
}

const ContentTagsDialog = (props: ContentTagsDialogProps) => {
    return (
        <>
            <Dialog maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick' && props.onClose) { props.onClose(); }}}>
                <DialogTitle>
                    Dynamické značky
                </DialogTitle>
                <DialogContent>
                    <ContentTags 
                        ids={props.ids}
                        afterCopy={() => {
                            if(props?.onClose) {
                                props?.onClose();
                            }
                        }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} type="submit" variant="contained">Zavrieť</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ContentTagsDialog;