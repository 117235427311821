import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../AppRoutes';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Convert';
import { FormatCurrency } from '../../utility/Format';

// Modely
import { DocumentItem, DocumentItemType, DocumentItemCompute, Product, ProductPriceCompute, ProductLite, Unit, Parameter, ParameterItem, ParameterItemPriceCompute, PriceList, ProductVolumeDiscount, Currency } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Backdrop, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tooltip, Accordion, AccordionDetails, AccordionSummary, Typography, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ProductsDialog, { ProductsDialogProps } from '../product/ProductsDialog';
import ParameterConfiguratorDialog, { ParameterConfiguratorDialogProps } from '../settings/parameter_configurator/ParameterConfiguratorDialog';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import BusinessIcon from '@mui/icons-material/Business';
import WidgetsIcon from '@mui/icons-material/Widgets';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TagIcon from '@mui/icons-material/Tag';
import ScaleIcon from '@mui/icons-material/Scale';
import TopicIcon from '@mui/icons-material/Topic';
import EuroIcon from '@mui/icons-material/Euro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import CalculateIcon from '@mui/icons-material/Calculate';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): DocumentItem => ({
    id: 0,
    parentId: 0,
    productId: 0,
    type: DocumentItemType.Product,
    ordinalNumber: 0,
    ean: '',
    mpn: '',
    code: '',
    codeSupplier: '',
    name: '',
    variant: '',
    note: '',
    // weight: 0,
    quantity: 1,
    unit: '',
    // pricePurchase: 0,
    // pricePurchaseVatRate: 0,
    // price: 0,
    // priceCalc: 0,
    priceIncludeVat: false,
    // priceVatRate: 0,
    // priceUnitNoVat: 0,
    // priceUnitVatAmount: 0,
    // priceTotalNoVat: 0,
    // priceTotalVatAmount: 0,
    other: {},
    configurator: []
});

// Vstupné parametre
export interface DocumentCreateItemProps {
    item?: DocumentItem;
    itemIndex?: number;
    itemLoadProduct?: ProductLite; // Ak podhodím produkt, tak je automaticky načítaný pri zobrazení okna
    units: Unit[],
    parameters: Parameter[];
    vatRates: number[];
    vatRateDefault: number;
    priceIncludeVat: boolean;
    languageDefaultId: number;
    priceList: PriceList;
    tagDiscount: number;
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    currency: Currency;
    onSave: (item: DocumentItem, itemIndex?: number) => void;
    onClose: () => void;
}

const DocumentCreateItem = (props: DocumentCreateItemProps) => {

    // Stav
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingList, setLoadingList] = useState<boolean>(false);
    const [loadingListText, setLoadingListText] = useState<string>('');
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<DocumentItem>(EmptySource());
    const [product, setProduct] = useState<Product>({});
    const [productToLoad, setProductToLoad] = useState<number>();
    const [productListToLoad, setProductListToLoad] = useState<ProductLite[]>();
    const [productVolumeDiscounts, setProductVolumeDiscounts] = useState<ProductVolumeDiscount[]>([]);
    const [configuratorToLoad, setConfiguratorToLoad] = useState<ParameterItem[]>()

    // Referencie na elementy
    const quantityRef = useRef<HTMLInputElement>();

    // Harmonika
    const [codeExpanded, setCodeExpanded] = useState<boolean>(false);
    const [priceExpanded, setPriceExpanded] = useState<boolean>(false);
    const [pricePurchaseExpanded, setPricePurchaseExpanded] = useState<boolean>(false);
    const [variantExpanded, setVariantExpanded] = useState<boolean>(false);
    const [configuratorExpanded, setConfiguratorExpanded] = useState<boolean>(false);
    const [noteExpanded, setNoteExpanded] = useState<boolean>(false);

    // Funkcie pre zaokrúhlenie podľa desatín
    const mathRound2 = (value: number) => Math.round(value * 100) / 100;
    const mathRound4 = (value: number) => Math.round(value * 10000) / 10000;

    // Automaticky vyplním okno
    useEffect(() => {
        if (!props.open) {
            return;
        }

        // Zavriem harmoniky
        if (priceExpanded) {
            setPriceExpanded(false);
        }
        if (codeExpanded) {
            setCodeExpanded(false);
        }
        if (pricePurchaseExpanded) {
            setPricePurchaseExpanded(false);
        }
        if (variantExpanded) {
            setVariantExpanded(false);
        }
        if (configuratorExpanded) {
            setConfiguratorExpanded(false);
        }
        if (noteExpanded) {
            setNoteExpanded(false);
        }

        // Použijem props.item alebo emptySource (automaticky spustím prepočet, aby sa doplnili dopočítavané hodnoty)
        setSource(DocumentItemCompute(props.item ?? {
            ...EmptySource(),
            unit: (props.units.find(unit => unit.other?.translationName === 'ks') !== undefined ? 'ks' : ''),
            priceIncludeVat: props.priceIncludeVat,
            priceVatRate: props.vatRateDefault
        }));

        // Ak je na vstupe podhodený produkt, tak ho automaticky načítam
        if (props.itemLoadProduct !== undefined && (props.itemLoadProduct?.id ?? 0) > 0) {
            setProductToLoad(props.itemLoadProduct.id);
        }

        // Ak existuje relačný produkt, tak ho dotiahnem
        if (props.item && (props.item.productId ?? 0) > 0) {
            axios
                .get(AppConfig.ApiUri + 'product/' + props.item.productId)
                .then(response => {
                    if (response.data !== null) {
                        setProduct(response.data as Product);
                    }
                });
        } else {
            setProduct({});
        }
    }, [props.item, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setProduct({});
        setSource(prev => ({
            ...prev,
            ...EmptySource(),
            id: prev.id,
            ordinalNumber: prev.ordinalNumber,
            unit: (props.units.find(unit => unit.other?.translationName === 'ks') !== undefined ? 'ks' : ''),
            priceIncludeVat: props.priceIncludeVat,
            priceVatRate: props.vatRateDefault
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source, props.itemIndex);
        props.onClose();
    };

    // Zrušenie relácie s produktom
    const handleProductRelationCancel = () => {
        setProduct({});
        setSource(prev => ({
            ...prev,
            productId: 0,
            other: {}
        }))
    };

    // Automaticky vyplním vybrané položky konfigurátora po označení, musím to vykonať cez stav a háčik, keďže v callbacku zo zoznamu nemám vyplnenú inštanciu 'props'
    useEffect(() => {
        // Pokračujem, len ak nie je vynulovaný
        if (configuratorToLoad === undefined) {
            return;
        }

        // Uchovám vybraný produkt a vynulujem, aby sa háčik nevyvolal viac krát
        const configuratorItems = configuratorToLoad;
        setConfiguratorToLoad(undefined);

        // Aktualizujem konfigurátor
        setSource(prev => DocumentItemCompute({
            ...prev,
            configurator: [
                ...(prev.configurator ?? [])?.filter(item => item.uid !== 'configurator') ?? [],
                ...configuratorItems.map(item => ({
                    uid: 'configurator',
                    name: props.parameters?.find(parameter => parameter.id === item.parameterId)?.other?.translationName ?? '',
                    value: item.other?.translationName,
                    price: ParameterItemPriceCompute(item, props.priceList)
                }))
            ]
        }));
    }, [configuratorToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    // Výber z konfigurátoru
    const [parameterConfiguratorDialog, setParameterConfiguratorDialog] = useState<ParameterConfiguratorDialogProps>({
        open: false,
        keepMounted: false,
        onSelect: (items: ParameterItem[]) => {
            if (items.length > 0) {
                // Vyvolám háčik pre načítanie položiek konfigurátora (v callback nemám prístup k 'props')
                setConfiguratorToLoad(items);

                // Automaticky rozbalím panel konfigurátora
                setConfiguratorExpanded(true);

                // Automaticky označím množstvo pre zmenu
                quantityFocus();
            }
            handleParameterConfiguratorClose();
        },
        onClose: () => {
            handleParameterConfiguratorClose();
            quantityFocus();
        }
    });

    // Funkcia pre "focus" množstva
    const quantityFocus = () => {
        if (quantityRef && quantityRef.current) {
            var target = quantityRef.current;
            setTimeout(() => {
                target.focus();
                target.select();
            }, 100);
        }
    };

    // Otvorenie konfigurátora (ak neexistuje žiadny parameter typu konfigurátor, ktorý by obsahoval vybrané položky parametrov (alebo sú prázdne), tak neotváram)
    const handleParameterConfiguratorOpen = (parametersItems?: number[]) => {
        if (parametersItems === undefined || props.parameters.find(parameter => (parameter.type === 2 || parameter.type === 3) && parameter?.items?.find(item => parametersItems?.includes(item.id ?? 0))) === undefined) {
            return;
        }
        setParameterConfiguratorDialog(prev => ({ ...prev, open: true, parametersItems: parametersItems }));
    };
    const handleParameterConfiguratorClose = () => {
        setParameterConfiguratorDialog(prev => ({ ...prev, open: false }));
    };

    // Automaticky vyplním vybraný produkt po označení, musím to vykonať cez stav a háčik, keďže v callbacku zo zoznamu nemám vyplnenú inštanciu 'props'
    useEffect(() => {
        // Pokračujem, len ak ešte nie je vynulovaný
        if (productToLoad === undefined) {
            return;
        }

        // Uchovám vybraný produkt a vynulujem, aby sa háčik nevyvolal viac krát
        const productId = productToLoad;
        setProductToLoad(undefined);

        // Získam kompletnú triedu produktu a aplikujem na položku
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'product/' + productId)
            .then(response => {
                if (response.data !== null) {
                    // Nový vybraný produkt
                    const productNew = response.data as Product;
                    const productItem = productToDocumentItem(productNew);

                    // Ak existuje variant, tak automaticky rozbalím panel variantu
                    if ((productItem.variant ?? '').length > 0) {
                        setVariantExpanded(true);
                    }

                    // Uchovám vybraný produkt
                    setProduct(productNew);

                    // Vyvolám zmenu stavu položky
                    setSource(prev => DocumentItemCompute({
                        ...prev,
                        ...productItem
                    }));

                    // Automaticky označím množstvo pre zmenu
                    quantityFocus();

                    // Vyvolám okno pre konfigurátor (zobrazí sa len ak je možné vybrať konfiguráciu)
                    handleParameterConfiguratorOpen(productNew.parameters);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [productToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre pozastavenie vlákna
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    // Automaticky vložím vybrané produkty z pola, vždy po jednom
    useEffect(() => { productListToLoadCallback(); }, [productListToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vždy keď sa vyvolá táto funcia, tak spracujem jeden produkt a uložím nový stav aby sa funkcia zopakovala, na konci resetujem hodnotu a zavriem okno
    const productListToLoadCallback = async () => {

        // Pokračujem, len ak zoznam produktov ešte nie je vynulovaný
        if (productListToLoad === undefined) {
            return;
        }
        if (productListToLoad.length === 0) {
            setProductListToLoad(undefined);
            setLoadingList(false);
            setLoadingListText('');
            return;
        }

        // Zavriem okno
        if (props.open) {
            props.onClose();
        }

        // Ďalší produkt na spracovanie (podržím v pamäti)
        const productLite = productListToLoad[0];

        // Získam kompletné triedy produktov a vložím do zoznamu ako nové položky
        setLoadingList(true);
        setLoadingListText(productLite?.name ?? '');

        // Pozastavím vlákno, aby sa stihol aktualizovať stav nadradeného komponentu
        await sleep(250);

        // Získam kompletnú triedu produktov a vložím do zoznamu položiek cez onSave()
        axios
            .get(AppConfig.ApiUri + 'product/' + productLite.id)
            .then(response => {
                if (response.data !== null) {

                    // Nový vybraný produkt
                    const productNew = response.data as Product;
                    const productItem = productToDocumentItem(productNew);

                    // Pretypujem Prepočítam položku
                    const productComputed = DocumentItemCompute({
                        ...EmptySource(),
                        ...productItem
                    });

                    // Vložím do zoznamu
                    props.onSave(productComputed);
                }

                // Odstránim spracovanú položku a uložím stav aby sa spracoval ďalší v poradí
                setProductListToLoad(prev => {
                    var changed = [...(prev ?? [])];
                    if (changed.length > 0) {
                        changed.shift();
                    }
                    return changed;
                });
            });
    };

    // Funkcia pre pretypovanie product na triedu položky
    const productToDocumentItem = (product: Product): DocumentItem => {
        // Získam všetky parametre, ktoré tvoria variant a vyskladám z nich textovú informáciu
        // Prejdem všetky parametere typu "variant" (type == 1) a získam všetky položky, ktoré figurujú pri produkte
        const productVariants: ParameterItem[] = [];
        props.parameters?.filter(parameter => parameter.type === 1)?.forEach(parameter => {
            parameter?.items?.filter(item => product.parameters?.includes(item.id ?? 0))?.forEach(item => productVariants.push(item));
        });
        const productVariant = productVariants?.map(item => item.other?.translationName ?? '')?.join(', ') ?? '';

        // cmd.Parameters.AddParameter(DbType.Int32, "file_id", iProduct.Files?.Find(file => file.Type == 0 && file.CoverInList)?.FileId ?? iProduct.Files?.OrderBy(file => file.OrdinalNumber)?.ToList()?.Find(file => file.Type == 0)?.FileId ?? 0);
        const productLiteName = (product?.translations?.find(translation => translation.languageId === props.languageDefaultId)?.name ?? '');
        const productLiteFile = product.files?.find(file => file.type === 0 && file.coverInList === true)?.other;

        // Vrátim známe údaje
        return ({
            parentId: 0,
            productId: product.id,
            ean: product.ean,
            mpn: product.mpn,
            code: product.code,
            codeSupplier: product.codeSupplier,
            name: productLiteName ?? '',
            variant: productVariant,
            weight: product.weight,
            unit: props.units?.find(unit => unit.id === product.unitId)?.other?.translationName ?? '',
            pricePurchase: product.pricePurchase,
            pricePurchaseVatRate: product.pricePurchaseVatRate,
            price: ProductPriceCompute(product, props.priceList, props.tagDiscount),
            priceIncludeVat: props.priceIncludeVat,
            priceVatRate: product.priceVatRate,
            configurator: [],
            other: {
                fileExtension: productLiteFile?.fileExtension ?? '',
                fileIsImage: productLiteFile?.fileIsImage ?? true,
                fileName: productLiteFile?.fileName ?? '',
                fileNameSmall: productLiteFile?.fileNameSmall ?? '',
                fileNameMedium: productLiteFile?.fileNameMedium ?? '',
                fileSrc: productLiteFile?.fileSrc ?? '',
                fileSrcSmall: productLiteFile?.fileSrcSmall ?? '',
                fileSrcMedium: productLiteFile?.fileSrcMedium ?? ''
            }
        });
    };

    // Výber existujúceho produktu
    const [productsDialog, setProductsDialog] = useState<ProductsDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (products: ProductLite[]) => {
            // Výber viacerých produktov
            if (products.length > 1) {
                setProductListToLoad(products);
            }
            else if (products.length === 1 && (products[0].id ?? 0) > 0) {
                setProductToLoad(products[0].id ?? 0);
            }
            handleProductsClose();
        },
        onClose: () => handleProductsClose()
    });

    // Zobrazenie produktov (pri prvom ponecham komponent pripnutý aby som nemusel znovu naťahovať všetky číselníky)
    const handleProductsOpen = () => {
        setProductsDialog(prev => ({ ...prev, open: true /*, keepMounted: true - citelne to spomaluje form */ }));
    };
    const handleProductsClose = () => {
        setProductsDialog(prev => ({ ...prev, open: false }));
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
            // Automaticky označím text vo vybraných poliach
            if ((props.autoFocus === 'quantity' ||
                props.autoFocus === 'weight' ||
                props.autoFocus === 'price' ||
                props.autoFocus === 'priceCalc') &&
                (input?.select !== undefined && typeof input.select === 'function')) {
                setTimeout(() => { input?.select(); }, 250);
            }
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Pridať, upraviť alebo vymazať parameter konfigurátor
    const handleChangeConfigurator = (index: number, property: string, value: any) => {
        setSource(prev => {
            let changed = prev.configurator ?? [];
            changed[index] = { ...changed[index], [property]: value };
            let next: DocumentItem = { ...prev, configurator: changed };
            // Prepočet položky spustím len ak som zmenil niektorú z hodnôt, ktoré ho ovplyvňujú
            if (property === 'price') {
                next = handleVolumeDiscount(next);  // Aplikujem množstevnú zľavu (priamo ju ovplyvňuje cena aj množstvo)
                next = DocumentItemCompute(next);      // Prepočítam položku
            }
            return next;
        });
    }
    const handleCreateConfigurator = () => {
        setSource(prev => ({ ...prev, configurator: [...(prev.configurator ?? []), { uid: '', name: '', value: '', price: 0 }] })); // Nemusím vyvolať žiadny prepočet, keďže nulová cena nič neovplyvní)
    }
    const handleDeleteConfigurator = (index: number) => {
        setSource(prev => {
            let changed = prev.configurator ?? [];
            let next: DocumentItem = { ...prev, configurator: changed.filter(deleted => deleted !== changed[index]) ?? [] };
            next = handleVolumeDiscount(next);  // Aplikujem množstevnú zľavu (priamo ju ovplyvňuje cena aj množstvo)
            next = DocumentItemCompute(next);      // Prepočítam položku
            return next;
        });
    }

    // Ak sa zmení cennník alebo produkt, tak automaticky vyplním zoznam množstevných zliav
    // Tie sa aplikujú iba pri zmene množstva
    useEffect(() => {
        let volumeDiscounts: ProductVolumeDiscount[] = [];

        // Ak produkt obsahuje množstevné zľavy pre daný cenník, tak ich použijem prioritne
        if ((product.id ?? 0) > 0 && (product.volumeDiscounts?.length ?? 0) > 0) {
            volumeDiscounts = [...(product?.volumeDiscounts?.filter(v => v.priceListId === (props.priceList.id ?? 0))) ?? []];
        }

        // Ak produkt neobsahuje množstevné zľavy pre daný cenník, tak aleternatívne použijem množstevné z cenníka
        if (volumeDiscounts.length === 0 && (props.priceList.discounts?.length ?? 0) > 0) {
            volumeDiscounts = [...(props.priceList.discounts?.map(d => ({
                minimalQuantity: d.quantity,
                discount: d.discount
            })) ?? [])];
        }

        // Uložím stav
        setProductVolumeDiscounts(volumeDiscounts);
    }, [props.priceList, product]);

    // Ak sa zmení množstvo, tak automaticky vykonám kontrolu množstevných zliav, kde sa zľava automaticky pridá ako položka konfigurátora
    const handleVolumeDiscount = (item: DocumentItem): DocumentItem => {

        // Vstupné údaje
        let itemDiscount: number = 0;           // percentá
        let itemDiscountPrice: number = 0;      // vypočítaná suma
        let itemDiscountQuantity: number = 0;   // minimálne množstvo
        let itemQuantity = item.quantity ?? 0;  // množstvo

        // Pokračujem v kontrole len ak sú nastavené množstevné zľavy a množstvo je väčšie ako nula
        if (itemQuantity > 0 && productVolumeDiscounts.length > 0) {

            // Zoradím od najväčšieho minimálneho množstva a nájdem prvú v poradí, ktorá má menšie alebo rovnaké minimálne množstvo
            const volumeDiscount = productVolumeDiscounts
                .sort((a, b) => (a.minimalQuantity ?? 0) < (b.minimalQuantity ?? 0) ? 1 : -1)
                .find(d => (d.minimalQuantity ?? 0) <= itemQuantity && (d.discount ?? 0) > 0);

            // Ak som našiel zľavu, tak ju aplikujem
            if (volumeDiscount !== undefined) {
                // Získam cenníkovú cenu vrátane konfigurátora (okrem položky množstevnej zľavy)
                let itemPrice = mathRound4((item.price ?? 0) + (item?.configurator?.filter(c => c.uid !== 'volumediscount')?.reduce((sum, current) => sum + (current?.price ?? 0), 0) ?? 0))

                // Získam zľavu v percentách a vypočítanu sumu
                itemDiscount = volumeDiscount.discount ?? 0;
                itemDiscountPrice = mathRound4((itemPrice / 100) * itemDiscount);
                itemDiscountQuantity = volumeDiscount.minimalQuantity ?? 0;
            }
        }

        // Aktualizujem konfigurátor (pridám alebo odstránim položku)
        item = {
            ...item, configurator: [
                ...(item?.configurator?.filter(c => c.uid !== 'volumediscount') ?? []),
                ...(itemDiscountPrice > 0 ? [{ uid: 'volumediscount', name: 'Množstevná zľava od ' + itemDiscountQuantity + ' MJ', value: itemDiscount + '%', price: itemDiscountPrice * -1 }] : [])
            ]
        };

        return item;
    }

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {

        // Prepočet položky spustím len ak som zmenil niektorú z hodnôt, ktoré ho ovplyvňujú
        let compute = [
            'quantity',
            'price',
            'priceCalc',
            'priceIncludeVat',
            'priceVatRate'
        ].includes(property);

        // Upraví stav položky
        setSource(prev => {
            let next: DocumentItem = ({ ...prev, [property]: value });
            if (compute) {
                next = handleVolumeDiscount(next);          // Aplikujem množstevnú zľavu (priamo ju ovplyvňuje cena aj množstvo)
                next = DocumentItemCompute(next, property);    // Prepočítam položku
            }
            return next;
        });
    }

    // Informácie o vybranom produkte
    const productInfo = (product.code ?? '') + ' - ' + (product?.translations?.find(translation => translation.languageId === props.languageDefaultId)?.name ?? '');

    return (
        <>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loadingList}>
                <CircularProgress color="inherit" />
                <Typography ml={2}>{loadingListText}</Typography>
            </Backdrop>

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.itemIndex === undefined ? 'Nová položka' : 'Položka'}
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={8}>
                                <TextField margin="dense" label="Existujúci produkt" fullWidth variant="outlined" autoComplete="off" disabled onClick={handleProductsOpen}
                                    value={((product.id ?? 0) > 0 ? productInfo : '...')}
                                    inputProps={{ style: { cursor: 'pointer' } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <WidgetsIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField fullWidth margin="dense" type="text" label="Zásoby" variant="outlined" autoComplete="off" disabled
                                    value={product?.stocks?.reduce((sum, current) => sum + (current?.quantity ?? 0), 0) ?? '0'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <BusinessIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(source.productId ?? 0) > 0 && (
                                <Grid item xs={12}>
                                    <Alert severity='info'>Zmena údajov v tomto formulári nemá vplyv na existujúci produkt.</Alert>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Button variant="contained" size="small" startIcon={<TopicIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} onClick={handleProductsOpen}>Vybrať</Button>
                                {(source.productId ?? 0) > 0 && <>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => { setProductToLoad(source.productId) }}>Obnoviť</Button>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} href={AppRouteUrl.PRODUCTS + '?id=' + (source.productId ?? 0).toString()} target="_blank">Detail</Button>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={handleProductRelationCancel}>Zrušiť prepojenie</Button>
                                </>}
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="quantity" type="text" label="Množstvo MJ" variant="outlined" autoComplete="off" value={source.quantity ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'quantity' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown, ref: quantityRef }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalculateIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="product-create-unit">Merná jednotka</InputLabel>
                                    <Select labelId="product-create-unit" label="Merná jednotka" value={source.unit ?? ''} name="unit" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'unit' && setFocus(input)}>
                                        <MenuItem value="">-</MenuItem>
                                        {props.units.map(unit => (<MenuItem key={unit.id} value={unit.other?.translationName}>{unit.other?.translationName ?? ''}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="weight" type="text" label="Hmotnosť MJ v gramoch" variant="outlined" autoComplete="off" value={source.weight ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'weight' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ScaleIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth required type="text" margin="dense" name="price" label={'Cena ' + (source.priceIncludeVat ? '(s DPH)' : '(bez DPH)')} variant="outlined" autoComplete="off" value={source.price ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'price' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="priceCalc" type="text" label={'Cena ' + (!source.priceIncludeVat ? '(s DPH)' : '(bez DPH)')} variant="outlined" autoComplete="off" value={source.priceCalc ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'priceCalc' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="product-create-vatrate">DPH</InputLabel>
                                    <Select required labelId="product-create-vatrate" label="DPH" value={source.priceVatRate ?? 0} name="priceVatRate" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'priceVatRate' && setFocus(input)}>
                                        {(props.vatRates ?? []).length === 0 && <MenuItem value={0}>0 %</MenuItem>}
                                        {props.vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item container xs={12} columnSpacing={1}>

                                <Grid item xs={12} sm={4} order={(source.priceIncludeVat ?? true) ? 1 : 2}>
                                    <TextField fullWidth disabled margin="dense" type="number" label="Jednotková cena (s DPH)" variant="outlined" autoComplete="off" value={mathRound2((source.priceUnitNoVat ?? 0) + (source.priceUnitVatAmount ?? 0))}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EuroIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={4} order={(source.priceIncludeVat ?? true) ? 2 : 1}>
                                    <TextField fullWidth disabled margin="dense" type="number" label="Jednotková cena (bez DPH)" variant="outlined" autoComplete="off" value={source.priceUnitNoVat ?? ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EuroIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={4} order={3}>
                                    <TextField fullWidth disabled margin="dense" type="number" label="Jednotková cena DPH" variant="outlined" autoComplete="off" value={source.priceUnitVatAmount ?? ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EuroIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={6} sm={2}>
                                <Typography variant='body2'>
                                    Celkom bez DPH:<br />
                                    Celkom DPH:<br />
                                    Celkom s DPH:
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sm={2} textAlign='right'>
                                <Typography variant='body2'>
                                    {FormatCurrency(source.priceTotalNoVat ?? 0, props.currency, 2)}<br />
                                    {FormatCurrency(source.priceTotalVatAmount ?? 0, props.currency, 2)}<br />
                                    {FormatCurrency(mathRound2((source.priceTotalNoVat ?? 0) + (source.priceTotalVatAmount ?? 0)), props.currency, 2)}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={variantExpanded} onChange={() => setVariantExpanded(prev => !prev)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Variant ({(source.variant?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField margin="dense" name="variant" label="Variant" fullWidth variant="outlined" autoComplete="off" value={source.variant ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'variant' && setFocus(input)}
                                                    inputProps={{ maxLength: 255 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={configuratorExpanded} onChange={() => setConfiguratorExpanded(prev => !prev)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Konfigurácia ({(source.configurator?.length ?? 0) > 0 ? (source.configurator?.length ?? 0) : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(source?.configurator?.length ?? 0) > 0 &&
                                            <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                                                <div style={{ flexGrow: 1 }}>
                                                    <Table size="small" sx={{ minWidth: '500px' }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Názov</TableCell>
                                                                <TableCell>Hodnota</TableCell>
                                                                <TableCell>{'Príplatok ' + (props.priceIncludeVat ? '(s DPH)' : '(bez DPH)')}</TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {source?.configurator?.map((configuration, index) => (
                                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                        <TextField fullWidth size="small" name="name" variant="outlined" autoComplete="off" value={configuration.name ?? ''}
                                                                            disabled={configuration.uid === 'volumediscount'}
                                                                            onChange={(e) => { handleChangeConfigurator(index, e.target.name, e.target.value) }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <EditIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </TableCell>
                                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                        <TextField fullWidth size="small" name="value" variant="outlined" autoComplete="off" value={configuration.value ?? ''}
                                                                            disabled={configuration.uid === 'volumediscount'}
                                                                            onChange={(e) => { handleChangeConfigurator(index, e.target.name, e.target.value) }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <EditIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </TableCell>
                                                                    <TableCell sx={{ width: '20%', p: 0.5, pl: 0 }}>
                                                                        <TextField fullWidth size="small" name="price" type="text" variant="outlined" autoComplete="off" value={configuration.price ?? ''}
                                                                            disabled={configuration.uid === 'volumediscount'}
                                                                            onChange={(e) => { handleChangeConfigurator(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <Tooltip title="Príplatok, ktorý bude pripočítaný k jednotkovej cene.">
                                                                                            <EuroIcon className="formIconHelp" />
                                                                                        </Tooltip>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </TableCell>
                                                                    <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                        <IconButton aria-label="Vymazať"
                                                                            disabled={configuration.uid === 'volumediscount'}
                                                                            onClick={() => handleDeleteConfigurator(index)}>
                                                                            <DeleteIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        }
                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleCreateConfigurator()}>Pridať parameter</Button>

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={codeExpanded} onChange={() => setCodeExpanded(prev => !prev)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Kódy</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={3}>
                                                <TextField margin="dense" name="code" label="Kód" fullWidth variant="outlined" autoComplete="off" value={source.code ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'code' && setFocus(input)}
                                                    inputProps={{ maxLength: 64 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TagIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField margin="dense" name="ean" label="EAN" fullWidth variant="outlined" autoComplete="off" value={source.ean ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'ean' && setFocus(input)}
                                                    inputProps={{ maxLength: 25 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <QrCodeIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField margin="dense" name="codeSupplier" label="Kód dodávateľa" fullWidth variant="outlined" autoComplete="off" value={source.codeSupplier ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'codeSupplier' && setFocus(input)}
                                                    inputProps={{ maxLength: 64 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TagIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField margin="dense" name="mpn" label="Kód výrobcu - MPN" fullWidth variant="outlined" autoComplete="off" value={source.mpn ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'mpn' && setFocus(input)}
                                                    inputProps={{ maxLength: 25 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TagIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={pricePurchaseExpanded} onChange={() => setPricePurchaseExpanded(prev => !prev)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Nákupná cena</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField fullWidth margin="dense" name="pricePurchase" type="text" label="Nákupná cena (s DPH)" variant="outlined" autoComplete="off" value={source.pricePurchase ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EuroIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={2}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="item-create-purchasevatrate">DPH</InputLabel>
                                                    <Select labelId="item-create-purchasevatrate" label="DPH" value={source.pricePurchaseVatRate ?? 0} name="pricePurchaseVatRate" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                        inputRef={input => props.autoFocus === 'pricePurchaseVatRate' && setFocus(input)}>
                                                        {(props.vatRates ?? []).length === 0 && <MenuItem value={0}>0 %</MenuItem>}
                                                        {props.vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={noteExpanded} onChange={() => setNoteExpanded(prev => !prev)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={3} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                                    inputProps={{ maxLength: 255 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>

                {/* Výber produktu (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <ProductsDialog {...productsDialog} />

                {/* Konfigurátor (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <ParameterConfiguratorDialog {...parameterConfiguratorDialog} parameters={props.parameters} />
            </Dialog>
        </>
    )
}

export default DocumentCreateItem;