import React, { FormEvent, useEffect, useState } from 'react'

// Utlity
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Convert';
import { LocalizationProvider, DateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import skLocale from 'date-fns/locale/sk';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, Grid, TextField, Alert, InputAdornment, AlertColor, DialogActions, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

// Modely
import { DocumentPayment, Payment } from '../../models/Models';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): DocumentPayment => ({
    id: 0,
    price: 0,
    note: '',
    date: new Date(),
    paymentId: 0
});

// Vstupné parametre
export interface DocumentCreatePaymentProps {
    payment?: DocumentPayment;
    paymentIndex?: number;
    paymentRest?: number; // Zvyšná suma
    paymentId?: number; // Spôsob platby
    payments: Payment[];
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (payment: DocumentPayment, paymentIndex?: number) => void;
    onClose: () => void;
}

const DocumentCreatePayment = (props: DocumentCreatePaymentProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<DocumentPayment>(EmptySource());

    // Automaticky vyplním okno
    useEffect(() => {
        if (!props.open) {
            return;
        }

        // Použijem props.item alebo emptySource (automaticky spustím prepočet, aby sa doplnili dopočítavané hodnoty)
        setSource(props.payment ?? { ...EmptySource(), price: props.paymentRest ?? 0, paymentId: props.paymentId ?? 0 });
    }, [props.payment, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps


    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            ...prev,
            private: false,
            payment: ''
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source, props.paymentIndex);
        props.onClose();
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick' && props.onClose) { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.paymentIndex === undefined ? 'Nová úhrada' : 'Úhrada'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }} alignItems="flex-end">

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="documentpayment-create-type">Forma úhrady *</InputLabel>
                                    <Select required labelId="documentpayment-create-type" label="Forma úhrady *" value={source.paymentId ?? 0} name="paymentId" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        {props.payments.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DateTimePicker inputFormat='dd.MM.yyyy HH:mm' ampm={false} disableMaskedInput={true}
                                        renderInput={(props) => <TextField required fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Dátum úhrady" value={source.date} onChange={(d) => { handleChange('date', d) }}
                                        inputRef={input => props.autoFocus === 'date' && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="price" type="text" label="Suma" variant="outlined" autoComplete="off" value={source.price ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => (props.autoFocus === 'price' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth multiline rows={4} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                        </Grid>
                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default DocumentCreatePayment;