// Modely
import { CustomerBank } from '../../models/Models';

// Komponenty
import { FormControlLabel, InputAdornment, Switch, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';

// Ikony
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export interface CustomerCreateBankProps {
    bank: CustomerBank;
    index: number;
    onChange: (index: number, property: string, value: any) => void;
}

const CustomerCreateBank = (props: CustomerCreateBankProps) => {
    return (
        <Grid container columnSpacing={1}>

            <Grid item xs={8}>
                <TextField margin="dense" name="name" label="Názov bankového účtu" fullWidth variant="outlined" autoComplete="off" value={props.bank.name ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <AccountBalanceIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item sm={4}>
                <FormControlLabel sx={{ pt: 2 }} control={<Switch checked={props.bank.main ?? false} name="main" onChange={(e) => { props.onChange(props.index, e.target.name, e.target.checked) }} />} label="Hlavný účet" />
            </Grid>

            <Grid item xs={12} sm={8}>
                <TextField margin="dense" name="iban" label="IBAN" fullWidth variant="outlined" autoComplete="off" value={props.bank.iban ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 34 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CreditCardIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField margin="dense" name="swift" label="BIČ / SWIFT" fullWidth variant="outlined" autoComplete="off" value={props.bank.swift ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 25 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CreditCardIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={8}>
                <TextField margin="dense" name="number" label="Číslo účtu" fullWidth variant="outlined" autoComplete="off" value={props.bank.number ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 25 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CreditCardIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField margin="dense" name="code" label="Kód banky" fullWidth variant="outlined" autoComplete="off" value={props.bank.code ?? ''} onChange={(e) => { props.onChange(props.index, e.target.name, e.target.value) }}
                    inputProps={{ maxLength: 15 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CreditCardIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

        </Grid>
    )
}

export default CustomerCreateBank;