// Komponenty
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ParameterItems from './ParameterItems';

// Vstupné parametre
export interface ParameterItemsDialogProps {
    open: boolean;
    parameterId: number;
    parameterName?: string;
    argument?: any;             // Môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    onChanged?: () => void;     // Udalosť po zmene v zozname
    onClose?: () => void;
}

const ParameterItemDialog = (props: ParameterItemsDialogProps) => {
    return (
        <>
            <Dialog maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick' && props.onClose) { props.onClose(); }}}>
                <DialogTitle>
                    { props.parameterName !== undefined && props.parameterName.length > 0 ? props.parameterName : 'Hodnoty parametra' }
                </DialogTitle>
                <DialogContent sx={{ height: '75vh' }}>
                    <ParameterItems parameterId={props.parameterId} parameterName={props.parameterName} onChanged={props.onChanged} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} type="submit" variant="contained">Zavrieť</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ParameterItemDialog;