import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Language, LanguageCode } from '../../../models/Models';
import { File } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, Tooltip, Box, FormControlLabel, Switch, Card, CardMedia, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';

// Ikony
import TranslateIcon from '@mui/icons-material/Translate';
import SortIcon from '@mui/icons-material/Sort';
import DeleteIcon from '@mui/icons-material/Delete';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Language => ({
    id: 0,
    actived: true,
    system: false,
    ordinalNumber: 0,
    code: 'sk',
    name: '',
    fileId: 0,
    other: {
        fileSrcMedium: ''
    }
});

// Vstupné parametre
export interface LanguageCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const LanguageCreate = (props: LanguageCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Language>(EmptySource());
    const [sourceCode, setSourceCode] = useState<LanguageCode[]>([]);
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {             
            if(files.length > 0 && files[0].fileIsImage)  {
                setSource(prev => ({ 
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileSrcMedium: files[0].fileSrcMedium
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };
    
    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if(!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language/' + props.id)
            .then(response => {
                if(response.data !== null) {
                    if(props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Language);
                }
            })
            .finally(() => {
                setLoading(false);
            }); 
    }, [props.id, props.copy, props.open]); 

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguageCodes = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'languagecode')
            .then(response => {
                setSourceCode(response.data);
            })
            .finally(() => {
                setLoading(false);
            }); 
    };
    useEffect(() => loadLanguageCodes(), []);

    // Zmeny vo formulári
    const handleChange = (property:string, value:any) => {   
        setSource(prev => ({ ...prev, [property] : value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => { 
        if(warning.text.length > 0) {
            setWarning(EmptyWarning);
        }        
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if(source.code === '') {
            setWarning({ ...EmptyWarning, name: 'code', text: 'Musíte vybrať kód!' });
            return;
        }

        if(source.id === 0) {
            source.system = false;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'language', source)
            .then(response => {
                if(response.data > 0) {
                    if(props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            }); 
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if(focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);
    
    return (
        <>
            <FilesDialog { ...filesDialog } />
            
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick') { props.onClose(); }}}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        { ((props?.id ?? 0) > 0 ? 'Jazyk' : 'Nový jazyk') }
                        { (props.copy && ' (kópia)') }
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} disabled={source.system} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Názov jazykovej mutácii, tak ako sa bude zobrazovať v zozname výberu.">
                                                    <TranslateIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="language-create-code">Jazyk (systémová mutácia)</InputLabel>
                                    <Select required labelId="language-create-code" label="Jazyk (systémová mutácia)" value={source.code ?? ''} name="code" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'code' && setFocus(input)}>
                                        <MenuItem key={0} value={''}>Žiadny</MenuItem>
                                        { sourceCode?.map(item => <MenuItem key={item.code} value={item.code}>{item.name + ' (' + item.code + ')'}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? 0} onChange={(e) => { handleChange(e.target.name, Number.parseInt(e.target.value)) }} 
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            
                            <Grid item xs={12} sm={4}>                    
                                { (source.fileId ?? 0) > 0 && 
                                    <Card sx={{ mt: 1 }}>
                                        <CardMedia component="img" height="120" image={source.other?.fileSrcMedium} alt="Náhľad" />
                                        <CardActions>
                                            <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>Vymazať</Button>
                                        </CardActions>
                                    </Card>
                                }
                                <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať obrázok</Button>
                            </Grid>
                        </Grid>
                        
                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={ warning.color as AlertColor }>{warning.text}</Alert>)}  

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default LanguageCreate;